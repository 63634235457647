<ng-container *ngIf="currentAdvanceQueryGroups">
  <div *ngFor="let group of currentAdvanceQueryGroups" class="QB-list-group">
    <div class="QB-list-group-list">
      <div class="flex gap-3 align-items-center pl-2">
        <span>Condition:</span>
        <p-dropdown [options]="andOrOptions" optionValue="label" optionLabel="label"
                    [(ngModel)]="group.condition" (onChange)="setChange()"></p-dropdown>
        <button type="button" pButton *ngIf="!removeHide" class="btn-remove-group pull-right"
                (click)="removeChildGroup(group)" id="{{group.GroupId}}" icon="fas fa-minus-circle"
                label="Remove Group"></button>
        <button type="button" pButton class="btn-add-group pull-right" (click)="addGroupRow(group)"
                icon="fas fa-plus-circle" label="Add Group"></button>
        <button type="button" pButton class="btn-add-role pull-right" (click)="addRule(group)" icon="fas fa-plus-circle"
                label="Add Rule"></button>
      </div>
      <div>
        <div class="add-Data-Query">
          <ng-container *ngFor="let rule of group.rules">
            <div class="grid align-items-center mt-0">
              <div class="col-3">
                <p-dropdown [options]="selectedColumnList" optionLabel="displayName" optionValue="id"
                            [(ngModel)]="rule.selectedColumnId"
                            (onChange)="setColumnDataType(rule, $event)"></p-dropdown>
              </div>
              <div class="col-2">
                <ng-container *ngIf="rule.columnDateType === '' || rule.columnDateType === undefined">
                  <p-dropdown [options]="stringOperatorsList" optionLabel="value" optionValue="value"
                              [(ngModel)]="rule.operator"
                              (onChange)="setChange()"></p-dropdown>
                </ng-container>
                <ng-container *ngIf="rule.columnDateType !== undefined && !rule.isPredefined && (rule.columnDateType === 'VARCHAR' || rule.columnDateType === 'LONGTEXT' || rule.columnDateType === 'TEXT')
                    && rule.selectedColumnId !== 56">
                  <p-dropdown [options]="stringOperatorsList" optionLabel="value" optionValue="value"
                              [(ngModel)]="rule.operator"
                              (onChange)="setChange()"></p-dropdown>
                </ng-container>
                <ng-container
                  *ngIf="rule.columnDateType !== undefined && !rule.isPredefined &&
                       (rule.columnDateType === 'INT' || rule.columnDateType === 'BIGINT' || rule.columnDateType === 'DECIMAL'
                        || rule.columnDateType === 'DATETIME' || rule.columnDateType === 'BIT' || rule.columnDateType === 'DATE')">
                  <p-dropdown [options]="integerOperatorsList" optionLabel="value" optionValue="value"
                              [(ngModel)]="rule.operator"
                              (onChange)="setChange()"></p-dropdown>
                </ng-container>
                <ng-container
                  *ngIf="rule.columnDateType === 'TINYINT' || rule.isPredefined || rule.selectedColumnId === 56">
                  <p-dropdown [options]="booleanOperatorsList" optionLabel="value" optionValue="value"
                              [(ngModel)]="rule.operator"
                              (onChange)="setChange()"></p-dropdown>
                </ng-container>
              </div>
              <div class="col-2"
                   *ngIf="rule.columnDateType !='DATETIME' && rule.columnDateType !='DATE' && !rule.isPredefined && rule.columnDateType!='TINYINT' && rule.selectedColumn!='Account.SleeveType'">
                <input pInputText type="text" [(ngModel)]="rule.value" (ngModelChange)="setChange()"
                       (keypress)="validateValue(rule.columnDateType, $event)">
              </div>
              <div class="col-2" *ngIf="rule.columnDateType === 'TINYINT'">
                <p-dropdown [options]="yesNoValues" optionLabel="label" optionValue="value"
                            [(ngModel)]="rule.value" (onChange)="setChange()"></p-dropdown>
              </div>
              <div class="col-2" *ngIf="rule.selectedColumn === 'Account.SleeveType'">
                <p-dropdown [options]="sleeveTypeValues" optionLabel="label" optionValue="label"
                            [(ngModel)]="rule.value" (onChange)="setChange()"></p-dropdown>
              </div>
              <div class="col-2" *ngIf="rule.isPredefined">
                <p-dropdown [options]="rule.predefinedList.values" optionLabel="name" optionValue="id"
                            [(ngModel)]="rule.predefinedValue"
                            (onChange)="setPredefinedvalue(rule, $event)"></p-dropdown>
              </div>
              <div class="col-3"
                   *ngIf="(rule.columnDateType === 'DATETIME' || rule.columnDateType === 'DATE' ) && rule.columnDateType !== null">
                <p-dropdown [options]="macroList" optionLabel="name" optionValue="id"
                            [(ngModel)]="rule.macro"
                            (onChange)="clearDate(rule)"></p-dropdown>
              </div>
              <div class="col-2"
                   *ngIf="rule.macro === 0 && (rule.columnDateType === 'DATETIME' || rule.columnDateType === 'DATE')">
                <p-calendar [(ngModel)]="rule.date" [showIcon]="true"
                            (ngModelChange)="setChange()" [yearNavigator]="true" [yearRange]="getYearRange()"
                            [readonlyInput]="true"></p-calendar>
              </div>
              <div class="col-1">
                <button id="removeAdv" type="button" pButton class="p-button-danger"
                        (click)="removeRule(group,rule)" icon="fas fa-minus-circle"></button>
              </div>
            </div>
          </ng-container>
        </div>
        <eclipse-data-query-advance [currentAdvanceQueryGroups]="group.groups"
                                    [availableColumnList]="availableColumnList"
                                    [selectedColumnList]="selectedColumnList"
                                    [advanceQueryChanges]="advanceQueryChanges">
        </eclipse-data-query-advance>
      </div>
    </div>
  </div>
</ng-container>
