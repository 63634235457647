import { IPortfolioAnalytics, IPortfolioTeam } from '../../../models/portfolio';
import { IIdName } from '../../../models/tom';

export enum PortfolioEditorPage {
  Summary = 'Summary',
  Cash = 'Cash',
  Accounts = 'Accounts',
  Model = 'Model',
  Notes = 'Notes',
  GainLoss = 'GainLoss',
  Holdings = 'Holdings',
  TradeHistory = 'TradeHistory',
  AuditHistory = 'AuditHistory',
  SleeveMaintenance = 'SleeveMaintenance',
  Preferences = 'Preferences',
  CompareTool = 'CompareTool',
}

export type PortfolioEntity = {
  id: number;
  name: string;
  tags: string;
  modelId?: number;
  modelName: string;
  isSleevePortfolio: boolean;
  isDisabled: boolean;
  doNotTrade: boolean;
  registrationId?: number;
  totalValue: number;
  managedValue: number;
  custodialAccountNumber: string;
  sleeveStrategyAggregateName: string;
  sleeveDeviationPercent?: number;
  categoryDeviationPercent?: number;
  classDeviationPercent?: number;
  subclassDeviationPercent?: number;
  securitySetDeviationPercent?: number;
  securityDeviationPercent?: number;
  autoRebalance: string;
  hasPendingTrades: boolean;
  lastTradedDate: Date;
  lastRebalanceDate: Date;
  lastTLHDate: Date;
  contribution: number;
  distribution: number;
  outOfTolerance: boolean;
  cashOutOfTolerance: boolean;
  cashNeed: number;
  cashNeedPercentage: number;
  cashPercent: number;
  cashTargetPercent: number;
  actualSetAsideCash: number;
  targetSetAsideCash: number;
  hasTLHOpportunity: boolean;
  needAnalytics: number;
  needAnalyticsStatus: string;
  orionConnectFirmId: number;
  portfolioGroupId?: number;
  hasSubstitutes: boolean;
  hasUnassignedHoldings: boolean;
  underMaxGainAmount: boolean;
  createdBy: string;
  createdDate: Date;
  editedBy: string;
  editedDate: Date;
  analyticsEditedDate: Date;
  failedReason: string;
  teams: IPortfolioTeam[]; // The selected teams objects
  rebalanceDay: number;
  rebalanceTypeId: number;
  rebalanceOptionId: number;
  rebalanceOption: string;
  buyEmphasisOn: number;
  sellEmphasisOn: number;
  isMac: boolean;
  // Added for use in the UI
  primaryTeamId: number;
  teamIds: number[];
  analytics: IPortfolioAnalytics[];
  model: IIdName;
  showDeviationSection: boolean;
};

export type PortfolioTacticalValidation = {
  portfolioId: number;
  portfolioName: string;
  errors: {
    typeId: number;
    message: string;
  }[];
};
