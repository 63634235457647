<section style="display: flex; flex-direction: column; height: 100%;">
  <div class="page-header-row">
    <h3>Holdings</h3>
    <button type="button" pButton (click)="exportToExcel()"
            label="Export to Excel" icon="far fa-fw fa-arrow-circle-down" class="p-button-secondary m-r-5"></button>
  </div>

  <ag-grid-angular style="width: 100%; flex: 1;" *ngIf="holdingsList$ | async as holdingsList" #agGrid class="ag-theme-alpine grid-height-autosize margin-top-15" [gridOptions]="gridOptions"
                   rowSelection="single" (rowSelected)="onRowSelected($event)" [columnDefs]="columnDefs" [rowData]="holdingsList" enableKeyboardSelectAllRows
                   [getContextMenuItems]="getContextMenuItems" (gridReady)="onGridReady($event)" [context]="gridContext" (modelUpdated)="onModelUpdated()"
                   (rowDoubleClicked)="onRowDoubleClicked($event)"
                   [suppressAggFuncInHeader]="true" [groupIncludeFooter]="true">
  </ag-grid-angular>
</section>
