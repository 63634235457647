import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionHelper } from './session.helper';
import { ILogin } from '../login/login';
import { NotificationService as AppNotificationService } from './customSubService';
import { v4 as UUID } from 'uuid';
import { IRequestBasicOptions, IRequestOptions } from './interfaces';
import { api } from '../../environments/api';
import { development } from '../../environments/development';
import { HTTP_CONSTANTS } from '../libs/app.constants';

@Injectable()
export class OEHttpClient {
  private static readonly _accessTokenName: string = 'accessTokenInfo';

  constructor(
    private _http: HttpClient,
    private _sessionHelper: SessionHelper,
    private _appNotificationService: AppNotificationService,
    @Inject('HOST_ORIGIN_NAME') private _hostOriginName: string) {
  }

  /** Creating authorization headers for an Eclipse endpoint */
  createAuthorizationHeader(): HttpHeaders {
    const sessionToken = this.getSessionToken(HTTP_CONSTANTS.ECLIPSE_ACCESS_TOKEN_KEY);

    return this.getBasicHttpHeaders(sessionToken)
      .append(HTTP_CONSTANTS.REQUEST_ID_HEADER_KEY, UUID());
  }

  /**
   * Creates and returns headers for the purpose of calling an Orion Connect endpoint.  `Authorization`
   * header is set to the given Orion Connect session {@link token}.
   * @param token The session token to be used in the `Authorization` header.
   * @returns HTTP headers, including `Accept`, `Content-Type` and `Authorization`.
   */
  createOrionConnectAuthorizationHeader(token: string): HttpHeaders {
    if (!token) {
      console.log('Cannot create an Orion Connect Authorization HTTP Header because no session token was provided.');
      // We don't return an error -- allowing the endpoint to return an HTTP 401/Unauthorized for logging.
    }

    return this.getBasicHttpHeaders(token);
  }

  /*** append host url to given path and returns  */
  public getApiUrl(route: string): string {
    let apiEndpoint: string;

    // get the site domain as the base url for api requests. local builds ignore this since v1/v2 don't run on the same domain:port
    // and have those values specified in environment.
    const host: string = development.local ? '' : this._hostOriginName;

    // if the version is included as the first url segment, look up the host URL for that version in the config
    const routeParts = route.split('/');
    if (routeParts.length > 0) {
      const apiVersion = routeParts[0]; // get the first segment of the route
      const apiVersionUrl = api.apiEndpoint[apiVersion];
      if (apiVersionUrl) {
        apiEndpoint = apiVersionUrl.replace(`${apiVersion}/`, ''); // remove the version from the host URL (it's already included in the route)
      }
    }

    if (!apiEndpoint) { // default to v1
      apiEndpoint = route.startsWith('v1/') ? api.apiEndpoint.v1.replace('v1/', '') : api.apiEndpoint.v1;
    }
    return `${host}${apiEndpoint}${route}`;
  }

  /**
   * Constructs a request and returns the response.
   * @deprecated Please use `get<T>` and `post<T>` for better type safety.
   * @param method GET/POST/PUT
   * @param url
   * @param options
   */
  public request(method: string, url: string, options: IRequestOptions | {
    [option: string]: any;
  } = {}): Observable<any> {
    options.headers = options.headers || this.createAuthorizationHeader();

    if (options?.showProgress === false) {
      options.headers = options.headers.append(HTTP_CONSTANTS.SKIP_PROGRESS_HEADER_KEY, '');
    }
    if (options?.showError === false) {
      options.headers = options.headers.append(HTTP_CONSTANTS.SKIP_ERROR_HEADER_KEY, '');
    }

    return this._http.request(method, this.getApiUrl(url), options);
  }

  /**
   * Constructs a GET request.
   * @deprecated Please use `get<T>` for better type safety.
   * @param url
   * @param showProgress
   */
  public getData(url: string, showProgress?: boolean): Observable<any> {
    return this.get<any>(url, {showProgress: showProgress});
  }

  /**
   * Gets an image/blob.  Treats response as a raw file, so no parsing is performed.
   * @param url
   */
  public getImage(url: string): Observable<any> {
    const authHeaders = this.createAuthorizationHeader();
    return this._http.get(this.getApiUrl(url), {headers: authHeaders, responseType: 'blob'});
  }

  /**
   * Constructs a GET request.
   * @param url The endpoint URL
   * @param options Options
   */
  public get<T>(url: string, options?: IRequestBasicOptions): Observable<T> {
    let authHeaders = this.createAuthorizationHeader();
    if (options?.showProgress === false) {
      authHeaders = authHeaders.append(HTTP_CONSTANTS.SKIP_PROGRESS_HEADER_KEY, '');
    }
    if (options?.showError === false) {
      authHeaders = authHeaders.append(HTTP_CONSTANTS.SKIP_ERROR_HEADER_KEY, '');
    }
    return this._http.get<T>(this.getApiUrl(url), {headers: authHeaders});
  }

  public login(url: string, data: HttpHeaders): Observable<any> {
    let authHeaders = data;
    authHeaders = authHeaders
      .append(HTTP_CONSTANTS.REQUEST_ID_HEADER_KEY, UUID());
    return this._http.get(this.getApiUrl(url), {headers: authHeaders});
  }

  /**
   * Constructs a POST request.
   * @deprecated use `post<T>` to provide type safety.
   * @param url The endpoint URL
   * @param data The data to post
   * @param options Options
   */
  public postData(url: string, data: any): Observable<any> {
    return this.post<any>(url, data);
  }

  /**
   * Constructs a POST request.
   * @param url The endpoint URL
   * @param data The data to post
   * @param options Options
   */
  public post<T>(url: string, data: any): Observable<T> {
    const authHeaders = this.createAuthorizationHeader();
    return this._http.post<T>(this.getApiUrl(url), JSON.stringify(data), {headers: authHeaders});
  }

  /**
   * Sends the given data to the given Orion Connect URI via an HTTP POST.
   * @param uri The Orion Connect URI to send the POST to.
   * @param token The session token to be added to the `Authorization` header for accessing the Orion Connect
   * endpoint.
   * @param data The data that gets stringified as JSON and sent as the payload.
   * @returns {Observable<any>}
   */
  public postDataToOrionConnectUri(uri: string, token: string, data: any): Observable<any> {
    const authHeaders = this.createOrionConnectAuthorizationHeader(token);
    return this._http.post(uri, JSON.stringify(data), {
      headers: authHeaders,
    });
  }

  /** To process post request */
  public downloadFile(url: string, data: any): Observable<any> {
    const authHeaders = this.createAuthorizationHeader();
    return this._http.post(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders, responseType: 'blob', observe: 'body',
    });
  }

  /** This is a version of the downloadFile method
   * that returns the entire response along with the body/blob
   * We can use the headers to get the filename from content-disposition
   */
  public downloadFileWithHeadersFromTrustedSource(url: string, data: any): Observable<any> {
    const authHeaders = this.createAuthorizationHeader();
    return this._http.post(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders, responseType: 'blob', observe: 'response',
    });
  }

  /** To process update request */
  public updateData(url: string, data: any, showProgress?: boolean): Observable<any> {
    let authHeaders = this.createAuthorizationHeader();
    if (showProgress === false) {
      authHeaders = authHeaders.append(HTTP_CONSTANTS.SKIP_PROGRESS_HEADER_KEY, '');
    }
    return this._http.put(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders,
    });
  }

  /** To process patch request */
  public patchData(url: string, data: any): Observable<any> {
    const authHeaders = this.createAuthorizationHeader();
    return this._http.patch(this.getApiUrl(url), data, {
      headers: authHeaders,
    });
  }

  /** To process delete request */
  public deleteData(url: string, data?: any): Observable<any> {
    const authHeaders = this.createAuthorizationHeader();
    const options: {
      body?: any;
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      observe?: any; // have to use `any` because Angular doesn't export HttpObserve
    } = {
      headers: authHeaders,
      observe: 'body',
    };

    /**
     * DELETE requests should not contain a payload.  Any endpoint receiving a payload should be evaluated to conform with W3C standards.
     * From RFC 8231 https://tools.ietf.org/html/rfc7231#section-4.3.5
     *   A payload within a DELETE request message has no defined semantics; sending a payload body on a DELETE request might cause some existing implementations to reject the request.
     */
    if (data) {
      options.body = data;
    }

    return this._http.request('delete', this.getApiUrl(url), options);
  }

  /** Processing file uploading  */
  public uploadFile(url: string, formData: FormData): Observable<any> {
    const self = this;
    const uuid = UUID();
    return new Observable(observer => {
      const accessToken = <ILogin>this._sessionHelper.getAccessToken(OEHttpClient._accessTokenName);
      let percentComplete = 0;
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        // eslint-disable-next-line eqeqeq
        if (xhr.readyState == 4) {
          // eslint-disable-next-line eqeqeq
          if (xhr.status == 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.getApiUrl(url), true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('Authorization', `Session ${accessToken.eclipse_access_token}`);
      xhr.setRequestHeader(HTTP_CONSTANTS.REQUEST_ID_HEADER_KEY, uuid);
      xhr.upload.addEventListener('progress', function (e) {
        percentComplete = Math.round((e.loaded / e.total) * 40);
        self._appNotificationService.fileImportProgress.emit({percentage: percentComplete});
      }, false);

      xhr.send(formData);
    });
  }

  /**
   * Returns the session token for the service as determined by the given {@link accessTokenName}.
   * @param accessTokenName The name of the access token to be used, such as `eclipse_access_token` or
   * `orion_access_token`.
   * @returns the session token for the service determined by the given {@link accessTokenName}, or null if the
   * token could not be obtained.
   */
  private getSessionToken(accessTokenName: string): string {
    const accessToken = this._sessionHelper.get<ILogin>(OEHttpClient._accessTokenName);

    if (!accessToken) {
      console.log('While creating an HTTP Header, the access token could not be obtained.');
      return null;
    }

    const sessionId = accessTokenName === HTTP_CONSTANTS.ECLIPSE_ACCESS_TOKEN_KEY
      ? accessToken.eclipse_access_token
      : accessToken.orion_access_token;

    if (!sessionId) {
      console.log(`${accessTokenName} not found.  Access token information: `, accessToken);
      return null;
    }

    return sessionId;
  }

  /**
   * Creates HTTP Headers for `Accept` and `Content-Type` with values of 'application/json', and an optional
   * `Authorization` header with the given {@link token}.
   * @param token The session token to be used with the optional `Authorization` header.  If not provided, no
   * `Authorization` header is created.
   * @returns HTTP headers, including `Accept`, `Content-Type` and optionally, `Authorization`.
   */
  private getBasicHttpHeaders(token: string = null): HttpHeaders {
    let authHeaders = new HttpHeaders();
    authHeaders = authHeaders.set('Accept', 'application/json');
    authHeaders = authHeaders.append('Content-Type', 'application/json');

    if (token) {
      authHeaders = authHeaders.append('Authorization', `Session ${token}`);
    }

    return authHeaders;
  }
}
