import { Inject, Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { distinctUntilChanged, filter, map, mergeWith, skip, switchMap, tap } from 'rxjs/operators';
import { SplitService } from '@splitsoftware/splitio-angular';
import { RxUtility } from '../../shared/helpers/rxjs-utilities';
import { BehaviorSubject, firstValueFrom, identity, Observable, Subject, Subscription } from 'rxjs';
import { ConfigService } from '../../config/config.service';
import { IUser } from '../../models/user';
import { environment } from '../../../environments/environment';
import { Treatments } from '@splitsoftware/splitio/types/splitio';
import { FEATURE_FLAGS, FeatureFlagGroup, FeatureFlagRegistry } from '../feature-flag';
import { development } from '../../../environments/development';

/**
 * A keyed array containing a feature flag name and its enabled status.
 */
export type FeatureFlags = { [key: string]: boolean };

@Injectable({
  providedIn: 'root',
})
export class SplitIoService {
  private splitReady = false;
  private splitSdkUpdateObs: Subscription;
  splitSdkUpdate$ = new Subject<string>();
  private currentUser: IUser;

  // Cache for the global feature flag values.
  private _globalFlags: BehaviorSubject<FeatureFlags> = new BehaviorSubject<FeatureFlags>({});
  // Subscription to the global feature flag changes.  When new changes are sent by Split, the subscription
  // will determine if any of the global flags were changed and will update the global cache accordingly.
  private globalFeatureFlagChangesSubscription: Subscription;

  constructor(private _authService: AuthService, public readonly splitService: SplitService, @Inject(FEATURE_FLAGS) public featureFlagRegistry: FeatureFlagRegistry) {
    this._authService.currentUser$
      // filter out null and duplicate users
      .pipe(filter(x => !!x && (!this.currentUser || this.currentUser.id !== x.id || this.currentUser.firmId !== x.firmId)))
      .subscribe(newUser => {
        this.splitReady = false;
        this.currentUser = newUser;
        this.initializeSplit(newUser);
      });
  }

  /**
   * Stream for the feature flags registered globally.  The stream will emit a value each
   * time a global flag value has changed.
   * See `feature-flags.ts` for the complete list of flags.
   */
  public get featureFlags$(): Observable<FeatureFlags> {
    return this._globalFlags.asObservable();
  }

  /**
   * The list of feature flags registered globally and their current value.  This list will be udpated
   * asynchronously in response to Split changes
   *
   * @see `FEATURE_FLAGS`
   */
  public get featureFlags(): FeatureFlags {
    return this._globalFlags.value;
  }

  /**
   * Initializes the global feature flags list.  Should only be called once.
   */
  public resolveGlobalFeatureFlags(): Observable<any> {
    // Get the current state of the global feature flags as the starting point for the cache.
    return this.flagsEnabled(this.getGlobalFeatureFlagsList(this.featureFlagRegistry))
      .pipe(
        tap((flags: FeatureFlags) => {
          if (development.local) {
            console.info(`Initial feature flag values for ${this.currentUser.name} (${this.currentUser.firmId})`, flags);
          }
          // Update the global feature flag cache with the initial flag values
          this.updateGlobalFeatureFlagCache(flags);
          return flags;
        }));
  }

  /**
   * Converts the global FeatureFlagGroup object into a simple array of unique strings.
   * Ex.
   *   [
   *    'Some_Flag_A',
   *    {
   *      group: 'MyGroup',
   *      flags: [
   *       'Some_Flag_B',
   *       'Some_Flag_A',
   *      ]
   *    }
   *   ]
   *  returns => ['Some_Flag_A', 'Some_Flag_B']
   * @private
   */
  public getGlobalFeatureFlagsList(registry: FeatureFlagRegistry): string[] {
    const flags = [];

    function recurse(item: string | FeatureFlagGroup) {
      // If the value is a string, then it's a flag, so pull it into the results
      if (typeof item === 'string') {
        flags.push(item);
      } else {
        // Recurse through each of the flags and add their values
        item.flags?.forEach(recurse);
      }
    }
    // Start the recursion at with the root level items
    registry.forEach(recurse);
    // Return the array of unique (by using Set) flag names, sorted because it's easier on the eyes.
    return Array.from(new Set([...flags]), String)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
  }

  /**
   * Creates a subscription that monitors the globally registered feature flags.  Any changes to the flag values
   * will cause the new list of flags to be emitted.
   * @param newFlags
   * @private
   */
  private updateGlobalFeatureFlagCache(newFlags: FeatureFlags): void {
    this.globalFeatureFlagChangesSubscription?.unsubscribe();

    // Emit the list of flags
    this._globalFlags.next(newFlags);

    // Create the subscription to watch for flag changes sent by Split
    this.globalFeatureFlagChangesSubscription = this.featureFlags$
      .pipe(
        // When getting a new list of flags, subscribe to the flag changes from Split.
        switchMap((flags: FeatureFlags) => this.flagsEnabled$(Object.keys(flags))),
        // Skip the first value, since it'll will match what we already have in the `newFlags` and it would cause
        // the code below to run over and over.
        skip(1),
        tap((flagChanges: FeatureFlags) => {
          const currentFlags = this.featureFlags;
          // Get the unique list of keys from the old and new lists
          const allKeys = [...new Set([...Object.keys(currentFlags), ...Object.keys(flagChanges)])];
          // Create an array of all keys that have changed between the lists
          const differenceKeys: string[] = allKeys
            .filter(key => currentFlags[key] !== flagChanges[key])
            .map(key => key);

          if (differenceKeys?.length) {
            this.updateGlobalFeatureFlagCache(flagChanges);
          }
        })
      ).subscribe();
  }

  async initializeSplit(newUser) {
    await firstValueFrom(ConfigService.settings$);

    const userName_lower = newUser?.userLoginId?.toLowerCase() ?? null;

    const attributes = {
      firmId: newUser.firmId,
      userName: userName_lower,
    };

    this.splitSdkUpdateObs?.unsubscribe();

    const sdkConfig = {
      core: {
        authorizationKey: environment.splitIoKey,
        key: newUser.firmId,
      }
      //   // The following lines are very useful they will dump out
      //   //current splits and changes that come through, check the dev tools console.
      //   ,impressionListener: {
      //     logImpression:  this.logImpression
      //   },
      //   debug: true
      //   // Move the following lines as their own function
      //   logImpression(impressionData) {
      //      console.log(JSON.stringify(impressionData));
      //   }
    };

    if (this.splitService?.isSDKReady) {
      this.splitService.destroy().subscribe(() => {
        this.splitService.init(sdkConfig).subscribe(() => {
          const client = this.splitService.getSDKClient();

          client.setAttributes(attributes);

          client.ready()
            .then(() => { // wait for client ready Promise
              this.subscribeToSdkUpdates();
              this.splitReady = true;
            });
        });
      });
    } else {
      this.splitService.init(sdkConfig).subscribe(() => {
        const client = this.splitService.getSDKClient();

        client.setAttributes(attributes);

        client.ready()
          .then(() => { // wait for client ready Promise
            this.subscribeToSdkUpdates();
            this.splitReady = true;
          });
      });
    }
  }

  /**
   * Watches for updates to the Split SDK and emits the changes using an internal observable.
   * This is necessary because the flag() may be subscribed to before the Split service has fully
   * initialized, causing the mergeWith to throw an undefined exception because sdkUpdate$ is undefined.
   * @private
   */
  private subscribeToSdkUpdates() {
    this.splitSdkUpdateObs?.unsubscribe();
    this.splitSdkUpdateObs = this.splitService.sdkUpdate$.subscribe(update => {
      this.splitSdkUpdate$.next(update);
    });
  }

  /**
   * Returns an observable for that provides the initial value for a Split flag,
   * as well as any updates made to that flag in realtime.
   * @param key Split flag to query
   * @param subscribeToUpdates if true, the returned Observable remains active and emits
   * updates when the flag is updated on the Split website.
   */
  private getTreatment(key: string, subscribeToUpdates: boolean = true): Observable<string> {
    return RxUtility.waitUntil$({
      until: () => this.splitReady
    }).pipe(
      subscribeToUpdates ? mergeWith(this.splitSdkUpdate$) : identity,
      map(() => {
        return this.splitService.getTreatment(key);
      }));
  }

  /**
   * Returns an observable for that provides the initial value for the Split flags,
   * as well as any updates made to those flags in realtime.
   * @param keys Split flags to query
   * @param subscribeToUpdates if true, the returned Observable remains active and emits
   * updates when the flag(s) are updated on the Split website.
   */
  private getTreatments(keys: string[], subscribeToUpdates: boolean = true): Observable<Treatments> {
    return RxUtility.waitUntil$({
      until: () => this.splitReady
    }).pipe(
      subscribeToUpdates ? mergeWith(this.splitSdkUpdate$) : identity,
      map(() => {
        return this.splitService.getTreatments(keys);
      }));
  }

  /**
   * Returns an Observable with the initial flag value.  Does not emit values if the flag is changed.
   * @param key Split flag to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   *
   * @deprecated Use the `featureFlags` object in conjunction with the global feature-flags.ts array.
   * @see `FEATURE_FLAGS`
   */
  public flagEnabled(key: string, enabledValue = 'on'): Observable<boolean> {
    return this.getTreatment(key, false)
      .pipe(
        map((value) => {
          return value === enabledValue;
        }));
  }

  /**
   * Returns a stream with the initial flag value and emits updates to that flag as they occur.
   * @param key Split flag to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   *
   * @deprecated Use the `featureFlags$` stream in conjunction with the global feature-flags.ts array.
   * @see `FEATURE_FLAGS`
   */
  public flagEnabled$(key: string, enabledValue = 'on'): Observable<boolean> {
    return this.getTreatment(key, true)
      .pipe(
        map((value) => {
          return value === enabledValue;
        }),
        // Since Split updates don't include what key was updated, we re-query all the keys
        // after an update message.  If the key being subscribed to didn't change as part of the
        // update, then we don't want to emit a redundant value, so distinctUntilChanged is used here.
        distinctUntilChanged());
  }

  /**
   * Queries for multiple flags simultaneously and provides a stream for any updates.
   * @param keys Split keys to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   *
   * @deprecated Use the `featureFlags` object in conjunction with the global feature-flags.ts array.
   * @see `FEATURE_FLAGS`
   */
  public flagsEnabled(keys: string[], enabledValue = 'on'): Observable<FeatureFlags> {
    return this.getTreatments(keys, false)
      .pipe(
        map((treatments) => {
          const flags = {};
          Object.entries(treatments).map(([key, value]) => {
            flags[key] = value === enabledValue;
          });
          return flags;
        }));
  }

  /**
   * Queries for multiple flags simultaneously and provides a stream for any updates.
   * @param keys Split keys to query
   * @param enabledValue Optional value (default is 'on') to determine if the flag is enabled.
   *
   * @deprecated Use the `featureFlags$` stream in conjunction with the global feature-flags.ts array.
   * @see `FEATURE_FLAGS`
   */
  public flagsEnabled$(keys: string[], enabledValue = 'on'): Observable<FeatureFlags> {
    return this.getTreatments(keys, true)
      .pipe(
        map((treatments) => {
          const flags = {};
          Object.entries(treatments).map(([key, value]) => {
            flags[key] = value === enabledValue;
          });
          return flags;
        }),
        // Since Split updates don't include what key was updated, we re-query all the keys
        // after an update message.  If the key being subscribed to didn't change as part of the
        // update, then we don't want to emit a redundant value, so distinctUntilChanged is used here.
        distinctUntilChanged());
  }
}
