<div *ngIf="currentAdvanceQueryGroups">
  <div *ngFor="let group of currentAdvanceQueryGroups" class="QB-list-group">
    <div class="QB-list-group-list">
      Condition:
      <select [(ngModel)]="group.condition" (ngModelChange)="setChange()">
        <option>AND</option>
        <option>OR</option>
      </select>
      <button type="button" pButton  *ngIf="!removeHide" class="btn-remove-group pull-right" (click)="removeChildGroup(group)" id="{{group.GroupId}}" icon="fas fa-minus-circle" label="Remove Group"></button>
      <button type="button" pButton class="btn-add-group pull-right" (click)="addGroupRow(group)" icon="fas fa-plus-circle" label="Add Group"></button>
      <button type="button" pButton class="btn-add-role pull-right" (click)="addRule(group)" icon="fas fa-plus-circle" label="Add Rule"></button>
      <div>
        <div class="add-Data-Query">
          <div *ngFor="let rule of group.rules">
            <div class="row">
              <div class="col-sm-3">
                <div class=" custom-group">
                  <div class="form-group">
                    <select class="form-control" [(ngModel)]="rule.selectedColumnId" (change)="setColumnDataType(rule,$event)">
                      <!--<option value="0">Select column </option>-->
                      <option *ngFor="let item of selectedColumnList" class={{item.dataType}} [value]="item.id">{{item.displayName}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class=" custom-group">
                  <div class="form-group">
                    <div *ngIf="rule.columnDateType == '' || rule.columnDateType == undefined">
                      <select class="form-control" [(ngModel)]="rule.operator" (ngModelChange)="setChange()">
                        <option  *ngFor="let item of stringOperatorsList" [value]="item.value" >{{item.value}}</option>
                      </select>
                    </div>
                    <div *ngIf="rule.columnDateType!= undefined && !rule.isPredefined && (rule.columnDateType =='VARCHAR' || rule.columnDateType =='LONGTEXT' || rule.columnDateType =='TEXT')&& rule.selectedColumnId!=56">
                      <select class="form-control" [(ngModel)]="rule.operator" (ngModelChange)="setChange()">
                        <option  *ngFor="let item of stringOperatorsList" [value]="item.value" >{{item.value}}</option>
                      </select>
                    </div>
                    <div *ngIf="rule.columnDateType!= undefined && !rule.isPredefined && (rule.columnDateType =='INT' || rule.columnDateType =='BIGINT' || rule.columnDateType == 'DECIMAL' || rule.columnDateType == 'DATETIME' || rule.columnDateType == 'BIT' || rule.columnDateType == 'DATE')">
                      <select class="form-control" [(ngModel)]="rule.operator" (ngModelChange)="setChange()">
                        <option *ngFor="let item of integerOperatorsList" [value]="item.value" >{{item.value}}</option>
                      </select>
                    </div>
                    <div *ngIf="rule.columnDateType== 'TINYINT' || rule.isPredefined || rule.selectedColumnId==56">
                      <select class="form-control" [(ngModel)]="rule.operator" (ngModelChange)="setChange()">
                        <option *ngFor="let item of booleanOperatorsList" [value]="item.value" >{{item.value}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" *ngIf="rule.columnDateType !='DATETIME' && rule.columnDateType !='DATE' && !rule.isPredefined && rule.columnDateType!='TINYINT' && rule.selectedColumn!='Account.SleeveType'">
                <div class=" custom-group">
                  <div class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="rule.value" (ngModelChange)="setChange()" (keypress)="validateValue(rule.columnDateType, $event)">
                  </div>
                </div>
              </div>
              <div class="col-sm-2" *ngIf="rule.columnDateType=='TINYINT'">
                <div class=" custom-group">
                  <div class="form-group">
                    <select class="form-control" [(ngModel)]="rule.value" (ngModelChange)="setChange()">
                      <option value="1" >Yes</option>
                      <option value="0" >No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" *ngIf="rule.selectedColumn=='Account.SleeveType'">
                <div class=" custom-group">
                  <div class="form-group">
                    <select class="form-control" [(ngModel)]="rule.value" (ngModelChange)="setChange()">
                      <option value="Normal" >Normal</option>
                      <option value="None" >None</option>
                      <option value="Custodial" >Custodial</option>
                      <option value="Distribution" >Distribution</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" *ngIf="rule.isPredefined">
                <div class=" custom-group">
                  <div class="form-group">
                    <select class="form-control" [(ngModel)]="rule.predefinedValue" (change)="setPredefinedvalue(rule,$event)">
                      <option value="0"> Select value </option>
                      <option *ngFor="let value of rule.predefinedList.values" class={{value.name}} [value]="value.id">{{value.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" *ngIf="(rule.columnDateType =='DATETIME'||rule.columnDateType =='DATE' ) && rule.columnDateType !=null">
                <div class="custom-group">
                  <div class="form-group">
                    <select class="form-control" [(ngModel)]="rule.macro" (ngModelChange)="clearDate(rule)">
                      <option value="0"> Specific date </option>
                      <option *ngFor="let macro of macroList" [value]="macro.id">{{macro.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" *ngIf="rule.macro =='0' && (rule.columnDateType =='DATETIME' || rule.columnDateType =='DATE')">
                <div class=" custom-group">
                  <div class="form-group">
                    <p-calendar [(ngModel)]="rule.date" [showIcon]="true" aria-hidden="true"
                                (ngModelChange)="setChange()" [yearNavigator]="true" [yearRange]="getYearRange()" [readonlyInput]="true"></p-calendar>
                  </div>
                </div>
              </div>
              <div class="col-sm-1 col-md-push-1">
                <button id="removeAdv" type="button" pButton class="p-button-sm p-button-danger" (click)="removeRule(group,rule)" icon="fas fa-minus-circle"></button>
              </div>
            </div>
          </div>
        </div>
        <eclipse-data-query-advance-legacy [currentAdvanceQueryGroups]="group.groups" [availableColumnList]="availableColumnList" [selectedColumnList]="selectedColumnList"
                                      [(advanceQueryChanges)]="advanceQueryChanges">
        </eclipse-data-query-advance-legacy>
      </div>
    </div>
  </div>
</div>
