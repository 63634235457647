import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { NotesModule } from '../../notes/notes.module';
import { WebComponentsModule } from '@oat/web-components';
import { PortfolioEditorComponent } from './portfolio-editor.component';
import { PortfolioSummaryComponent } from './portfolio-summary.component';
import { PortfolioAccountsComponent } from './portfolio-accounts.component';
import { PortfolioNotesComponent } from './portfolio-notes.component';
import { PortfolioCompareToolComponent } from './portfolio-compare-tool.component';
import { PortfolioService } from '../../../services/portfolio.service';
import { AccountService } from '../../../services/account.service';
import { CompareToolWrapperComponent } from '../compare-tool-wrapper/compare-tool-wrapper.component';
import { PortfolioCashComponent } from './portfolio-cash.component';
import { PortfolioGainLossComponent } from "./portfolio-gain-loss.component";
import { PortfolioHoldingsComponent } from "./portfolio-holdings.component";

@NgModule({
  imports: [
    SharedModule,
    NotesModule,
    WebComponentsModule,
    CompareToolWrapperComponent
  ],
  declarations: [
    PortfolioEditorComponent,
    PortfolioSummaryComponent,
    PortfolioAccountsComponent,
    PortfolioNotesComponent,
    PortfolioCompareToolComponent,
    PortfolioCashComponent,
    PortfolioGainLossComponent,
    PortfolioHoldingsComponent
  ],
  exports: [
    PortfolioEditorComponent,
  ],
  providers: [
    PortfolioService,
    AccountService,
  ]
})

export class PortfolioEditorModule {
}
