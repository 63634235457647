import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { PortfolioEditorService } from './portfolio-editor.service';
import { PortfolioEntity } from './types';
import { Subject, takeUntil } from 'rxjs';
import { ICashDetail } from 'src/app/models/tactical';
import { Utils as Util } from '../../../core/functions';
import * as Consts from '../../../libs/app.constants';
import { ColDef } from '@ag-grid-community/core';

@Component({
  selector: 'eclipse-portfolio-editor-cash',
  templateUrl: './portfolio-cash.component.html',
  styleUrls: ['./portfolio-cash.component.scss']
})
export class PortfolioCashComponent extends BaseComponent {
  private destroyed$: Subject<void> = new Subject<void>();
  portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  isSleevePortfolio: boolean = false;
  canReadModels: boolean = Util.getPermission(Consts.PRIV_MODELS)?.canRead;

  cashDetailsModel: {
    totalCash: ICashDetail[],
    setAsideCash: ICashDetail[],
    modelAndRebal: ICashDetail[],
    billingCash: ICashDetail[],
    reserveCash: ICashDetail[]
  } = { totalCash: [], setAsideCash: [], modelAndRebal: [], billingCash: [], reserveCash: [] };

  cashDetailColDefs: ColDef[];
  cashGridContext = {
    self: this,
  };
  defaultColDef: ColDef = <ColDef>{
    editable: false,
    cellClass: 'text-right',
    filter: 'number',
    enableCellChangeFlash: true,
  };
  showCashDetails: boolean = false;

  constructor() {
    super();

    this.portfolioEditorService.portfolio$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((portfolio: PortfolioEntity) => {
        this.isSleevePortfolio = portfolio.isSleevePortfolio;
      });
  }

  calculateCashDetails(): void {
    this.cashDetailsModel = {
      totalCash: [
        { name: 'Current $', dollarValue: 0.00 },
        { name: 'Current %', percentValue: 0.00 },
        { name: 'Post $', dollarValue: 0.00 },
        { name: 'Post %', percentValue: 0.00 },
        { name: 'Lower Tol. $', dollarValue: 0.00 },
        { name: 'Lower Tol. %', percentValue: 0.00 },
        { name: 'Target $', dollarValue: 0.00 },
        { name: 'Target %', percentValue: 0.00 },
        { name: 'Upper Tol. $', dollarValue: 0.00 },
        { name: 'Upper Tol. %', percentValue: 0.00 },
        { name: 'Current-Trade Needed $', dollarValue: 0.00 },
        { name: 'Current-Trade Needed %', percentValue: 0.00 },
        { name: 'Current-Trade Needed % Relative', percentValue: 0.00 },
        { name: 'Post-Trade Needed $', dollarValue: 0.00 },
        { name: 'Post-Trade Needed %', percentValue: 0.00 },
        { name: 'Post-Trade Needed % Relative', percentValue: 0.00 }
      ],
      setAsideCash: [
        { name: 'Set Aside $', dollarValue: 0.00 },
        { name: 'Set Aside %', percentValue: 0.00 },
        { name: 'Set Aside Min $', dollarValue: 0.00 },
        { name: 'Set Aside Min %', percentValue: 0.00 },
        { name: 'Set Aside Target $', dollarValue: 0.00 },
        { name: 'Set Aside Target %', percentValue: 0.00 }
      ],
      modelAndRebal: [
        { name: 'M&R Current $', dollarValue: 0.00 },
        { name: 'M&R Current %', percentValue: 0.00 },
        { name: 'M&R Post $', dollarValue: 0.00 },
        { name: 'M&R Post %', percentValue: 0.00 },
        { name: 'M&R Lower Tol. $', dollarValue: 0.00 },
        { name: 'M&R Lower Tol. %', percentValue: 0.00 },
        { name: 'M&R Target $', dollarValue: 0.00 },
        { name: 'M&R Target %', percentValue: 0.00 },
        { name: 'M&R Upper Tol. $', dollarValue: 0.00 },
        { name: 'M&R Upper Tol. %', percentValue: 0.00 },
        { name: 'M&R Current-Trade Needed $', dollarValue: 0.00 },
        { name: 'M&R Current-Trade Needed %', percentValue: 0.00 },
        { name: 'M&R Current-Trade Needed % Relative', percentValue: 0.00 },
        { name: 'M&R Post-Trade Needed $', dollarValue: 0.00 },
        { name: 'M&R Post-Trade Needed %', percentValue: 0.00 },
        { name: 'M&R Post-Trade Needed % Relative', percentValue: 0.00 },
      ],
      billingCash: [
        { name: 'Billing $', dollarValue: 0.00 },
        { name: 'Billing %', percentValue: 0.00 },
        { name: 'Billing Target $', dollarValue: 0.00 },
        { name: 'Billing Target %', percentValue: 0.00 }
      ],
      reserveCash: [
        { name: 'Reserve $', dollarValue: 0.00 },
        { name: 'Reserve %', percentValue: 0.00 },
        { name: 'Reserve Target $', dollarValue: 0.00 },
        { name: 'Reserve Target %', percentValue: 0.00 }
      ]
    };
  }
}
