import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { isObservable, Observable, of, Subject, switchMap } from 'rxjs';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';

/**
 * Actions button with popup list of action items.
 *
 * Action items are provided to the button via the `menuItems$` observable.  The items can be provided
 * at any time, but would normally be emitted in response to the `menuOpened` event.
 */
@Component({
  selector: 'eclipse-actions-button',
  templateUrl: './actions-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsButtonComponent {
  @ViewChild('menu') menu!: Menu;
  /**
   * Observable that contains the array of menu items.
   */
  @Input() menuItems!: Observable<MenuItem[]> | MenuItem[]; // Observable provided by the parent component

  @Input() disabled: boolean = false;

  @Input() buttonClass: string = 'p-button-secondary';
  @Input() buttonIcon: string = 'fas fa-sparkles';
  @Input() buttonLabel: string = 'Actions';

  /**
   * The menu toggled open.  The host should use this event to emit new values into the `menuItems$` observable.
   */
  @Output() menuOpened = new EventEmitter<void>(); // Event emitted when menu is opened

  /**
   * Subject that triggers the `menuItems$` observable to run.
   * @private
   */
  private menuToggleSubject = new Subject<void>();

  /**
   * Observable that conveys the menu items from the host to the menu.  The host must emit a new array
   * of menu items through the `menuItems$` observable.
   * @protected
   */
  public readonly items$: Observable<any[]> = this.menuToggleSubject.pipe(
    switchMap(() => {
      if (isObservable(this.menuItems)) {
        return this.menuItems;
      } else {
        return of(this.menuItems);
      }
    }),
  );

  /**
   * The menu has been toggled open.  Fires the events that will cause the host to send a new list of menu items.
   */
  protected onMenuToggle(evt: MouseEvent) {
    this.menu.toggle(evt);
    this.menuToggleSubject.next(); // Emit value to trigger the observable
    this.menuOpened.emit(); // Emit event to notify the parent component
  }

  /**
   * Causes the menu to prompt for a refresh of its items.
   */
  public refreshMenu(): void {
    this.menuToggleSubject.next();
  }
}
