import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { CustomSubService } from '../customSubService';
import { HTTP_CONSTANTS } from '../../libs/app.constants';

/**
 * HTTP Interceptor for tracking active requests.  If the request contains the `x-progress` header,
 * the request is sent into beforeQueueRequest before continuing and into the afterQueueRequest
 * after the response has completed.
 *
 * The `x-progress` header is removed before leaving this interceptor since its only purpose is
 * to track whether a request should be tracked or not.
 */
@Injectable()
export class RequestQueueHttpInterceptor implements HttpInterceptor {
  private readonly _subService: CustomSubService = inject(CustomSubService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.addRequestHandlers(request, next);
  }

  private addRequestHandlers(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const uuid = request.headers.get(HTTP_CONSTANTS.REQUEST_ID_HEADER_KEY);

    if (request.headers.has(HTTP_CONSTANTS.SKIP_PROGRESS_HEADER_KEY)) {
      const modifiedReq = request.clone({
        headers: request.headers.delete(HTTP_CONSTANTS.SKIP_PROGRESS_HEADER_KEY),
      });
      return next.handle(modifiedReq);
    }

    this._subService.beforeQueueRequest.emit({url: request.url, uuid: uuid});
    return next
      .handle(request)
      .pipe(
        finalize(() => {
          this._subService.afterQueueRequest.emit({url: request.url, uuid: uuid});
        }),
      );
  }
}
