import {Component, OnDestroy, ViewChild, HostListener} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Subscription, of} from 'rxjs';
import {BaseComponent} from '../../../core/base.component';
import {UntypedFormBuilder} from '@angular/forms';
import { GridOptions, ColDef, CsvExportParams, GridReadyEvent } from '@ag-grid-community/core';
import {ISavedView, IExitWarning} from '../../../viewModels/savedview';
import {
  IQuery,
  IMasterColumns,
  IAddQuery,
  IGroups,
  IRules,
  IRunQueryTabsData,
  IMacros,
  apiKeyValues
} from '../../../models/querybuilder';
import {QueryBuilderService} from '../../../services/querybuilder.service';
import {QueryBuilderDataService} from '../../../services/querybuilderdata.service';
import {AlertService} from '../../../core';
import * as Consts from '../../../libs/app.constants';
import { Utils as Util } from '../../../core/functions';
import {DataQueryStandardComponent} from '../shared/dataquerystandard.component';
import {DataQueryAdvanceComponent} from '../shared/dataqueryadvance.component';
import {BaseGridConfiguration} from '../../../shared/gridextensions/basegrid';

type QueryTabType = 'Standard' | 'Advanced';

@Component({
  selector: 'eclipse-data-queries',
  templateUrl: './dataqueries.component.html',
  styleUrls: ['./dataqueries.component.scss'],
})
export class DataQueriesComponent extends BaseComponent implements OnDestroy {
  availableColumnList: IMasterColumns[] = [];
  selectedColumnList: any[] = [];
  savedView: ISavedView = <ISavedView>{};
  actionType: string = '';
  finalQueryAdvanced: string;
  finalQueryStandard: any;
  runQueryTabsData: IRunQueryTabsData[] = [];
  activeTabData: IRunQueryTabsData = <IRunQueryTabsData>{};
  dynamicResultActiveTabIndex: number = 0;
  selectedQuery: number = 0;
  displayDeleteConfirm: boolean = false;
  private queryResultGridOptions: GridOptions;
  private columnDefs: ColDef[];
  finalQueryObject: IQuery[] = [];
  gridOptionsAPI: apiKeyValues[] = [];
  queryDisplay: IQuery;
  tempQueryDisplay: any;
  copydisplayQuery: IQuery;
  addQuery: IAddQuery;
  public filterQueryText: any;
  public filterAvailableColumnsText: any;
  public filterDisplayAvailableColumnsText: any;
  public filterSelectedColumns: any = [];
  queryFilterData: IQuery[] = [];
  activeTabName: QueryTabType = 'Standard';
  availableColumnsFilterData: any[] = [];
  availableColumnsForQueryGeneration: any[] = [];
  MasteravailableColumns: any[] = [];
  isModified: boolean = false;
  macroList: any = [];
  showError: boolean = false;
  hideSaveButton: boolean = false;
  hideSave: boolean = false;
  subscription: Subscription;
  isCloseOpenedQuery: boolean = false;
  isSaved: boolean = false;
  showQueryNameErr: boolean = false;
  nextAction: string = '';
  // saveHideInStandardTab: boolean = false;
  saveHideInAdvanceTab: boolean = false;
  noRecordsFound: boolean = false;
  // redirectRoute: string = '';
  hideRemoveButton: boolean = true;
  tabCountMaxm: boolean = false;
  sortArray: any[];
  saveDisable: boolean = false;
  gridContext = {
    self: this
  };
  activeTabIndex: number = 0;

  @ViewChild(DataQueryStandardComponent) _dataQueryStandardComponent: DataQueryStandardComponent;
  @ViewChild(DataQueryAdvanceComponent) _dataQueryAdvancedComponent: DataQueryAdvanceComponent;

  constructor(private _fb: UntypedFormBuilder, private _router: Router,
              private activateRoute: ActivatedRoute, private queryBuilderService: QueryBuilderService, private _alertService: AlertService,
              public queryBuilderDataService: QueryBuilderDataService) {

    super();

    // This will prepare the html controls.
    this.createNewQuery('Standard');

    // Set the selectedQueryId value from Shared service
    this.queryBuilderDataService.runQueryIds = [];

    this.selectedQuery = 0;

    this.selectedQuery = +this.queryBuilderDataService.selectedQueryId;
    if (isNaN(this.selectedQuery)) {
      this.selectedQuery = 0;
    }

    // Check the action to perform - Shared service
    this.performAction(this.queryBuilderDataService.actionType);
    // this.route = Util.activeRoute(activateRoute);
    this.queryDisplay.isChanged = false;

    this.queryResultGridOptions = BaseGridConfiguration.generateExtendedFilterGridOptions(this.defaultGridOptions, this);

    this.getAvailableColumns();

    // Subscribed to changes from left panel and perform actions based on action type
    this.subscription = queryBuilderDataService.contentLeftPanel$.subscribe(
      data => {
        // Perform action based on received service command
        this.selectedQuery = this.queryBuilderDataService.selectedQueryId;
        this.performAction(data);
      });
    this.queryDisplay.showSameRuleError = false;
    this.queryDisplay.emptyRuleValidation = false;
  }

  // Method to perform actions
  performAction(data: string) {
    switch (data) {
      case 'refresh':
        this.createNewQuery('Standard');
        this.queryBuilderDataService.selectedQueryId = 0;
        this.getQueryList();
        break;
      case 'create':
        // this.selectedQuery = 0;
        this.createNewQuery('Standard');
        break;
      case 'run':
        this.runQuery(+this.selectedQuery);
        break;
      case 'view':
        this.isQueryChanged('LP_OPEN');
        // eslint-disable-next-line eqeqeq
        if (this.nextAction != 'LP_OPEN' && !this.isModified) {
          this.getQueryDetails(this.selectedQuery);
          this.dynamicResultActiveTabIndex = 0;
        }
        break;
      case 'copy':
        this.copyQuery();
        this.dynamicResultActiveTabIndex = 0;
        break;
      case 'deleteFromLP':
        // On Query Delete, remove tab if RESULT-TAB is already opened.
        this.deleteQuery(this.selectedQuery);
        this.queryBuilderDataService.selectedQueryId = 0;
        this.getQueryList();
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.queryBuilderDataService.selectedQueryId = 0;
    this.queryBuilderDataService.actionType = '';
    this.queryBuilderDataService.runQueryIds = [];
    this.selectedQuery = 0;
    // Shared service - prevent memory leak when component destroyed.
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.activateRoute.params.subscribe((params: Params) => {
      // this.getQueryStringData(this.activateRoute);
    });
    // this.getAvailableColumns();
    this.savedView = <ISavedView>{
      exitWarning: <IExitWarning>{}
    };
    this.getQueryList();
    this.getMacroList();
  }

  // To get Query List from DataBase
  getQueryList() {
    this.queryBuilderService.getQueryList()
      .subscribe((querylist: IQuery[]) => {
        this.finalQueryObject = querylist;
        this.queryFilterData = querylist;

        // assigning updated query name in text field
        const queriesList = this.finalQueryObject;
        // eslint-disable-next-line eqeqeq
        if (this.queryDisplay.id != undefined || this.queryDisplay.id != null) {
          // eslint-disable-next-line eqeqeq
          this.queryDisplay.name = queriesList.find(x => x.id == this.queryDisplay.id).name;
        }

        this.filterDataQuery();
      });
  }

  // Get availableColumns from DataBase
  getAvailableColumns() {
    this.queryBuilderService.getMasterColumns()
      .subscribe((columns: IMasterColumns) => {
        this.MasteravailableColumns = (<any>Object).values(columns);
        this.availableColumnsForQueryGeneration = (<any>Object).values(columns);
        this.availableColumnList = (<any>Object).values(columns);
        this.availableColumnsFilterData = (<any>Object).values(columns);
      });
  }

  /* Move right to left of columns*/
  dataQueryColumn_moveacrossAvailable(sourceID, destID) {
    // eslint-disable-next-line eqeqeq
    const surLst = this.availableColumnList.filter(x => x.isSelected == true);
    const deleteIds = [];
    const destList = this.queryDisplay.selectedColumns;
    for (let count = 0; count < surLst.length; count++) {
      const option = surLst[count];
      destList.push({
        id: option.id,
        name: option.displayName,
        dataType: option.dataType,
        moduleId: option.moduleId,
        moduleName: option.moduleName,
        isSelected: false,
        operator: option.operator,
        value: null,
        displayName: option.displayName,
        isPredefined: option.isPredefined,
        columnDisplayOrder: 0
      });
      deleteIds.push(option.id);
    }
    deleteIds.forEach(element => {
      // eslint-disable-next-line eqeqeq
      this.availableColumnList.splice(this.availableColumnList.findIndex(x => x.id == element), 1);
    });
    this.filterSelectedColumns = [];
    this.filterDisplayAvailableColumnsText = [];
    this.setTradeGroupForColumns();
  }

  /* Move left to right of columns*/
  dataQueryColumn_moveacrossSelected(sourceID, destID) {
    const deleteIds = [];
    // eslint-disable-next-line eqeqeq
    const srcList = this.queryDisplay.selectedColumns.filter(x => x.isSelected == true);
    for (let count = 0; count < srcList.length; count++) {
      const option = srcList[count];
      this.availableColumnList.push({
        id: option.id,
        displayName: option.displayName,
        isSelected: false,
        dataType: option.dataType,
        isPredefined: option.isPredefined,
        moduleId: option.moduleId,
        moduleName: option.moduleName,
        name: option.name,
        value: option.value,
        columnDisplayOrder: option.columnDisplayOrder,
        operator: option.operator
      });
      deleteIds.push(option.id);
    }
    deleteIds.forEach(element => {
      // eslint-disable-next-line eqeqeq
      this.queryDisplay.selectedColumns.splice(this.queryDisplay.selectedColumns.findIndex(x => x.id == element), 1);
    });
    this.availableColumnList.sort((a, b) => { // sort() mutates the array
      if (a.displayName > b.displayName) {
        return 1;
      }
      if (a.displayName < b.displayName) {
        return -1;
      }
      return 0;
    });
    this.setTradeGroupForColumns();
    this.filterSelectedColumns = [];
    this.filterDisplayAvailableColumnsText = [];
    if (this.activeTabName === 'Standard') {
      this._dataQueryStandardComponent.removeSelectedRow(srcList);
    } else {
      this._dataQueryAdvancedComponent.removeSelectedRow(srcList);
    }
  }

  /* To change the order of availableColumns columns */
  onChangeAvailableColumn(event) {
    this.queryDisplay.isChanged = true;
    this.availableColumnList.forEach(x => x.isSelected = false);
    for (let i = 0; i < event.target.selectedOptions.length; i++) {
      if (event.target.selectedOptions[i].label) {
        // eslint-disable-next-line eqeqeq
        const getDataSelected = this.availableColumnList.find(x => x.displayName == event.target.selectedOptions[i].label);
        getDataSelected.isSelected = true;
      }
    }
  }

  /* To change the order of selectedColumns columns */
  onChangeSelectedColumn(event) {
    this.queryDisplay.isChanged = true;
    this.queryDisplay.selectedColumns.forEach(x => x.isSelected = false);
    for (let i = 0; i < event.target.selectedOptions.length; i++) {
      if (event.target.selectedOptions[i].label) {
        // eslint-disable-next-line eqeqeq
        const getDataSelected = this.queryDisplay.selectedColumns.find(x => x.displayName == event.target.selectedOptions[i].label);
        getDataSelected.isSelected = true;
      }
    }
  }

  /* Disables greater button till column is selected */
  isDisabledGreaterBtn() {
    let isValidGreaterThanArrow: boolean = false;
    // eslint-disable-next-line eqeqeq
    if (this.availableColumnList != undefined) {
      // eslint-disable-next-line eqeqeq
      const isvalid = this.availableColumnList.filter(x => x.isSelected == true);
      isValidGreaterThanArrow = (isvalid.length > 0) ? false : true;
    } else {
      isValidGreaterThanArrow = false;
    }
    return isValidGreaterThanArrow;
  }

  /* Disables lessthan button till column is selected */
  isDisabledLessThanBtn() {
    let isValidLessThanArrow: boolean = false;
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.selectedColumns != undefined) {
      // eslint-disable-next-line eqeqeq
      const isvalid = this.queryDisplay.selectedColumns.filter(x => x.isSelected == true);
      isValidLessThanArrow = (isvalid.length > 0) ? false : true;
    } else {
      isValidLessThanArrow = false;
    }
    return isValidLessThanArrow;
  }

  /* Move Up & down */
  dataQueryColumn_move(direction: 'up' | 'down') {
    const selectedColumnIndices = [];
    let increment: number;
    this.queryDisplay.selectedColumns.forEach(function (value, index) {
      if (value.isSelected === true) {
        selectedColumnIndices.push(index);
      }
    });
    if (direction === 'up') {
      increment = -1;
    } else {
      selectedColumnIndices.reverse();
      increment = 1;
    }
    selectedColumnIndices.forEach(selectedIndex => {
      if ((selectedIndex + increment) < 0 ||
        (selectedIndex + increment) > (this.queryDisplay.selectedColumns.length - 1)) {
        return;
      }
      this.itemMovement(selectedIndex, increment);
    });
    this.filterSelectedColumns = [];
  }

  /**Function to move list item up or down based on index */
  private itemMovement(selectedIndex: any, increment: number) {
    const selValue = this.queryDisplay.selectedColumns[selectedIndex].id;
    const selText = this.queryDisplay.selectedColumns[selectedIndex].displayName;
    this.queryDisplay.selectedColumns[selectedIndex].id = this.queryDisplay.selectedColumns[selectedIndex + increment].id;
    this.queryDisplay.selectedColumns[selectedIndex].displayName = this.queryDisplay.selectedColumns[selectedIndex + increment].displayName;
    this.queryDisplay.selectedColumns[selectedIndex + increment].id = selValue;
    this.queryDisplay.selectedColumns[selectedIndex + increment].displayName = selText;
    this.queryDisplay.selectedColumns[selectedIndex + increment].isSelected = true; // set the new index as selected//BugFix: OEF-6152 MI #1312 > Query Builder > Sorting Query columns
    this.queryDisplay.selectedColumns[selectedIndex].isSelected = false; // set the previous index as unselected
  }

  /** Confirm when we are away from page */
  confirmClick(status: boolean, id: number = null, nextAction: string = '') {
    this.showQueryNameErr = false;
    this.queryDisplay.isChanged = false;
    this.isModified = false;
    if (status) {
      this.saveQuery(this.tempQueryDisplay.id, 'Yes');
    } else {
      this.showError = false;
      this.isSaved = false;
      this.saveDisable = false;
      // Checking the action - closing of opened query (clicking on 'X')
      if (this.isCloseOpenedQuery) {
        this.isCloseOpenedQuery = false;
        this.createNewQuery('Standard');
      }
      // If 'No' clicked and if there is an action to perform, redirect here
      // eslint-disable-next-line eqeqeq
      if (this.nextAction == 'CREATE') {
        this.createNewQuery('Standard');
      // eslint-disable-next-line eqeqeq
      } else if (this.nextAction == 'COPY') {
        // this.displayQueryDetails();//TODO : copying
        this.queryBuilderService.getQueryDetails(this.selectedQuery)
          .subscribe((querydetails: IQuery) => {
            this.queryDisplay = querydetails;
            this.copydisplayQuery = this.queryDisplay;
            this.queryDisplay.isFavourite = querydetails.isFavourite ? true : false;
            this.queryDisplay.isAdvanced = querydetails.isAdvanced ? true : false;
            this.copyQuery();
          });

      // eslint-disable-next-line eqeqeq
      } else if (this.nextAction == 'RUN') {
        this.runQuery(this.selectedQuery);
      // eslint-disable-next-line eqeqeq
      } else if (this.nextAction == 'LP_OPEN') {
        this.getQueryDetails(this.selectedQuery);
        this.dynamicResultActiveTabIndex = 0;
      // eslint-disable-next-line eqeqeq
      } else if (this.nextAction == 'redirectRoute') {
        // eslint-disable-next-line eqeqeq
        if (this.savedView.exitWarning.observer != undefined) {
          this.queryBuilderDataService.selectedQueryId = 0;
          this.queryBuilderDataService.performActionOnLPanel('refresh'); // This will clear the active query selection and rebinds the list
          this.savedView.exitWarning.observer.next(!status);
          this.savedView.exitWarning.observer.complete();
        }
      // eslint-disable-next-line eqeqeq
      } else if (this.nextAction == 'resultTab') {
        // eslint-disable-next-line eqeqeq
        const queryId = !!this.dynamicResultActiveTabIndex && !!this.runQueryTabsData[this.dynamicResultActiveTabIndex - 1].id ?
          this.runQueryTabsData[this.dynamicResultActiveTabIndex - 1].id : 0;
        if (+queryId !== 0) {
          this.nextAction = '';
          this.getQueryDetails(this.selectedQuery);
          this.tabClick(this.dynamicResultActiveTabIndex);
        }
      } else {
        this.displayQueryDetails();
      }
      this.nextAction = '';
    }
  }

  /** Confirm when we are update the query */
  confirmUpdateClick(id) {
    this.queryDisplay.isChanged = false;
    this.isModified = false;
    // if (status == true) {
    this.tempQueryDisplay = this.queryDisplay;
    this.saveQuery(id, 'Yes');

    // }
    // else {
    //     //Checking the action - closing of opened query (clicking on 'X')
    //     if (this.isCloseOpenedQuery) {
    //         this.isCloseOpenedQuery = false;
    //         this.createNewQuery("Standard");
    //     }
    //     else
    //         this.displayQueryDetails();
    // }
  }

  /* Filter the Query */
  filterDataQuery() {
    this.finalQueryObject = [];
    // eslint-disable-next-line eqeqeq
    if (this.filterQueryText != '') {
      const data = this.queryFilterData.filter(x => x.name.toLowerCase()
        .indexOf(this.filterQueryText.toLowerCase()) >= 0);
      this.finalQueryObject = data;
      // eslint-disable-next-line eqeqeq
      if (this.finalQueryObject.length == 0) {
        this.noRecordsFound = true;
      } else {
        this.noRecordsFound = false;
      }
    } else {
      this.finalQueryObject = this.queryFilterData;
      this.noRecordsFound = false;
    }
  }

  // Delete Query from Action Menu
  confirmDeleteQuery(id) {
    this.selectedQuery = id;
    this.displayDeleteConfirm = true;
  }

  // confirm delete in popup for delete query
  confirmDeleteClick(isDelete: boolean) {
    if (isDelete) {
      this.deleteQuery(this.selectedQuery);
    }
    this.displayDeleteConfirm = false;
  }

  // Delete Query
  deleteQuery(id) {
    this.showQueryNameErr = false;
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.isChanged == true) {
      // When deleting QUERY, no need to update the query changes
      this.queryDisplay.isChanged = false;
      this.nextAction = '';
    }
    this.queryBuilderService.deleteQuery(id).subscribe(deleteQuery => {
        this._alertService.alert.emit([{typeId: 1, message: 'Query deleted successfully'}]);
        this.deleteTab(id);
        this.dynamicResultActiveTabIndex = 0; // making QueryBuilder Window active
        // To Reload fav query list on dashboard left panel - sending command through shared service
        this.queryBuilderDataService.selectedQueryId = 0;
        this.queryBuilderDataService.performActionOnLPanel('refresh');
        this.getQueryList();
        this.createNewQuery('Standard');
      },
      error => {
        this._alertService.alert.emit([{typeId: 4, message: error.message}]);
      });
  }

  // To save query into DB
  saveQuery(id, value) {
    this.saveDisable = true;
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.isChanged == true && id != undefined) {
      this.isSaved = true;
    } else {
      // Fixed the issue when user switches from one query to another
      const objGroup = <IGroups>{
        condition: 'AND',
        rules: <IRules[]>[],
        groups: <IGroups[]>[]
      };
      if (this.activeTabName === 'Advanced') {
        this.queryDisplay.where.rules = <IRules[]>[];
      } else {
        this.queryDisplay.where.groups = <IGroups[]>[objGroup];
      }
      // End of fix

      /*** This is to fix the update message*/
      // eslint-disable-next-line eqeqeq
      if (this.queryDisplay.isChanged == undefined && this.isModified == false) {
        return false;
      } else {
        this.queryDisplay.isChanged = false;
        this.isModified = false;
        // eslint-disable-next-line eqeqeq
        if (value == 'Yes') {
          // eslint-disable-next-line eqeqeq
          if (this.tempQueryDisplay != undefined) {
            this.queryDisplay = this.tempQueryDisplay;
          }
        }
        const mColumn = <IMasterColumns>{};
        const mColumnArray = [];
        this.queryDisplay.selectedColumns.forEach(item => {
          mColumn.id = item.id;
          mColumnArray.push(mColumn.id);
        });
        this.addQuery = <IAddQuery>{
          name: this.queryDisplay.name,
          where: this.queryDisplay.where,
          columnIds: mColumnArray,
          isAdvanced: this.queryDisplay.isAdvanced,
          isFavourite: this.queryDisplay.isFavourite,
          isTradeGroup: this.queryDisplay.isTradeGroup
        };
        // eslint-disable-next-line eqeqeq
        if (id != undefined || id != null) {
          this.isModified = false;
          this.queryBuilderService.updateQuery(this.addQuery, id)
            .subscribe((result: IAddQuery) => {
                this._alertService.alert.emit([{typeId: 1, message: 'Query updated successfully'}]);
                this.getQueryList(); // If we saerch any query, and updated it, still we are getting whole list instead of updated record
                // this.filterDataQuery();
                this.queryDisplay.isChanged = false;
                this.saveDisable = false;
                this.isSaved = false;
                this.tempQueryDisplay.where.groups.isChanged = false;
                // if query result is  already open and updated name then need to update name of the query(tab)
                // eslint-disable-next-line eqeqeq
                const tab = this.runQueryTabsData.find(rec => rec.id == id);
                // eslint-disable-next-line eqeqeq
                if (tab != undefined) {
                  tab.name = this.queryDisplay.name;
                }// updating name

                // perform actions based on 'nextAction' value
                if (this.isCloseOpenedQuery) {
                  this.isCloseOpenedQuery = false;
                  this.createNewQuery('Standard');
                }
                // eslint-disable-next-line eqeqeq
                if (this.nextAction == 'CREATE' || this.nextAction == 'DELETE') {
                  this.createNewQuery('Standard');
                // eslint-disable-next-line eqeqeq
                } else if (this.nextAction == 'COPY') {
                  this.copyQuery();
                // eslint-disable-next-line eqeqeq
                } else if (this.nextAction == 'OPEN') {
                  this.getQueryDetails(this.selectedQuery);
                // eslint-disable-next-line eqeqeq
                } else if (this.nextAction == 'LP_OPEN') {
                  this.getQueryDetails(this.selectedQuery);
                  this.dynamicResultActiveTabIndex = 0;
                // eslint-disable-next-line eqeqeq
                } else if (this.nextAction == 'redirectRoute') {
                  // This is used when clicks on other module, if any pending changes are there will show a popup, if click on NO button, will perform next action
                  // eslint-disable-next-line eqeqeq
                  if (this.savedView.exitWarning.observer != undefined) {
                    this.queryBuilderDataService.selectedQueryId = 0;
                    this.queryBuilderDataService.performActionOnLPanel('refresh');
                    this.savedView.exitWarning.observer.next(!status);
                    this.savedView.exitWarning.observer.complete();
                  }
                // eslint-disable-next-line eqeqeq
                } else if (this.nextAction == 'resultTab') {
                  // eslint-disable-next-line eqeqeq
                  const queryId = !!this.dynamicResultActiveTabIndex && !!this.runQueryTabsData[this.dynamicResultActiveTabIndex - 1].id ?
                    this.runQueryTabsData[this.dynamicResultActiveTabIndex - 1].id : 0;
                  // eslint-disable-next-line eqeqeq
                  if (+queryId != 0) {
                    // eslint-disable-next-line eqeqeq
                    if (this.queryDisplay.id == +queryId) {
                      this.runQuery(this.queryDisplay.id);
                    } else {
                      // this.runQueryTabsData.find(rec => rec.id == id).name = this.queryDisplay.name;//updating name
                      this.runQuery(+queryId);
                    }
                  }
                }
                // Reset nextAction value
                this.nextAction = '';

                // Sending 'refresh' command through Shared service to get Favourite Queries in left panel as query can be updated as favourite
                this.queryBuilderDataService.performActionOnLPanel('refresh');
              },
              error => {
                this._alertService.alert.emit([{typeId: 4, message: error.message}]);
              });
        } else {
          this.queryBuilderService.addQuery(this.addQuery)
            .subscribe((result: IAddQuery) => {
                this._alertService.alert.emit([{typeId: 1, message: 'Query saved successfully'}]);
                this.getQueryList();
                this.queryDisplay.isChanged = false;
                this.saveDisable = false;
                this.createNewQuery('Standard');
                // Sending 'refresh' command through Shared service to get Favourite Queries in left panel as query can be created as favourite
                this.queryBuilderDataService.performActionOnLPanel('refresh');
              },
              error => {
                this._alertService.alert.emit([{typeId: 4, message: error.message}]);
              });
        }
      }
    }

  }

  // Used to generate Query string for advanced query
  getQueryString(group) {
    this.finalQueryAdvanced = this.generateQuery(group.where.groups[0]);
    // if (this.finalQueryAdvanced == '' || this.finalQueryAdvanced == null) {
    //     this.hideSaveButton = true;
    // }
  }

  // Autogenerate query
  autoGenerateQuery() {
    // eslint-disable-next-line eqeqeq
    if (this.activeTabName == 'Standard') {
      this.autoGenerateStandardQuery();
    } else {
      this.autoGenetareAdvanceQuery();
    }
  }

  // Used to call automatically for standardQuery
  autoGenerateStandardQuery() {
    if (this.queryDisplay && this.queryDisplay.where.rules.length > 0) {
      this.getStandardQueryString(this.queryDisplay.where.rules);
    } else {
      this.finalQueryStandard = '';
      // this.hideSaveButton = true;
      this.queryDisplay.showSameRuleError = false;
      this.hideSave = false;
      this.hideSaveButton = false;
      // this.saveHideInStandardTab = true;
      // this.saveHideInAdvanceTab = false;
    }
  }

  // Used to call automatically for AdvanceQuery
  autoGenetareAdvanceQuery() {
    if (this.queryDisplay) {
      this.getQueryString(this.queryDisplay);
    } else {
      this.finalQueryAdvanced = '';
      // this.hideSaveButton = true;
    }
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.where.groups[0].groups.length == 0 && this.queryDisplay.where.groups[0].rules.length == 0) {
      this.hideRemoveButton = true;
    }
  }

  // Used to generate Query
  generateQuery(group) {
    let stringMainQuery = '(';
    let stringSubQuery = '';
    const MasterAvailableColumnsCopy = this.MasteravailableColumns;
    let count = 0;
    // eslint-disable-next-line eqeqeq
    if (group != undefined) {
      // eslint-disable-next-line eqeqeq
      if (group.rules != undefined) {
        count = group.rules.length;
        group.rules.forEach(rule => {
          // eslint-disable-next-line eqeqeq
          if ((group.condition != null) && (MasterAvailableColumnsCopy.length > 0) && (rule.selectedColumnId != null || rule.selectedColumnId != undefined) && (rule != undefined)) {
            // eslint-disable-next-line eqeqeq
            rule.selectedColumn = MasterAvailableColumnsCopy.find(x => x.id == rule.selectedColumnId).displayName;
          }
          const setQueryParam = this.setQueryValue(rule);
          stringMainQuery += `${rule.selectedColumn} ${rule.operator} ${this.setQueryValue(rule)} ${group.condition} `;
          // eslint-disable-next-line eqeqeq
          this.hideSaveButton = (rule.selectedColumn == null || rule.selectedColumn == '' || rule.operator == null || rule.operator == '' || setQueryParam == null || setQueryParam == '' || group.condition == null) ? true : false;
          if (this.hideSaveButton) {
            this.hideSave = true;
          } else {
            count--;
          }
        });
      }
      // eslint-disable-next-line eqeqeq
      if (group.groups != undefined) {
        group.groups.forEach(subGroup => {
          const recQuery = this.generateQuery(subGroup);
          if (recQuery.length > 0) {
            stringSubQuery += ' ' + this.generateQuery(subGroup);
          }
        });
        // eslint-disable-next-line eqeqeq
        if (count != 0) {
          this.hideSave = true;
          this.hideSaveButton = true;
          this.saveHideInAdvanceTab = true;
        } else {
          this.saveHideInAdvanceTab = false;
          this.hideSaveButton = false;
        }

        // For empty query we cannot consider count. So directly using rules lenght
        // if (this.activeTabName == 'Advance') {
        //     if (this.queryDisplay.where.groups != undefined && this.queryDisplay.where.groups[0].groups.length == 0 && this.queryDisplay.where.groups[0].rules.length == 0) {
        //         this.saveHideInAdvanceTab = true;
        //     }
        // }
      }
      // eslint-disable-next-line eqeqeq
      if (stringSubQuery.length == 0) {
        stringMainQuery = stringMainQuery.slice(0, stringMainQuery.length - (group.condition.length + 2));
        stringMainQuery += stringSubQuery;
      } else {
        stringMainQuery += stringSubQuery;
      }
      return stringMainQuery.length > 0 ? stringMainQuery + ')' : '';
    } else {
      const objGroup = <IGroups>{
        condition: 'AND',
        rules: <IRules[]>[],
        groups: <IGroups[]>[]
      };
      this.queryDisplay.where.groups = <IGroups[]>[objGroup];
    }
  }

  // Used to generate query string for standard
  getStandardQueryString(rules) {
    // eslint-disable-next-line eqeqeq
    if (rules != undefined) {
      this.finalQueryStandard = this.generateStandardQuery(rules);
      const lastIndex = this.finalQueryStandard.lastIndexOf(this.queryDisplay.where.condition);
      this.finalQueryStandard = this.finalQueryStandard.substring(0, lastIndex);
      // eslint-disable-next-line eqeqeq
      if (this.finalQueryStandard != null && this.finalQueryStandard != '' && this.finalQueryStandard != undefined) {
        this.finalQueryStandard += ')';
      }
    } else {
      // this.hideSaveButton = true;
    }
  }

  // looping the standard query
  generateStandardQuery(rules) {
    let stringMainQuery = '(';
    const stringSubQuery = ')';
    let count = 0;
    const MasterAvailableColumnsCopy = this.MasteravailableColumns;
    count = rules.length;
    rules.forEach(rule => {
      // eslint-disable-next-line eqeqeq
      if (rule.operator != null && MasterAvailableColumnsCopy.length > 0 && rule.operator != undefined && (rule.selectedColumnId != null || rule.selectedColumnId != undefined)) {
        // eslint-disable-next-line eqeqeq
        rule.selectedColumn = MasterAvailableColumnsCopy.find(x => x.id == rule.selectedColumnId).displayName;
      }
      const setQueryParam = this.setQueryValue(rule);
      stringMainQuery += `${rule.selectedColumn} ${rule.operator} ${setQueryParam} ${this.queryDisplay.where.condition} `;
      // eslint-disable-next-line eqeqeq
      this.hideSaveButton = (rule.selectedColumn == null || rule.selectedColumn == '' || rule.operator == '' || rule.operator == null || setQueryParam == null || setQueryParam == '' || this.queryDisplay.where.condition == null) ? true : false;
      if (this.hideSaveButton) {
        this.hideSave = true;
      } else {
        count--;
      }
    });
    // eslint-disable-next-line eqeqeq
    if (count != 0) {
      this.hideSave = true;
      // this.hideSaveButton = true;
      // this.saveHideInStandardTab = true;
    } else {
      // this.saveHideInStandardTab = false;
      this.hideSave = false;
      this.hideSaveButton = false;
    }
    // Condition for first time when switched to Standard tab, if no rules present, will hide button
    // eslint-disable-next-line eqeqeq
    if (this.activeTabName == 'Standard' && this.queryDisplay.where.rules.length == 0) {
      // this.saveHideInStandardTab = true;
    }
    return stringMainQuery += stringSubQuery;
  }

  // Getting selectedColumn value in different scenarios
  setQueryValue(rule: IRules) {
    // eslint-disable-next-line eqeqeq
    if (rule.columnDateType == 'DATETIME' || rule.columnDateType == 'DATE') {
      // eslint-disable-next-line eqeqeq
      if (rule.macro != undefined) {
        // eslint-disable-next-line eqeqeq
        if (rule.macro != '0' && this.macroList.length > 0) {
          // eslint-disable-next-line eqeqeq
          if (this.macroList.find(x => x.id == rule.macro) != undefined) {
            // eslint-disable-next-line eqeqeq
            return this.macroList.find(x => x.id == rule.macro).name;
          }
        } else {
          return rule.date;
        }
      }
    // eslint-disable-next-line eqeqeq
    } else if ((rule.predefinedValue != null || rule.predefinedValue != undefined) && rule.isPredefined) {
      return rule.predefinedName;
    // eslint-disable-next-line eqeqeq
    } else if (rule.columnDateType == 'TINYINT') {
      // eslint-disable-next-line eqeqeq
      if (rule.value == '1') {
        return 'Yes';
      } else {
        return 'No';
      }
    } else {
      return rule.value;
    }
  }

  changedObj(params) {
    this.queryDisplay.isChanged = params;
  }

  // Getting query details when click on particular query
  getQueryDetails(queryId) {
    this.tabCountMaxm = false;
    // this.showQueryNameErr = false;
    this.filterAvailableColumnsText = '';
    this.tempQueryDisplay = this.queryDisplay;
    if (this.tempQueryDisplay.where.groups.isChanged) {
      this.queryDisplay.isChanged = true;
    }
    this.showError = false;
    // If one query is opened and made some changes then clicking on another query to open
    // save changes if any and then redirect
    // eslint-disable-next-line eqeqeq
    if (this.selectedQuery == queryId) {
      // eslint-disable-next-line eqeqeq
      if (this.queryDisplay.isChanged == true) {
        this.isModified = true;
      } else {
        this.displayQueryDetails();
      }
    } else {
      this.selectedQuery = queryId; // QueryID to open after saving changes
      // eslint-disable-next-line eqeqeq
      if (this.queryDisplay.isChanged == true) {
        this.nextAction = 'OPEN';
        this.isModified = true;
      } else {
        this.displayQueryDetails();
      }
    }
    // send command on query click to make changes in menu options
    this.queryBuilderDataService.selectedQueryId = this.selectedQuery;
    this.queryBuilderDataService.performActionOnLPanel('queryClick');
  }

  // Displaying the query details
  displayQueryDetails() {
    this.queryBuilderService.getQueryDetails(this.selectedQuery)
      .subscribe((querydetails: IQuery) => {
        this.queryDisplay = querydetails;
        this.copydisplayQuery = this.queryDisplay;
        this.queryDisplay.isFavourite = querydetails.isFavourite;
        this.queryDisplay.isAdvanced = querydetails.isAdvanced;
        this.showQueryNameErr = false;
        this.availableColumnList = this.availableColumnsForQueryGeneration;
        this.queryDisplay.selectedColumns.sort((s, t) => s.columnDisplayOrder - t.columnDisplayOrder);  // sort() mutates the array
        this.queryDisplay.selectedColumns.forEach(selectedColumn => {
          // eslint-disable-next-line eqeqeq
          this.availableColumnList = this.availableColumnList.filter(x => x.id != selectedColumn.id);
        });
        this.availableColumnsFilterData = this.availableColumnList;
        // eslint-disable-next-line eqeqeq
        if (this.queryDisplay.isAdvanced == true) {
          this.activeTabName = 'Advanced';
          this.getDivId(1);
        } else {
          this.activeTabName = 'Standard';
          this.getDivId(0);
        }
      });
  }

  // Getting div id when switch from standardQuery to AdvanceQuery or reverse
  getDivId(index: number) {
    this.activeTabIndex = index;
    if (index === 0) {
      this.queryDisplay.isAdvanced = false;
      this.activeTabName = 'Standard';
      this.saveHideInAdvanceTab = false;
      this.saveButtonValidationInStandard();
    } else {
      this.queryDisplay.isAdvanced = true;
      this.activeTabName = 'Advanced';
      this.hideSaveButton = false;
      this.saveButtonValidationInAdvanced();
    }
  }

  // Save button validation when switches from advanced tab to standard
  saveButtonValidationInStandard() {
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.where.rules.length == 0) {
      // this.saveHideInStandardTab = true;
    } else {
      // this.saveHideInStandardTab = false;
      this.saveHideInAdvanceTab = false;
    }
  }

  // Save button validation when switches from standard tab to  advanced
  saveButtonValidationInAdvanced() {
    if (this.queryDisplay.where.groups) {
      this.queryDisplay.where.groups.forEach(group => {
        // eslint-disable-next-line eqeqeq
        if (group.rules.length == 0) {
          this.saveHideInAdvanceTab = true;
        } else {
          this.saveHideInAdvanceTab = false;
          // this.saveHideInStandardTab = false;
        }
      });
    }
  }

  // setting query as favourite
  setFavourite() {
    this.queryDisplay.isChanged = true;
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.isFavourite == false) {
      this.queryDisplay.isFavourite = true;
    } else {
      this.queryDisplay.isFavourite = false;
    }
  }

  // fired when any changes made in query
  setChange() {
    this.queryDisplay.isChanged = true;
    this.tabCountMaxm = false;
  }

  /**This method is used to check if any changes available in opened query to save and to the action selected */
  isQueryChanged(nextAction) {
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay != undefined && this.queryDisplay.isChanged == true) {
      this.nextAction = nextAction; // action to perform should only set when 'isChanged' is 'true' otherwise it go into infinite loop
      this.isModified = true;
      this.tempQueryDisplay = this.queryDisplay; // copying query to save if selects 'YES'
    } else {
      this.nextAction = '';
    }
  }

  // To create new query
  createNewQuery(tabName: QueryTabType) {
    this.noRecordsFound = false;
    this.filterQueryText = '';
    // fixed copy query issue
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay != undefined) {
      if (!this.queryDisplay.isChanged) {
        this.showQueryNameErr = false;
      }
    }
    // End copy query issue
    this.finalQueryObject = this.queryFilterData;
    this.isQueryChanged('CREATE');
    // if any changes in existing opened query,shows popup first, it will be saved accordingly then opens empty query.
    // eslint-disable-next-line eqeqeq
    if (this.nextAction != 'CREATE' && !this.isModified) {
      this.queryBuilderService.getMasterColumns()
        .subscribe((columns: IMasterColumns[]) => {
          this.availableColumnList = (<any>Object).values(columns);
        });
      this.availableColumnsFilterData = this.availableColumnsForQueryGeneration;
      // eslint-disable-next-line eqeqeq
      if (this.actionType == 'copy') {
        this._router.navigate(['/eclipse/querybuilder']);
      }
      this.selectedQuery = 0;
      const objGroup = <IGroups>{
        condition: 'AND',
        rules: <IRules[]>[],
        groups: <IGroups[]>[]
      };
      const objWhere = <IGroups>{
        condition: 'AND',
        rules: <IRules[]>[],
        groups: <IGroups[]>[objGroup]
      };
      this.queryDisplay = <IQuery>{name: null, isAdvanced: false, where: objWhere, selectedColumns: []};
      this.queryDisplay.selectedColumns = [];
      this.activeTabName = tabName;
      this.finalQueryStandard = '';
      this.finalQueryAdvanced = '';
      // this.queryBuilderDataService.performActionOnLPanel('refresh');//Calling individually
      this.filterAvailableColumnsText = '';
    }
  }

  // search for availableColumns
  filterAvailableColumns() {
    let MasterAvailableColumnsCopy = this.MasteravailableColumns; // master list
    this.availableColumnList = [];
    // eslint-disable-next-line eqeqeq
    if (this.filterAvailableColumnsText != '') {
      // Removing selected columns from Master list & assigning to Available Columns
      this.queryDisplay.selectedColumns.forEach(selectedColumn => {
        // eslint-disable-next-line eqeqeq
        MasterAvailableColumnsCopy = MasterAvailableColumnsCopy.filter(x => x.id != selectedColumn.id);
      });
      this.availableColumnList = MasterAvailableColumnsCopy;
      // filtering on Available Columns
      const data = this.availableColumnList.filter(x => x.displayName.toLowerCase()
        .indexOf(this.filterAvailableColumnsText.toLowerCase()) >= 0);

      this.availableColumnList = data;
    } else {
      this.queryDisplay.selectedColumns.forEach(selectedColumn => {
        // eslint-disable-next-line eqeqeq
        MasterAvailableColumnsCopy = MasterAvailableColumnsCopy.filter(x => x.id != selectedColumn.id);
      });
      this.availableColumnList = MasterAvailableColumnsCopy;
    }
  }

  // Get macros list from DataBase
  getMacroList() {
    this.queryBuilderService.getMacros()
      .subscribe((macros: IMacros) => {
        this.macroList = macros;
      });
  }

  // To copy the existing query
  copyQuery() {
    this.isQueryChanged('COPY');
    if (!this.showQueryNameErr) {
      this.showQueryNameErr = false;
    }
    const copyName = 'Copy of';
    // eslint-disable-next-line eqeqeq
    if (this.nextAction != 'COPY' && !this.isModified) {
      this.selectedQuery = 0;
      this.queryDisplay = this.copydisplayQuery;
      this.queryDisplay.id = null;
      const i = 1;
      // let data = this.finalQueryObject.filter(query => query.name == this.queryDisplay.name || query.name == copyName + this.queryDisplay.name + i);
      // eslint-disable-next-line eqeqeq
      if (this.queryDisplay.name.indexOf(copyName + ' ') == -1) {
        this.queryDisplay.name = `${copyName} ${this.copydisplayQuery.name}(${i})`;
      } else {

        // scenario: Copy of TestQuery(
        const queryIndex = this.queryDisplay.name.split('(');
        if (queryIndex.length >= 2) {
          // scenario: Copy of TestQuery(1)
          // eslint-disable-next-line eqeqeq
          if (queryIndex[1] == '') {
            const queryNum = 0;
            this.queryDisplay.name = `${queryIndex[0]}(${+queryNum + 1})`;
          } else {
            const queryNum = queryIndex[1].split(')')[0];
            queryIndex[1] = '';
            this.queryDisplay.name = `${queryIndex[0]}(${+queryNum + 1})`;
            // this.queryDisplay.name = this.queryDisplay.name.replace('(', '');
            // this.queryDisplay.name = this.queryDisplay.name.replace(queryNum, '');
            // this.queryDisplay.name = this.queryDisplay.name.replace(')', '');
            // this.queryDisplay.name = this.queryDisplay.name + '(' + (+ (queryNum) + 1) + ')';
          }
        } else {
          // scenario: Copy of TestQuery
          if (this.queryDisplay.name.indexOf(copyName) >= -1) {
            this.queryDisplay.name = this.copydisplayQuery.name + '(1)';
          }
        }
      }
      this.filterAvailableColumnsText = '';
      this.availableColumnList = this.availableColumnsForQueryGeneration;
      this.queryDisplay.selectedColumns.forEach(selectedColumn => {
        // eslint-disable-next-line eqeqeq
        this.availableColumnList = this.availableColumnList.filter(x => x.id != selectedColumn.id);
      });
      if (this.queryDisplay.isTradeGroup) {
        this.checkTradeGroupQueryName(this.queryDisplay.name + '_TG'); // Need to implement this
      } else {
        this.checkQueryName(this.queryDisplay.name);
      }
    }
    this.queryDisplay.isChanged = false;
  }

  // setting query as trade group query
  setTradeGroup() {
    let result;
    if (this.queryDisplay.isTradeGroup) {
      this.queryDisplay.selectedColumns.forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (item.displayName == 'Account.AccountId' || item.displayName == 'Portfolio.PortfolioId') {
          result = false;
          this.showError = result;
        }
      });
      // eslint-disable-next-line eqeqeq
      if (result != false) {
        this.showError = true;
      }
    } else {
      this.showError = false;
    }
    this.setChange();
    if (this.queryDisplay.isTradeGroup) {
      this.checkTradeGroupQueryName(this.queryDisplay.name + '_TG');
    } else {
      this.checkQueryName(this.queryDisplay.name);
    }
  }

  // Validations for tradegroup query
  setTradeGroupForColumns() {
    let result;
    if (this.queryDisplay.isTradeGroup) {
      this.queryDisplay.selectedColumns.forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (item.displayName == 'Account.AccountId' || item.displayName == 'Portfolio.PortfolioId') {
          result = false;
          this.showError = result;
        }
      });
      // eslint-disable-next-line eqeqeq
      if (result != false) {
        this.showError = true;
      }
    } else {
      this.showError = false;
    }
    this.setChange();
  }

  closeQuery(queryid) {
    this.isCloseOpenedQuery = true;
    this.tempQueryDisplay = this.queryDisplay; // Copying changes(if any) to Temp object
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.isChanged == true) {
      this.isModified = true;
    } else {
      this.createNewQuery('Standard');
    }
  }

  checkQueryName(queryName) {
    // if (this.queryDisplay.isTradeGroup) {
    //     queryName = queryName + '_TG';
    // }
    const queries = this.finalQueryObject;
    for (let i = 0; i < queries.length; ++i) {
      // eslint-disable-next-line eqeqeq
      if (queries[i].name == queryName) {
        // eslint-disable-next-line eqeqeq
        if (this.selectedQuery != 0 && queries[i].id == this.selectedQuery) {
          this.showQueryNameErr = false;
        } else {
          this.showQueryNameErr = true;
        }
        break;
      } else {
        this.showQueryNameErr = false;
      }
    }
  }

  checkTradeGroupQueryName(queryName) {
    // if (this.showQueryNameErr) {
    //     queryName = queryName + '_TG';
    // }
    const queries = this.queryFilterData;
    for (let i = 0; i < queries.length; ++i) {
      // eslint-disable-next-line eqeqeq
      if (queries[i].name == queryName) {
        // eslint-disable-next-line eqeqeq
        if (this.selectedQuery != 0 && queries[i].id == this.selectedQuery) {
          this.showQueryNameErr = false;
        } else {
          this.showQueryNameErr = true;
        }
        break;
      } else {
        this.showQueryNameErr = false;
      }
    }
  }

  runQuery(id: number) {
    // this.runQueryTabsData[0].name = this.queryDisplay.name;
    if (!this.showQueryNameErr) {
      this.showQueryNameErr = false;
    }
    this.tabCountMaxm = false;
    // this.filterAvailableColumnsText = '';
    // only 5tabs of query results are shown
    // eslint-disable-next-line eqeqeq
    if (this.runQueryTabsData.length == 5 && this.queryBuilderDataService.runQueryIds != undefined && this.queryBuilderDataService.runQueryIds.indexOf(id) == -1) {
      this.tabCountMaxm = true;
      return; // 5 queries results are opened and if we re run query which is already open, it should be activated
    }
    // Check whether Query result already present
    // eslint-disable-next-line eqeqeq
    const isQueryLoaded = this.runQueryTabsData.filter(elem => elem.id == id);
    if (isQueryLoaded.length === 1) {
      this.dynamicResultActiveTabIndex = this.runQueryTabsData.indexOf(isQueryLoaded[0]) + 1; // To make tab active
      this.reBindTabData(isQueryLoaded[0], id); // Rebind tab data to get changes
      return;
    } else {
      this.executeQuery(id);
    }

  }

  deleteTab(id: number) {
    this.tabCountMaxm = false;
    this.runQueryTabsData = this.runQueryTabsData.filter(data => data.id !== id);
    this.queryBuilderDataService.runQueryIds.splice(this.queryBuilderDataService.runQueryIds.indexOf(id), 1); // remove id from shared service object
    this.gridOptionsAPI = this.gridOptionsAPI.filter(x => x.key !== id);
    if (this.runQueryTabsData.length > 0) {
      this.dynamicResultActiveTabIndex = 1;
      // bind first tab data to the grid
      // eslint-disable-next-line eqeqeq
      const data = this.runQueryTabsData.filter(x => x.id == this.runQueryTabsData[0].id);
      // eslint-disable-next-line eqeqeq
      if (data != undefined && data.length > 0) {
        this.activeTabData = data[0];
      }
      // End binding
      // To hide export menu option on Tab close if query is Fav and RAN from LPanel
      this.queryBuilderDataService.performActionOnLPanel('tabClose-' + id); // if two queries of FAV running then???
    } else {
      this.dynamicResultActiveTabIndex = 0;
      this.queryBuilderDataService.performActionOnLPanel('allTabs');
    }
  }

  handleTabClose(evt) {
    this.deleteTab(this.runQueryTabsData[evt.index-1].id);
  }

  onTabChange(evt) {
    this.tabClick(evt.index);
  }

  tabClick(tabIndex: number) {
    this.tabCountMaxm = false;
    this.dynamicResultActiveTabIndex = tabIndex;
    // After click on run query, if user made some changes in existing query and click on run query tab
    // Will populate popup asking confirmation for save
    // eslint-disable-next-line eqeqeq
    if (this.queryDisplay.isChanged == true) {
      this.nextAction = 'resultTab';
      this.tempQueryDisplay = this.queryDisplay;
      this.isModified = true; // This will display save changes confirmation popup
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (tabIndex !== 0) {
      this.dynamicResultActiveTabIndex = tabIndex; // 'executeQuery';
      const tabId = this.runQueryTabsData[tabIndex - 1].id;
      // eslint-disable-next-line eqeqeq
      const data = this.runQueryTabsData.filter(x => x.id == tabId);
      // eslint-disable-next-line eqeqeq
      if (data != undefined && data.length > 0) {
        this.activeTabData = data[0];
      }

    } else {
      this.dynamicResultActiveTabIndex = 0;
    }
  }

  /** Grid has initialised  */
  onGridReady(params: GridReadyEvent) {
    // eslint-disable-next-line eqeqeq
    const isApiExist = this.gridOptionsAPI.filter(x => x.key == this.selectedQuery);
    // eslint-disable-next-line eqeqeq
    if (isApiExist.length == 0) {
      this.gridOptionsAPI.push({key: this.selectedQuery, value: params.api});
    } else {
      this.gridOptionsAPI.keys[this.selectedQuery] = params.api;
    }

    // AutoSize all columns
    params.api.autoSizeColumns([]);

    Util.windowSize();
  }

  @HostListener('window:resize')
  onResize() {
    Util.windowSize();
  }

  createColumnDefs(headers) {
    this.columnDefs = [];
    headers.forEach(element => {
      const displayName = this.displayFriendlyName(element.displayName);
      switch (element.dataTypeId) {
        case Consts.QueryBuilderColumnsDataTypes.DateTime:
        case Consts.QueryBuilderColumnsDataTypes.Date:
          this.columnDefs.push(
            <ColDef>{
              headerName: displayName,
              field: `${element.moduleName}_${element.name}`,
              cellRenderer: this.formatUTCDateCellRenderer,
              filter: 'agTextColumnFilter',
              enableRowGroup: true,
              enablePivot: false,
              filterParams: {dataType: 'date'}
            }
          );
          break;
        case Consts.QueryBuilderColumnsDataTypes.TinyInt:
        case Consts.QueryBuilderColumnsDataTypes.Bit:
          this.columnDefs.push(
            <ColDef>{
              headerName: displayName,
              field: `${element.moduleName}_${element.name}`,
              cellRenderer: this.boolRender,
              enableRowGroup: true,
              enablePivot: false,
              filterParams: {dataType: 'boolean'},
              filter: true
            }
          );
          break;
        case Consts.QueryBuilderColumnsDataTypes.Int:
        case Consts.QueryBuilderColumnsDataTypes.BigInt:
        case Consts.QueryBuilderColumnsDataTypes.Decimal:
          this.columnDefs.push(
            <ColDef>{
              headerName: displayName,
              field: `${element.moduleName}_${element.name}`,
              filter: 'agNumberColumnFilter',
              enableRowGroup: true,
              enablePivot: false
            }
          );
          break;
        case Consts.QueryBuilderColumnsDataTypes.Varchar:
        case Consts.QueryBuilderColumnsDataTypes.LongText:
        case Consts.QueryBuilderColumnsDataTypes.Text:
        default:
          const colDef = <ColDef>{
            headerName: displayName,
            field: `${element.moduleName}_${element.name}`,
            filter: 'agTextColumnFilter',
            enableRowGroup: true,
            enablePivot: false
          };
          const maskFields = ['accountname', 'accountnumber', 'portfolio', 'name', 'acct-port-cust-mod name'];
          if(maskFields.includes(element.name.toLowerCase())) {
            colDef.cellClass = 'fs-mask';
          }
          this.columnDefs.push(colDef);
      }
    });
  }

  private displayFriendlyName(fieldName: string): string {
    if (fieldName && fieldName.indexOf('.') !== -1) {
      const nameParts = fieldName.split('.');
      if (nameParts && nameParts.length > 0) {
        let parts = nameParts[1].split(/(?=[A-Z])/);
        if (parts && parts.length > 0) {
          parts = parts.map(x => {
            if (x?.trim().toLocaleLowerCase() === 'id') {
              return x.toUpperCase();
            } else {
              return x?.charAt(0).toUpperCase() + x?.slice(1);
            }
          });
          if (/^[A-Z]*$/.test(parts[0]) && /^[A-Z]*$/.test(parts[1])) {
            if (parts.length === 2) {
              return parts[0] + parts[1];
            } else if (parts.length > 2) {
              return parts[0] + parts[1] + ' ' + parts.slice(2).join(' ');
            }
          }
          return parts.join(' ');
        }
      }
    }
    return fieldName;
  }

  /** Render boolean values */
  boolRender(params) {
    // eslint-disable-next-line eqeqeq
    if (params.column.colId == 'Portfolio_outOfBalance') {
      // eslint-disable-next-line eqeqeq
      if (params.node.data['Portfolio_outOfBalance'] == 1) {
        return 'Yes';
      } else {
        return 'No';
      }
    } else {
      // eslint-disable-next-line eqeqeq
      if (params.node.data[params.column.colId] == 0) {
        return 'No';
      // eslint-disable-next-line eqeqeq
      } else if (params.node.data[params.column.colId] == 1) {
        return 'Yes';
      } else {
        return params.node.data[params.column.colId];
      }
    }
  }

  /** Render default value */
  stringRender(params) {
    // return params.node.data[params.column.colId];
    // params.node.data[params.column.colId] = params.node.data[params.column.colId.split("_")[0] + '.' + params.column.colId.split("_")[1]];
    return params.node.data[params.column.colId];
  }

  /**Export run query data into excel/csv */
  exportQueryResult(id: number) {
    const self = this;
    // id -last selected query in query builder/Favourite query section
    // activeTabQueryId - Currently active tab which user want to export data of.
    const csvParams = <CsvExportParams>{
      skipFooters: true,
      skipRowGroups: true,
    };

    const dt = new Date();
    csvParams.fileName = `${this.activeTabData.name?.replace(' ', '_')}_${dt.getMonth()+1}${dt.getDate()}${dt.getFullYear()}_${dt.getHours()}${dt.getMinutes()}.csv`;

    csvParams.processCellCallback = function (params) {
      const col = params.column.getColId();
      // let dataType = self.MasteravailableColumns.filter(x => x.displayName == params.column.getColId())[0].dataType;
      // eslint-disable-next-line eqeqeq
      const dataType = self.activeTabData.selectedColumns.find(x => `${x.moduleName}_${x.name}` == col).dataTypeId;
      switch(dataType) {
        case Consts.QueryBuilderColumnsDataTypes.DateTime:
          return self.formatDateTimeCellRenderer(params);
        case Consts.QueryBuilderColumnsDataTypes.Date:
          return self.formatDateCellRenderer(params);
        case Consts.QueryBuilderColumnsDataTypes.TinyInt:
          return self.boolRender(params);
        default:
          return self.stringRender(params);
      }
    };

    // Export selected Tab data else active/selected query data
    let activeTabQueryId = +self.runQueryTabsData[self.dynamicResultActiveTabIndex - 1].id;
    // eslint-disable-next-line eqeqeq
    if (activeTabQueryId != undefined && activeTabQueryId != null && isNaN(activeTabQueryId)) {
      activeTabQueryId = id;
    }
    // eslint-disable-next-line eqeqeq
    const data = this.runQueryTabsData.filter(elem => elem.id == +activeTabQueryId)[0];
    if (data?.GridOptions) {
      const api = this.gridOptionsAPI.filter(x => x.key === activeTabQueryId)[0].value;
      api.exportDataAsCsv(csvParams);
    }
  }


  /**Method to call executeQuery API */
  executeQuery(id: number) {
    this.queryBuilderService.executeQuery(id)
      .subscribe((data: IRunQueryTabsData) => {
        // Mantaining runQuery id's to show options accordingly in Fav panel
        this.queryBuilderDataService.runQueryIds.push(id);
        this.sortArray = [];
        this.bindTabsData(data);
      });
  }

  /**Method to bind the Tabs */
  bindTabsData(data) {
    data.selectedColumns = data.selectedColumns.sort((s, t) => s.columnDisplayOrder - t.columnDisplayOrder);
    this.createColumnDefs(data.selectedColumns);
    // this.parsingDateIntoRequiredFormat(data.result);
    // Add the selected query data to the 'available tabs' data
    const newQueryTab: IRunQueryTabsData = {
      id: data.id,
      name: data.name,
      result: this.sortArray,
      selectedColumns: data.selectedColumns,
      GridOptions: BaseGridConfiguration.generateExtendedFilterGridOptions(this.defaultGridOptions, this),
      ColumnDef: this.columnDefs
    };
    this.runQueryTabsData.push(newQueryTab);
    setTimeout(function () {
      this.dynamicResultActiveTabIndex = this.runQueryTabsData.indexOf(newQueryTab) + 1; // Currently selected query will be activated
    }.bind(this), 0);
    // send command on query run to show Export option on left panel
    this.queryBuilderDataService.performActionOnLPanel('queryRun');
    this.BindActiveTabGridData(data); // Binding active tab data - changed looping to single grid
  }

  BindActiveTabGridData(data: IRunQueryTabsData) {
    this.activeTabData = <IRunQueryTabsData>{};
    let itemObj;
    // this.sortArray = [];
    data.result.forEach(ele => {
      itemObj = {};
      data.selectedColumns.forEach(col => {
        itemObj[`${col.moduleName}_${col.name}`] = ele[col.displayName];
      });
      this.sortArray.push(itemObj);
    });
    const activeData = this.runQueryTabsData.filter(x => x.id === data.id);
    if (activeData !== undefined && activeData.length) {
      this.activeTabData = activeData[0];
    }
  }

  // Method to re bind already executed query tab data
  reBindTabData(queryResult, id) {
    // Reloading data to get changes in query
    this.queryBuilderService.executeQuery(id)
      .subscribe((data: IRunQueryTabsData) => {
        this.createColumnDefs(data.selectedColumns);
        // eslint-disable-next-line eqeqeq
        this.runQueryTabsData.find(rec => rec.id == id).name = data.name;
        this.sortArray = [];
        this.BindActiveTabGridData(data);
      });
  }

  /** To deactivate component  */
  canDeactivate() {
    // when user clicks on any menu option other than auery builder will be checking if any changes are made in query
    this.nextAction = 'redirectRoute';
    if (!this.queryDisplay.isChanged) { // If there are no changes in query
      this.queryBuilderDataService.selectedQueryId = 0;
      this.queryBuilderDataService.performActionOnLPanel('refresh');
      return of(true);
    } else {
      // Shows the save popup
      this.tempQueryDisplay = this.queryDisplay;
      this.isModified = true;
    }
    // This will maintain reference to next action (menuOption on which mouse click performed)
    // return new Observable<boolean>((sender: Observer<boolean>) => {
    //     this.savedView.exitWarning.observer = sender;
    // });
  }

}
