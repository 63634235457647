import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core/http.client';
import { IAddQuery, IQuery } from '../models/querybuilder';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {

  private masterColumnsEndPoint = 'v1/querybuilder/queries/columns';
  private queryListEndPoint = 'v1/querybuilder/queries';
  private macrosEndPoint = 'v1/querybuilder/queries/macros';

  constructor(private _httpClient: OEHttpClient) { }

  getMasterColumns() {
    return this._httpClient.getData(this.masterColumnsEndPoint);
  }

  getQueryList(): Observable<IQuery[]> {
    return this._httpClient.getData(this.queryListEndPoint);
  }
  getFavQueryList() {
    return this._httpClient.getData(`${this.queryListEndPoint}?favourite=true`);
  }
  getQueryDetails(id) {
    return this._httpClient.getData(`${this.queryListEndPoint}/${id}`);
  }

  addQuery(queryobj: IAddQuery) {
    return this._httpClient.postData(this.queryListEndPoint, queryobj);
  }

  deleteQuery(id) {
    return this._httpClient.deleteData(`${this.queryListEndPoint}/${id}`);
  }

  updateQuery(queryobj: IAddQuery, id) {
    return this._httpClient.updateData(`${this.queryListEndPoint}/${id}`, queryobj);
  }
  executeQuery(id) {
    return this._httpClient.getData(`${this.queryListEndPoint}/${id}/execute`);
  }

  getMacros() {
    return this._httpClient.getData(this.macrosEndPoint);
  }

  getMasterColumnValues(id) {
    return this._httpClient.getData(`${this.masterColumnsEndPoint}/${id}/values`);
  }

  /** To search trade groups  */
  searchTradeGroups(searchKey: string, typeId: number = 0) {
    if (typeId !== 0) {
      return this._httpClient.getData(`${this.queryListEndPoint}/tradegroups?search=${searchKey}&typeId=${typeId}`);
    } else {
      return this._httpClient.getData(`${this.queryListEndPoint}/tradegroups?search=${searchKey}`);
    }
  }

  /** To get trade groups  */
  getTradeGroups(typeId) {
    return this._httpClient.getData(`${this.queryListEndPoint}/tradegroups?typeId=${typeId}`);
  }

}
