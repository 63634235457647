<ng-container *ngIf="standardQuery?.where">
  <div class="conditions flex gap-3 align-items-center p-2">
    <span>Condition:</span>
    <p-dropdown [options]="andOrOptions" optionValue="label" optionLabel="label"
                [(ngModel)]="standardQuery.where.condition" (onChange)="setChange()"></p-dropdown>
    <button type="button" pButton title="Add Condition" (click)="addQueryRule(standardQuery.where.rules)"
            icon="fas fa-plus-circle" label="Add Rule"></button>
  </div>
  <div class="grid align-items-center mt-0" *ngFor="let standardlist of standardQuery.where.rules">
    <div class="col-3">
      <p-dropdown [options]="standardQuery.selectedColumns" optionLabel="displayName" optionValue="id"
                  [(ngModel)]="standardlist.selectedColumnId"
                  (onChange)="setColumnDataType(standardlist, $event)"></p-dropdown>
    </div>
    <div class="col-2">
      <div *ngIf="standardlist.columnDateType === '' || standardlist.columnDateType === undefined">
        <p-dropdown [options]="stringOperatorsList" optionLabel="value" optionValue="value"
                    [(ngModel)]="standardlist.operator" (onChange)="setChange()"></p-dropdown>
      </div>
      <div *ngIf="standardlist.columnDateType !== undefined && !standardlist.isPredefined &&
      (standardlist.columnDateType === 'VARCHAR' || standardlist.columnDateType === 'LONGTEXT' || standardlist.columnDateType === 'TEXT') && standardlist.selectedColumnId !== 56">
        <p-dropdown [options]="stringOperatorsList" optionLabel="value" optionValue="value"
                    [(ngModel)]="standardlist.operator" (onChange)="setChange()"></p-dropdown>
      </div>
      <div *ngIf="standardlist.columnDateType!= undefined && !standardlist.isPredefined
      && (standardlist.columnDateType === 'INT' || standardlist.columnDateType === 'BIGINT' || standardlist.columnDateType === 'DECIMAL'
      || standardlist.columnDateType === 'DATETIME' || standardlist.columnDateType === 'DATE' || standardlist.columnDateType === 'BIT')">
        <p-dropdown [options]="integerOperatorsList" optionLabel="value" optionValue="value"
                    [(ngModel)]="standardlist.operator" (onChange)="setChange()"></p-dropdown>
      </div>
      <div
        *ngIf="standardlist.columnDateType === 'TINYINT' || standardlist.isPredefined || standardlist.selectedColumnId === 56">
        <p-dropdown [options]="booleanOperatorsList" optionLabel="value" optionValue="value"
                    [(ngModel)]="standardlist.operator" (onChange)="setChange()"></p-dropdown>
      </div>
    </div>
    <div class="col-2"
         *ngIf="standardlist.columnDateType !== 'DATETIME' && standardlist.columnDateType !== 'DATE' && !standardlist.isPredefined &&
         standardlist.columnDateType !== 'TINYINT' && standardlist.selectedColumn !== 'Account.SleeveType'">
      <input pInputText type="text" [(ngModel)]="standardlist.value" (ngModelChange)="setChange()"
             (keypress)="validateValue(standardlist.columnDateType, $event)">
    </div>
    <div class="col-2" *ngIf="standardlist.columnDateType === 'TINYINT'">
      <p-dropdown [options]="yesNoValues" optionLabel="label" optionValue="value"
                  [(ngModel)]="standardlist.value" (onChange)="setChange()"></p-dropdown>
    </div>
    <div class="col-2" *ngIf="standardlist.selectedColumn === 'Account.SleeveType'">
      <p-dropdown [options]="sleeveTypeValues" optionLabel="label" optionValue="label"
                  [(ngModel)]="standardlist.value" (onChange)="setChange()"></p-dropdown>
    </div>
    <div class="col-2" *ngIf="standardlist.isPredefined">
      <p-dropdown [options]="standardlist.predefinedList.values" optionLabel="name" optionValue="id"
                  [(ngModel)]="standardlist.predefinedValue"
                  (onChange)="setPredefinedvalue(standardlist,$event)"></p-dropdown>
    </div>
    <div class="col-2"
         *ngIf="(standardlist.columnDateType === 'DATETIME' || standardlist.columnDateType === 'DATE' )&& standardlist.columnDateType !== null">
      <p-dropdown [options]="macroList" optionLabel="name" optionValue="id" [(ngModel)]="standardlist.macro"
                  (onChange)="clearDate(standardlist)"></p-dropdown>
    </div>
    <div class="col-2"
         *ngIf="standardlist.macro === 0 && (standardlist.columnDateType === 'DATETIME' || standardlist.columnDateType === 'DATE')">
      <p-calendar [(ngModel)]="standardlist.date" [showIcon]="true"
                  (ngModelChange)="setChange()" [yearNavigator]="true" [yearRange]="getYearRange()"
                  [readonlyInput]="true"></p-calendar>
    </div>
    <div class="col-1">
      <button type="button" pButton class="p-button-danger"
              (click)="deleteRow(standardlist)" icon="fas fa-minus-circle"></button>
    </div>
  </div>
</ng-container>
