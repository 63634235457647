<div *ngIf="!!availableColumns?.length" class="flex flex-column gap-1">
  <p-dropdown ariaLabel="Available columns" id="columns" [options]="availableColumns" [(ngModel)]="filter.key"
              optionValue="key" optionLabel="colDef.headerName" [style]="{'width': '100%'}"
              [filter]="true" filterBy="colDef.headerName"
              (onChange)="filterColumnChange()" appendTo="body">
    <ng-template let-item pTemplate="selectedItem">
      {{ item.colDef.headerName }}
    </ng-template>
    <ng-template let-pc pTemplate="item">
      {{ pc.colDef.headerName }}
    </ng-template>
  </p-dropdown>
  <p-dropdown ariaLabel="Filter comparator" id="comparison" [options]="comparators | keyvalue: asIsOrder" [(ngModel)]="filter.comparator"
              optionValue="key" optionLabel="value.description"
              [style]="{'width': '100%'}" appendTo="body">
    <ng-template let-item pTemplate="selectedItem">
      {{ item.value.description }}
    </ng-template>
    <ng-template let-pc pTemplate="item">
      {{ pc.value.description }}
    </ng-template>
  </p-dropdown>

  <ng-container [ngSwitch]="columnDataType">
    <ng-template [ngSwitchCase]="dataType.Number">
      <input aria-label="Filter value" id="numberValue" name="numberValue" *ngSwitchCase="dataType.Number" pInputText type="number" [(ngModel)]="value"
             placeholder="filter value (numeric)" (keyup.enter)="applyFilter()" step="any" [disabled]="allowImportOnly">
      <div class="form-group" *ngIf="filter.comparator === 'BETWEEN'">
        <input aria-label="Second filter value" id="numberBetween" name="numberBetween" type="number" [(ngModel)]="filter.condition" placeholder="Second filter value" (keyup.enter)="applyFilter()" step="any" pInputText>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="dataType.String">
      <input aria-label="Filter value" id="stringValue" name="stringValue" *ngSwitchCase="dataType.String" pInputText type="text" [(ngModel)]="value"
             placeholder="filter value (string)" (keyup.enter)="applyFilter()" [disabled]="allowImportOnly">
    </ng-template>
    <ng-template [ngSwitchCase]="dataType.Boolean">
      <p-dropdown ariaLabel="Filter value" id="boolValue" *ngSwitchCase="dataType.Boolean" [options]="booleanOptions" [(ngModel)]="value"
                  optionValue="value" optionLabel="description" [style]="{'width': '100%'}" appendTo="body">
      </p-dropdown>
    </ng-template>
    <ng-template [ngSwitchCase]="dataType.Enum">
      <p-dropdown ariaLabel="Filter value" id="enumValue" *ngSwitchCase="dataType.Enum" [options]="enumOptions" [(ngModel)]="value"
                  optionValue="value" optionLabel="description" [style]="{'width': '100%'}" appendTo="body">
      </p-dropdown>
    </ng-template>
    <ng-template [ngSwitchCase]="dataType.Date">
      <ng-container *ngIf="filter.comparator !== 'TODAY'">
        <input aria-label="Filter value" id="dateFilter" name="dateFilter"
               *ngIf="filter.comparator === 'OLDER_THAN' || filter.comparator === 'WITHIN' || filter.comparator === 'WITHIN_AFTER'; else date_value "
               pInputText type="number" (keyup.enter)="applyFilter()"
               [(ngModel)]="value" placeholder="filter value">
        <ng-template #date_value>
          <p-calendar id="dateFilterValue" [showIcon]="true" [monthNavigator]="true" placeholder="Filter value" appendTo="body"
                      [yearNavigator]="true" [(ngModel)]="value" dataType="string" dateFormat="yy-mm-dd"></p-calendar>
        </ng-template>
      </ng-container>
    </ng-template>
  </ng-container>

  <div class="flex" style="justify-content: space-between; gap: 4px;">
    <button id="btnReset" type="button" pButton class="p-button-secondary p-button-sm" (click)="discardFilter()"
            label="Reset"></button>
    <div class="flex">
      <button type="button" pButton class="p-button-secondary p-button-sm" (click)="showImport()"
              id="btnUploadList" aria-label="Import values" *ngIf="bulkImportAllowed()"
              label="{{ isEditingFilter && allowImportOnly ? 'Edit Values' : 'Import' }}">
      </button>
      <button type="button" pButton class="p-button-secondary p-button-sm" (click)="applyFilter()"
              id="btnSaveFilter" icon="far fa-circle-plus" [disabled]="!isValid()" label="{{ isEditingFilter ? 'Save' : 'Add' }}">
      </button>
    </div>
  </div>
</div>

<p-dialog header="Import Filter Values" [(visible)]="showImportPopup" [modal]="true" [resizable]="true"
          class="ui-dialog-md">
  <div class="ui-dialog-content col-sm-12">
    <div class="choose-file-small-box file-upload-input-style p-v-20" (dragover)="dragFile($event)"
         (drop)="dropFile($event)">
      <span pButton class="btn-file" id="file-import-button"> Select File to Import
          <input type="file" name="model" id="model-file" aria-labelledby="file-import-button"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
                 (change)="fileSelected($event)" [(ngModel)]="uploadFile">
      </span>
      <br/> or drag and drop here
    </div>

    <section>
      <div class="inline-flex align-items-center">
        <h5 id="value-list-label">Paste a list of values</h5><i class="fas fa-circle-question m-l-5" aria-hidden="true" pTooltip="Use {blank} to include an empty value"></i>
      </div>
      <textarea id="valuesList" aria-labelledby="value-list-label" (ngModelChange)="onPastedValueListChanged($event)" pInputTextarea placeholder="One value per line" [(ngModel)]="valuesList" rows="5"></textarea>
    </section>
  </div>
  <p-footer>
    <span *ngIf="importError" class="text-danger m-r-10">{{importError}}</span>
    <button *ngIf="values?.length && valuesList?.length" id="btnApplyPastedFilters" type="button" class="p-button-primary" pButton icon="fas fa-check" (click)="applyPastedFilters()"
            label="Apply {{ values?.length }} Value{{values?.length === 1 ? '' : 's'}}"></button>
    <button id="btnCloseFilterImport" type="button" class="p-button-secondary " pButton icon="fas fa-times" (click)="showImportPopup=false"
            label="Close"></button>
  </p-footer>
</p-dialog>
