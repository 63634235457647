import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import {
  ColDef,
  CsvExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  ValueGetterParams
} from '@ag-grid-community/core';
import { Utils as Util } from '../../../core/functions';
import { PortfolioEditorService } from './portfolio-editor.service';
import { EntityEditorService } from '../../../shared/entity-editor';
import * as Consts from '../../../libs/app.constants';
import { PortfolioEntity } from './types';
import { BaseGridConfiguration } from '../../../shared/gridextensions/basegrid';
import { Subject, takeUntil, tap, Observable , BehaviorSubject, combineLatest} from 'rxjs';
import { IHolding } from "../../../models/holding";
import {
  ExtendedGridFilterToolPanel,
  IExtendedFilterToolPanelFilter
} from "../../../shared/gridextensions/extended-grid-filter.component";
import { IExitWarning, ISavedView } from "../../../viewModels/savedview";
import { filter, switchMap } from "rxjs/operators";
import { HoldingService } from "../../../services/holding.service";
import { ITabNav } from "../../../viewModels/tabnav";
import { Router } from "@angular/router";
import { SplitIoService } from "../../../core/feature-flag";

@Component({
  selector: 'eclipse-portfolio-editor-holdings',
  templateUrl: './portfolio-holdings.component.html'
})
export class PortfolioHoldingsComponent extends BaseComponent {
  gridContext = {
    isGridModified: false,
    isNone: false,
    self: this,
    isFilterChanged: false,
  };
  columnDefs: ColDef[];
  portfolio: PortfolioEntity;
  public readonly _portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public readonly _entityEditorService: EntityEditorService = inject(EntityEditorService);
  public readonly _holdingService: HoldingService = inject(HoldingService);
  private readonly _splitIoService: SplitIoService = inject(SplitIoService);
  tabsModel: ITabNav;
  public gridOptions: GridOptions;
  private gridApi: GridApi<IHolding>;
  private destroyed$: Subject<void> = new Subject<void>();
  savedView: ISavedView = <ISavedView>{};
  setFilter: boolean = false;
  public holdingsList$: Observable<IHolding[]>;
  filterType: number;
  private selectedFilterTypeSubject = new BehaviorSubject<number>(0);
  selectedFilterType$ = this.selectedFilterTypeSubject.asObservable();

  constructor(private _router: Router) {
    super(Consts.PRIV_HOLDINGS);
    this.tabsModel = Util.convertToTabNav(this.permission);

    this.filterType = (this.filterType === undefined) ? 0 : this.filterType;
    const filterOptions: IExtendedFilterToolPanelFilter = {
      filterType$: this._holdingService.getHoldingFilters(),
      filterTypeId: this.filterType,
    };

    this.gridOptions = BaseGridConfiguration.generateGridOptions({
      gridOptions: this.defaultFilterableGridOptions,
      context: this,
      viewTypeId: Consts.ViewTypeEnum.HoldingListingView,
      savedView: this.savedView,
      filter: filterOptions,
      refreshData: () => this.setFilter = true
    });

    this.gridOptions.suppressColumnVirtualisation = true;
    (<SideBarDef>this.gridOptions.sideBar).defaultToolPanel = ''; // the Filters panel shouldn't be expanded by default
    this.createColumnDefs();

    this.savedView = <ISavedView>{
      parentColumnDefs: this.columnDefs,
      parentGridOptions: this.gridOptions,
      exitWarning: <IExitWarning>{}
    };

    this.holdingsList$ =  combineLatest([this._portfolioEditorService.portfolio$, this.selectedFilterType$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([portfolio, filterType]) => !!portfolio.id),
        switchMap(([portfolio, filterType]) => { return this._holdingService.getHoldings('portfolio', portfolio.id, filterType ) }),
        tap(() => this.setFilter = true)
      );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Calls on model update */
  onModelUpdated() {
    if (this.setFilter) {
      this.setFilter = false;
      const savedViewComponent = ExtendedGridFilterToolPanel.getToolPanel(this.gridApi)?.savedViewComponent;
      if (savedViewComponent?.model.id > 0) {
        this.gridApi.setFilterModel(savedViewComponent.filterModel);
        if (!!savedViewComponent?.colState) {
          this.gridApi.applyColumnState(savedViewComponent.colState);
        }
        this.gridContext.isFilterChanged = true;
      } else {
        this.gridContext.isFilterChanged = false;
      }
      setTimeout(() => {
        this.gridContext.isGridModified = false;
      }, 0);
    }
  }

  /** Fires on filter change */
  onFilterChange(filter) {
    this.selectedFilterTypeSubject.next(+filter);
  }

  /** Create column headers for agGrid */
  createColumnDefs() {
    this.columnDefs = [
      <ColDef>{
        colId: 'accountNumber',
        headerName: 'Account Number',
        field: 'accountNumber',
        width: 144,
        cellClass: 'text-center fs-mask',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'securitySymbol',
        headerName: 'Symbol',
        field: 'securitySymbol',
        width: 130,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'price',
        headerName: 'Price',
        field: 'price',
        width: 133,
        cellRenderer: this.formatCurrencyCellRenderer,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        colId: 'value',
        headerName: 'Value',
        field: 'value',
        width: 155,
        cellRenderer: this.formatCurrencyCellRenderer,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        colId: 'targetInPer',
        headerName: 'Target %',
        field: 'targetInPer',
        width: 135,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max']
      },
      <ColDef>{
        colId: 'currentInPer',
        headerName: 'Current % ',
        field: 'currentInPer',
        width: 142,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max']
      },
      <ColDef>{
        colId: 'shares',
        headerName: 'Current Shares',
        field: 'shares',
        width: 172,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max']
      },
      <ColDef>{
        colId: 'pendingValue',
        headerName: 'Pending Value',
        field: 'pendingValue',
        width: 168,
        cellRenderer: this.formatCurrencyCellRenderer,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        colId: 'pendingInPer',
        headerName: 'Pending %',
        field: 'pendingInPer',
        width: 145,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max']
      },
      <ColDef>{
        colId: 'pendingShares',
        headerName: 'Pending Shares',
        field: 'pendingShares',
        width: 176,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableValue: true,
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max']
      },
      <ColDef>{
        colId: 'excluded',
        headerName: 'Excluded',
        field: 'excluded',
        width: 138,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'isCash',
        headerName: 'Is Cash',
        field: 'isCash',
        width: 127,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'inModel',
        headerName: 'In Model',
        field: 'inModel',
        width: 134,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'assetCategoryName',
        headerName: 'Category',
        field: 'assetCategoryName',
        width: 179,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'assetClassName',
        headerName: 'Class',
        field: 'assetClassName',
        width: 181,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'assetSubClassName',
        headerName: 'Sub Class',
        field: 'assetSubClassName',
        width: 166,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'id',
        headerName: 'Id',
        field: 'id',
        width: 110,
        cellClass: 'text-center',
        hide: true,
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        colId: 'securityName',
        headerName: 'Security',
        field: 'securityName',
        width: 154,
        cellClass: 'text-center',
        hide: true,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'lastTLHDate',
        headerName: 'Last TLH Date',
        field: 'lastTLHDate',
        width: 154,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        hide: true,
        valueGetter: this.lastTLHDateValueGetter
      }
    ];

    if(this._splitIoService.featureFlags['TEXP_portfolio_details_gain_loss_7520']) {
      this.columnDefs.push(
        <ColDef>{
          colId: 'isSMA',
          headerName: 'SMA',
          field: 'isSMA',
          width: 130,
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
          hide: true
        },
        <ColDef>{
          colId: 'costAmount',
          headerName: 'Cost Basis',
          field: 'costAmount',
          width: 168,
          cellRenderer: this.formatCurrencyCellRenderer,
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter',
          enableValue: true,
          aggFunc: 'sum',
          allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
          tooltipValueGetter: Util.currencyValueForTooltip,
          hide: true
        },
        <ColDef>{
          colId: 'unrealizedGainLoss',
          headerName: 'Unrealized G/L $',
          field: 'unrealizedGainLoss',
          width: 168,
          cellRenderer: this.formatCurrencyCellRenderer,
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter',
          enableValue: true,
          aggFunc: 'sum',
          allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
          tooltipValueGetter: Util.currencyValueForTooltip,
          hide: true
        },
        <ColDef>{
          colId: 'stUnrealizedGainLoss',
          headerName: 'Unrealized G/L ST $',
          field: 'stUnrealizedGainLoss',
          width: 168,
          cellRenderer: this.formatCurrencyCellRenderer,
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter',
          enableValue: true,
          aggFunc: 'sum',
          allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
          tooltipValueGetter: Util.currencyValueForTooltip,
          hide: true
        },
        <ColDef>{
          colId: 'ltUnrealizedGainLoss',
          headerName: 'Unrealized G/L LT $',
          field: 'ltUnrealizedGainLoss',
          width: 168,
          cellRenderer: this.formatCurrencyCellRenderer,
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter',
          enableValue: true,
          aggFunc: 'sum',
          allowedAggFuncs: ['sum', 'avg', 'min', 'max'],
          tooltipValueGetter: Util.currencyValueForTooltip,
          hide: true
        });
    }
  }

  /** Method to display context menu on agGrid*/
  getContextMenuItems(params) {
    if (!params.node || !params.node.data) {
      return null;
    }
    const self = params.context.self;
    const contextResult = [];
    // To display context menu only when we right click on selected row
    const selectedRows = params.api.getSelectedRows();
    const clickedCell = selectedRows.find(s => s.id === params.node.data.id);
    if (clickedCell === undefined) {
      return;
    }

    const permission = Util.getPermission(Consts.PRIV_HOLDINGS);
    if (permission.canRead) {
      contextResult.push({
        name: 'View Details',
        action: function () {
          self._router.navigate(['/eclipse/holding/view/portfolio', self._portfolioEditorService.portfolio.id, params.node.data.id, 'list']);
        }
      });
    }
    return contextResult;
  }

  /*****************************************  END: AG-GRID HELPER METHODS     **********************************/


  /*****************************************   START: AG-GRID EVENTS    *************************************/
  /** row selection event to egt selected holding id */
  onRowSelected(event) {
    this.tabsModel.id = event.node.data.id;
  }

  /** Fires on row double click */
  onRowDoubleClicked(event) {
    if (this.tabsModel.canRead) {
      this._router.navigate(['/eclipse/holding/view/portfolio', this._portfolioEditorService.portfolio.id, event.data.id, 'list']);
    }
  }

  /** Grid has initialised  */
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.savedView.parentGridApi = params.api;
    const contextParams = params.context;
    this.gridApi.addEventListener('columnResized', function () {
      contextParams.isGridModified = true;
    });

    this.gridApi.addEventListener('columnVisible', function () {
      contextParams.isGridModified = true;
    });
    this.gridApi.addEventListener('columnMoved', function () {
      contextParams.isGridModified = true;
    });
    this.gridApi.addEventListener('filterChanged', function () {
      // If already filter is applyed on existing saved view, On loading of that view we need to made isGridModified as false on first time grid load.
      if (contextParams.isFilterChanged) {
        contextParams.isFilterChanged = false;
        contextParams.isGridModified = false;
      } else {
        contextParams.isGridModified = true;
      }
    });

    this.gridApi.addEventListener('sortChanged', function () {
      contextParams.isGridModified = true;
    });

    this.gridApi.addEventListener('columnRowGroupChanged', function () {
      contextParams.isGridModified = true;
    });
  }

  /*****************************************  END: AG-GRID EVENTS    *************************************/

  /**Export Excel for Grid */
  exportToExcel() {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipGroups: true,
      fileName: 'Holdings.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  }

  lastTLHDateValueGetter(params: ValueGetterParams<IHolding>): void {
    const self = params.context.self;
    return params.data?.lastTLHDate ? self.dateFormatOnValueGetter(params.data.lastTLHDate) : '';
  }
}
