<div class="topbar">
  <div class="page-header-container">
    <section class="page-header-bar">
      <div class="flex flex-column">
        <div class="vertical-center" style="gap: 10px;">
          <h4 id="pageName" *ngIf="pageName?.length">{{ pageName }}</h4>
          <ng-content select="[slot=labelAction]"></ng-content>
        </div>
        <ng-content select="[slot=subtitle]"></ng-content>
      </div>
      <div>
        <ng-content select="[slot=analyticsCountUpdate]"></ng-content>
      </div>
      <div class="flex flex-column align-items-end gap-2">
        <div class="inline-flex gap-2">
          <ng-content select="[slot=mainActions]"></ng-content>
          <button type="button" pButton *ngIf="!!allowExcelExport" (click)="exportToExcel.emit()"
                  label="Export to Excel" icon="far fa-fw fa-arrow-circle-down"
                  class="p-button-secondary m-b-5"></button>
        </div>
        <ng-content select="[slot=action]"></ng-content>
      </div>
    </section>
  </div>
</div>
