<div class="page-header-row">
    <h3>Cash</h3>
    <ng-template #loadingCash>
        <div class="flex m-t-5" style="gap: 10px; align-items: center;">
            <p-skeleton width="10rem" height="1.55rem"></p-skeleton>
            <p-skeleton width="10rem" height="1.55rem"></p-skeleton>
        </div>
    </ng-template>
    <!-- <button pButton class="p-button-secondary" label="View Cash Details"
        icon="fas fa-arrow-up-right"
        (click)="showPortfolioCashDetails()" iconPos="right" *ngIf="isSleevePortfolio"></button> -->
</div>
<div class="section-row" *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingCash">
    <div class="section-column">
        <p-card header="Cash Values">
            <hr>
            <div class="data-box">
                <span class="data-label">Total Cash</span>
                <span>{{ (portfolioEditorService.cashDetails$ | async)?.currentCashAmount | currency:'USD' }}</span>
            </div>
            <div class="data-box">
                <span class="data-label">Available Cash</span>
                <span>{{ (portfolioEditorService.cashDetails$ | async)?.availableCash | currency:'USD' }}</span>
            </div>
            <div class="data-box">
                <span class="data-label">Current %</span>
                <span>{{ portfolio.cashPercent | percent:'1.0-2' }}</span>
            </div>
            <div class="data-box">
                <span class="data-label">Target %</span>
                <span>{{ portfolio.cashTargetPercent | percent:'1.0-2' }}</span>
            </div>
            <div class="data-box">
                <span class="data-label">Cash Need %</span>
                <span>{{ portfolio.cashTargetPercent - portfolio.cashPercent | percent:'1.0-2' }}</span>
            </div>
            <div class="section-content-column">
                <div class="data-box">
                    <h6>Cash Flow</h6>
                </div>
                <div class="data-box">
                    <span class="data-label">Contributions</span>
                    <span>{{ portfolio.contribution | currency:'USD' }}</span>
                </div>
                <div class="data-box">
                    <span class="data-label">Distributions</span>
                    <span>{{ portfolio.distribution | currency:'USD' }}</span>
                </div>
            </div>
        </p-card>
    </div>
    <div class="section-column">
        <p-card header="Set Aside Cash">
            <hr>
            <div class="section-content-column">
                <div class="data-box">
                    <span class="data-label">Set Aside Amount</span>
                    <span>{{ portfolio.actualSetAsideCash | currency:'USD' }}</span>
                </div>
                <div class="data-box">
                    <span class="data-label">Set Aside Target</span>
                    <span>{{ portfolio.targetSetAsideCash | currency:'USD' }}</span>
                </div>
            </div>
        </p-card>
    </div>
</div>

<div *ngIf="isSleevePortfolio" class="col-lg-12 gray95">
    <eclipse-set-aside-cash [portfolioId]="portfolioEditorService.portfolioId$ | async">
    </eclipse-set-aside-cash>
    <hr />
</div>

<p-dialog header="Cash Details" [(visible)]="showCashDetails" modal="true" [draggable]="true"
          [resizable]="true" [style]="{'width': '1000px'}" styleClass="ui-dialog-scrollable">
  <div class="p-content">
    <div class="col-xs-12 col-sm-6 m-b-10">
      <h4>Total Cash</h4>
      <ag-grid-angular class="ag-theme-alpine"
                       style="height:460px;width:100%"
                       enableColResize="true"
                       [rowDragManaged]="true" headerHeight="0" rowHeight="28"
                       [animateRows]="true"
                       [columnDefs]="cashDetailColDefs" [rowData]="cashDetailsModel.totalCash"
                       [suppressScrollOnNewData]="true"
                       [context]="cashGridContext" [defaultColDef]="defaultColDef">
      </ag-grid-angular>
      <h4>Set Aside Cash</h4>
      <ag-grid-angular class="ag-theme-alpine"
                       style="height:175px;width:100%"
                       enableColResize="true"
                       [rowDragManaged]="true" headerHeight="0" rowHeight="28"
                       [animateRows]="true"
                       [columnDefs]="cashDetailColDefs" [rowData]="cashDetailsModel.setAsideCash"
                       [suppressScrollOnNewData]="true"
                       [context]="cashGridContext" [suppressColumnVirtualisation]="true" [defaultColDef]="defaultColDef">
      </ag-grid-angular>
    </div>
    <div class="col-xs-12 col-sm-6 m-b-10">
      <h4>Model & Rebalance</h4>
      <ag-grid-angular class="ag-theme-alpine"
                       style="height:460px;width:100%"
                       enableColResize="true"
                       [rowDragManaged]="true" headerHeight="0" rowHeight="28"
                       [animateRows]="true"
                       [columnDefs]="cashDetailColDefs" [rowData]="cashDetailsModel.modelAndRebal"
                       [suppressScrollOnNewData]="true"
                       [context]="cashGridContext" [suppressColumnVirtualisation]="true" [defaultColDef]="defaultColDef">
      </ag-grid-angular>
      <h4>Billing Cash</h4>
      <ag-grid-angular class="ag-theme-alpine"
                       style="height:120px;width:100%"
                       enableColResize="true"
                       [rowDragManaged]="true" headerHeight="0" rowHeight="28"
                       [animateRows]="true"
                       [columnDefs]="cashDetailColDefs" [rowData]="cashDetailsModel.billingCash"
                       [suppressScrollOnNewData]="true"
                       [context]="cashGridContext" [suppressColumnVirtualisation]="true" [defaultColDef]="defaultColDef">
      </ag-grid-angular>
      <h4>Reserve Cash</h4>
      <ag-grid-angular class="ag-theme-alpine"
                       style="height:120px;width:100%"
                       enableColResize="true"
                       [rowDragManaged]="true" headerHeight="0" rowHeight="28"
                       [animateRows]="true"
                       [columnDefs]="cashDetailColDefs" [rowData]="cashDetailsModel.reserveCash"
                       [suppressScrollOnNewData]="true"
                       [context]="cashGridContext" [suppressColumnVirtualisation]="true" [defaultColDef]="defaultColDef">
      </ag-grid-angular>
    </div>
  </div>
  <p-footer>
    <button pButton (click)="showCashDetails = false">Ok</button>
  </p-footer>
</p-dialog>