export const enum AstroBatchStatus {
  Initiated = 'Initiated',
  Failed = 'Failed',
  Completed = 'completed',
  // Added for new astro service
  Complete = 'Complete',
  NeverStarted = 'NeverStarted',
  NeverReceived = 'NeverReceived',
  Unknown = 'Unknown'
}

export enum OptimizationType {
  Standard = 0,
  HarvestLoss = 1,
  WithdrawCash = 4,
  Single = 7
}

export const enum DecimalPlaces {
  Percent = 7,
  Amount = 6
}

export const OverrideParameterType = {
  Percent: 'percent',
  Amount: 'amount',
  Boolean: 'boolean',
  HarvestAmount: 'harvestAmount',
  SellIfLossGT: 'sellIfLossGT'
}

export const enum OverrideParameterPlaceholder {
  Decimal = '0.00',
  Count = '0',
  Dollar = '$',
  Percent = '%'
}

export const enum OverrideParameterInputType {
  Textbox = 'textbox',
  Checkbox = 'checkbox',
  RadioButton = 'radio'
}

export const AstroInvestorPreferences = {
  AstroTemplate: 'astroTemplate',
  AstroTargetStrategy: 'targetStrategy',
  AstroPreferences: 'Astro Preferences',
  ExposureModel: 'exposureModel',
  AstroWithHoldCashForTaxes: 'astroWithholdCashForTaxes',
  ExposureModelPreferenceName: 'riskModel',
  MaximumAccountCash: 'maximumAccountCash',
  MinimumAccountCash: 'minimumAccountCash',
  SyncEclipseCashToAstro: 'syncEclipseCashToAstro',
  AccountMaxGainAmount: 'accountMaxGainAmount'
};

export const OverrideParameterValue = {
  MaximumIntegerValue: 2147483647,
  MaximumOptimizationAmount: 999999999999999,
  MaximumTicketCharge: 9999,
  MaximumTurnoverPercentage: 1000,
  MinimumTradeSize: 100000
};

export const OverrideParameter = {
  ShortTermGainTaxRate: 'Short Term Gain Tax Rate (%)',
  LongTermGainTaxRate: 'Long Term Gain Tax Rate (%)',
  SecurityMinimumWeight: 'Security Minimum Weight (%)',
  SecurityMaximumWeight: 'Security Maximum Weight (%)',
  MaximumTrackingError: 'Maximum Tracking Error (%)',
  MinimumTrackingError: 'Minimum Tracking Error (%)',
  MaximumTradeSize: 'Maximum Trade Size (%)'
};

export const OptimizationValidationCode = {
  MaxCapitalGain: 'MaxCapitalGain',
  TicketCharge: 'TicketCharge',
  NetCapitalGainsYTD: 'NetCapitalGainsYTD',
  MaximumNoOfTrades: 'MaximumNoOfTrades',
  MaximumNoOfAssets: 'MaximumNoOfAssets',
  InvalidPercentage: 'InvalidPercentage',
  InvalidTurnoverPercentage: 'InvalidTurnoverPercentage',
  MinimumTradeSize: 'MinimumTradeSize'
};

export const OptimizationValidationMessage = {
  NetCapitalGainsYTD: 'Net Capital Gains YTD ($) should not be greater than allowed limit.',
  TicketCharge: 'Ticket charge ($) value should not be greater than allowed limit.',
  MaximumCapitalGain: 'Maximum Capital Gains ($) value should not be greater than allowed limit.',
  MaximumNoOfTrades: 'Maximum # Trades value should not be greater than allowed limit.',
  MaximumNoOfAssets: 'Maximum # Assets value should not be greater than allowed limit.',
  InvalidPercentage: 'should not be greater than 100.',
  InvalidTurnoverPercentage: 'Maximum Turnover (%) Should not be greater than 1000.',
  MinimumTradeSize: 'Minimum Trade Size value should not be greater than allowed limit.'
};

export const TransactionCostValidationCode = {
  SellTradeTransactionCost: 'SellTradeTransactionCost',
  BuyTradeTransactionCost: 'BuyTradeTransactionCost'
};

export const TransactionCostValidationMessage = {
  SellTradeTransactionCost: 'Transaction Cost (Sell) ($) value should not be greater than allowed limit.',
  BuyTradeTransactionCost: 'Transaction Cost (Buy) ($) value should not be greater than allowed limit.'
};

export const TrackingErrorValidationCode = {
  MissingField: 'MissingField',
  MaxMinTrackingError: 'MaxMinTrackingError'
};

export const TrackingErrorValidationMessage = {
  MinMaxBothShouldPresent: 'Maximum Tracking Error (%) and Minimum Tracking Error (%) must be entered together.',
  MaxShouldGreaterThanMin: 'Maximum Tracking Error (%) must be greater than Minimum Tracking Error (%)'
};

export const WithdrawCashValidationCode = {
  GreaterThanZero: 'GreaterThanZero',
  GreaterThanExistingCash: 'GreaterThanExistingCash',
  LessThanMarketValue: 'LessThanMarketValue',
  MarketValueAndAvailableCashDifference: 'MarketValueAndAvailableCashDifference'
};

export const WithdrawCashValidationMessage = {
  GreaterThanZero: 'Amount to withdraw must be greater than $0.',
  GreaterThanExistingCash: 'Amount to withdraw must be greater than existing cash.',
  LessThanMarketValue: 'Amount to withdraw must be less than Total Market Value.',
  MarketValueAndAvailableCashDifference: 'Withdrawal amount should be less than the difference of Total Market Value and Available Cash'
};

export const HarvestLossValidationCode = {
  LossFieldMissing: 'LossFieldMissing',
  LossGtFieldMissing: 'LossGtFieldMissing',
  ShortTermLoss: 'ShortTermLoss',
  LongTermLoss: 'LongTermLoss',
  SellIfLossGT: 'SellIfLossGT',
  SellIfLossGTPercent: 'SellIfLossGTPercent'
};

export const HarvestLossValidationMessage = {
  LossFieldMissing: 'Please enter either $ amount or percent, ST or LT Loss',
  LossGtFieldMissing: 'Please enter either $ amount or percent, Loss.',
  ShortTermLoss: 'Short Term Loss value should not be greater than allowed limit.',
  LongTermLoss: 'Long Term Loss value should not be greater than allowed limit.',
  SellIfLossGT: 'Sell Lot if Loss GT value should not be greater than allowed limit.'
};

export const AstroOptimizationCompletionMessages = {
  Comment: 'Optimization Complete',
  MultipleBatchOptimizationComplete: 'Multiple Batch Optimization Complete.',
  UnhandledBatchesPresent: 'There is a delay in processing some batches. View processed batches.'
};

export const InvestorPreferenceSaveResponse = {
  ErrorWhileSavingPreferences: 'Error occurred while saving Investor Preferences data.'
};

export const AstroOptimizationStackedBarLabel = {
  Done: 'Done',
  Failed: 'Failed',
  Pending: 'Pending'
};

export const AstroAccountPreferenceValue = {
  AllowShortTermGain: 'allowShortTermGains'
};
