import { Routes } from '@angular/router';
import { EclipseComponent } from './eclipse.component';
import { ErrorLogComponent } from './components/errorLog/errorlog.component';
import { DataQueriesComponent } from './components/querybuilder/dataqueries/dataqueries.component';
import { AnalyticsErrorLogComponent } from './components/analyticserrorlog/analyticserrorlog.component';
import { MainDashboardComponent } from './components/dashboard';
import { featureFlagsResolver, SplitIoService } from './core/feature-flag';
import { authGuard, confirmDeactivateGuard, featureFlagGuard } from './core/guard';
import { inject } from '@angular/core';
import { DataQueriesLegacyComponent } from './components/querybuilder';

export const EclipseRoutes: Routes = [
  { path: 'eclipse', redirectTo: 'eclipse/dashboard', pathMatch: 'full' },
  {
    path: 'eclipse',
    component: EclipseComponent,
    canActivate: [authGuard],
    canActivateChild: [featureFlagGuard],
    resolve: { featureFlags: () => inject(SplitIoService).resolveGlobalFeatureFlags() },
    children:
      [
        {
          path: 'dashboard',
          title: 'Overview',
          component: MainDashboardComponent,
          resolve: { featureFlags: featureFlagsResolver },
        },
        { path: 'errorlogs', title: 'Error Logs', component: ErrorLogComponent },
        { path: 'errorlogs/:type', title: 'Error Logs', component: ErrorLogComponent },
        { path: 'analyticserrorlogs', title: 'Analytics Error Logs', component: AnalyticsErrorLogComponent },
        {
          path: 'sleeve',
          title: 'Sleeves',
          loadChildren: () => import('./components/sleeve/sleeve.module').then(m => m.SleeveModule),
          data: {
            preload: false
          }
         },
        {
          path: 'admin',
          title: 'Administration',
          loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
          data: {
            preload: false
          }
        },
        {
          path: 'manageusers',
          title: 'Manage Users',
          loadChildren: () => import('./components/manage-users/manage-users.module').then(m => m.ManageUsersModule),
          data: {
            preload: false
          }
        },
        { path: 'model', title: 'Models', redirectTo: 'strategy/model' }, // models have moved to strategy/model
        {
          path: 'strategy',
          loadChildren: () => import('./components/strategy/strategy.module').then(m => m.StrategyModule),
          data: {
            preload: false
          }
        },
        {
          path: 'portfolio',
          title: 'Portfolios',
          loadChildren: () => import('./components/portfolio/portfolio.module').then(m => m.PortfolioModule),
          data: {
            preload: false
          }
        },
        {
          path: 'account',
          title: 'Accounts',
          loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule),
          data: {
            preload: false
          }
      },
        {
          path: 'security',
          title: 'Securities',
          loadChildren: () => import('./components/security/security.module').then(m => m.SecurityModule),
          data: {
            preload: false
          }
        },
        {
          path: 'holding',
          title: 'Holdings',
          loadChildren: () => import('./components/holding/holding.module').then(m => m.HoldingModule),
          data: {
            preload: false
          }
        },
        {
          path: 'tradeorder',
          title: 'Orders',
          loadChildren: () => import('./components/tradeorder/tradeorder.module').then(m => m.TradeOrderModule),
          data: {
            preload: false
          }
        },
        {
          path: 'tradetool',
          title: 'Trade Tool',
          loadChildren:  () => import('./components/tradetool/tradetool.module').then(m => m.TradeToolModule),
          data: {
            preload: false
          }
        },
        {
          path: 'blendedfund',
          title: 'Blended Fund',
          loadChildren: () => import('./components/blendedfund/blendedfund.module').then(m => m.BlendedFundModule),
          data: {
            preload: false
          }
        },
        {
          path: 'astro',
          title: 'Astro',
          loadChildren:  () => import('./components/astro/astro.module').then(m => m.AstroModule),
          data: {
            preload: false
          }
        },
        // Remove legacy routes when deleting TEXP_querybuilder_primeng_10966
        { path: 'querybuilder/legacy', title: 'Query Builder', component: DataQueriesLegacyComponent, canDeactivate: [confirmDeactivateGuard] },
        { path: 'querybuilder/legacy/:type/:id', title: 'Query Builder', component: DataQueriesLegacyComponent, canDeactivate: [confirmDeactivateGuard] },
        { path: 'querybuilder', title: 'Query Builder', component: DataQueriesComponent, canDeactivate: [confirmDeactivateGuard] },
        { path: 'querybuilder/:type/:id', title: 'Query Builder', component: DataQueriesComponent, canDeactivate: [confirmDeactivateGuard] },
        {
          path: 'customimports',
          title: 'Custom Imports',
          loadChildren: () => import('./components/custom-imports/custom-imports.module').then(m => m.CustomImportsModule),
          data: {
            preload: false
          }
        },
        {
          path: 'datastatus',
          title: 'Data Status',
          loadChildren: () => import('./components/datastatus/datastatus.module').then(m => m.DataStatusModule),
        }
      ]
  }
];
