import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../core/base.component';
import {IMasterColumns, IMasterColumnsValues, IQuery, IRules, IMacros} from '../../../models/querybuilder';
import {QueryBuilderService} from '../../../services/querybuilder.service';

@Component({
  selector: 'eclipse-data-query-advance',
  templateUrl: './dataqueryadvance.component.html'
})
export class DataQueryAdvanceComponent extends BaseComponent {

  @Input() availableColumnList: any[] = [];
  @Input() currentAdvanceQueryGroups: any;
  @Input() selectedColumnList: any[] = [];
  isAdvancedChanged: boolean = false;
  macroList: any = [];
  showPredefinedValues: boolean = false;
  predefinedList: IMasterColumnsValues[];
  integerOperatorsList: any = [];
  stringOperatorsList: any = [];
  booleanOperatorsList: any = [];
  @Input() advanceQueryChanges: IQuery = <IQuery>{};
  @Input() removeHide: boolean;

  public readonly andOrOptions: { label: string }[] = [
    {label: 'AND'}, {label: 'OR'}
  ];
  public readonly yesNoValues: { label: string; value: number }[] = [
    {label: 'Yes', value: 1}, {label: 'No', value: 0}
  ];
  public readonly sleeveTypeValues: { label: string }[] = [
    {label: 'Normal'}, {label: 'None'},
    {label: 'Custodial'}, {label: 'Distribution'},
  ];

  constructor(private queryBuilderService: QueryBuilderService) {
    super();
  }

  ngOnInit() {
    this.getAvailableColumns();
    this.getMacroList();
    this.loadIntegerOperators();
    this.loadStringOperators();
    this.loadBooleanOperators();

  }

  getChange() {
    this.isAdvancedChanged = true;
    this.advanceQueryChanges.isChanged = true;
  }

  // Getting availableColumns from DataBase
  getAvailableColumns() {
    this.queryBuilderService.getMasterColumns()
      .subscribe((columns: IMasterColumns[]) => {
        this.availableColumnList = columns;
      });
  }

  // Used to addGroupRow when click on addGroup button
  addGroupRow(groupParam) {
    groupParam.groups.push({
      condition: groupParam.condition,
      rules: (<IRules[]>[]),
      groups: (<IQuery[]>[])
    });
    this.getChange();
  }

  // Used to remove the Group
  removeChildGroup(event) {
    // eslint-disable-next-line eqeqeq
    const index = this.currentAdvanceQueryGroups.findIndex(x => x == event);
    this.currentAdvanceQueryGroups.splice(index, 1);
    this.getChange();
  }

  // Used to add the rule when click on add rule button
  addRule(ruleParam) {
    ruleParam.rules.push({
      selectedColumn: '',
      operator: '',
      value: '',
      columnDateType: ''
    });
    this.getChange();
  }

  // Used to remove the rule
  removeRule(group, rule) {
    // eslint-disable-next-line eqeqeq
    const index = group.rules.findIndex(x => x == rule);
    group.rules.splice(index, 1);
    this.getChange();
  }

  // Used to call when availableColumns removed from availableColumnList
  removeSelectedRow(selectedColumnObj) {
    this.getGroup(selectedColumnObj);
  }

  getGroup(selectedColumnObjs) {
    const advanceQueryObject = this.currentAdvanceQueryGroups[0];
    selectedColumnObjs.forEach(selectedColumnObj => {
      advanceQueryObject.rules.forEach(rule => {
        // eslint-disable-next-line eqeqeq
        advanceQueryObject.rules = advanceQueryObject.rules.filter(x => x.selectedColumnId != selectedColumnObj.id);
      });
      advanceQueryObject.groups.forEach(group => {
        // eslint-disable-next-line eqeqeq
        if (group.rules != undefined) {
          // eslint-disable-next-line eqeqeq
          group.rules = group.rules.filter(r => r.selectedColumnId != selectedColumnObj.id);
          // eslint-disable-next-line eqeqeq
          if (group.rules != undefined) {
            if (group.groups.length > 0) {
              this.removeGroupAndRules(group.groups, selectedColumnObj);
            }
          }
        }
      });
    });
    this.currentAdvanceQueryGroups[0] = advanceQueryObject;
  }

  removeGroupAndRules(group, selectedColumnObj) {
    group.forEach(subGroup => {
      // eslint-disable-next-line eqeqeq
      subGroup.rules = subGroup.rules.filter(x => x.selectedColumnId != selectedColumnObj.id);
      this.removeGroupAndRules(subGroup.groups, selectedColumnObj);
      // This is to delete the group when there is no rules and group under it
      // if ((subGroup.rules != undefined && subGroup.rules.length == 0) && (subGroup.groups != undefined && subGroup.groups.length == 0)) {
      //     subGroup.groups = undefined;
      // }
      // if ((subGroup.rules != undefined && subGroup.rules.length == 0) && (subGroup.groups != undefined && subGroup.groups.length != 0)) {
      //    if(subGroup.groups.groups != undefined &&  subGroup.groups.groups.length ==0)
      //     subGroup.groups.groups = undefined;
      // }
    });
  }

  // refering from parent to child
  assignAdvanceQuery(adavanceQuery: any) {
    this.currentAdvanceQueryGroups = adavanceQuery;
    // eslint-disable-next-line eqeqeq
    if (this.availableColumnList.length == 0) {
      this.getAvailableColumns();
    }
  }

  // Getting the selectedColumn datatype
  setColumnDataType(advancedSelected: IRules, event) {
    const dataType = this.availableColumnList.find(d => d.id === event.value)?.dataType;
    advancedSelected.value = '';
    advancedSelected.date = '';
    advancedSelected.macro = '';
    advancedSelected.operator = '';
    advancedSelected.predefinedValue = null;
    // eslint-disable-next-line eqeqeq
    const data = this.availableColumnList.filter(x => x.id == event.value);
    if (!!data.length) {
      if (data[0].isPredefined) {
        this.callPredefined(event.value, advancedSelected);
      }
    }
    advancedSelected.isPredefined = false;
    if (dataType) {
      advancedSelected.columnDateType = dataType;
      advancedSelected.columnDateType = advancedSelected.columnDateType === 'ng-star-inserted' ? undefined : advancedSelected.columnDateType;
    }
    if (advancedSelected.columnDateType === '' || !advancedSelected.columnDateType) {
      advancedSelected.columnDateType = data[0].dataType;
    }
    this.getChange();
  }

  // Will call any changes made in existing query
  setChange() {
    this.getChange();
  }

  // Will clear the date in change of macroList
  clearDate(advancedSelected) {
    advancedSelected.date = '';
    this.setChange();
  }

  // Getting macros list from DataBase
  getMacroList() {
    this.queryBuilderService.getMacros()
      .subscribe((macros: IMacros[]) => {
        macros.unshift({id: 0, name: 'Specific date', typeId: null});
        this.macroList = macros;
      });
  }

  // Setting predefined values
  setPredefinedvalue(advancedSelected, event) {
    this.getChange();
    advancedSelected.predefinedName = advancedSelected.predefinedList.values.find(x => x.id === event.value).name;
  }

  // Will call when selectedColumn contains predefied values
  callPredefined(selectedId, advancedSelected) {
    this.queryBuilderService.getMasterColumnValues(selectedId)
      .subscribe((predefinedValues: IMasterColumnsValues[]) => {
          this.predefinedList = predefinedValues;
          advancedSelected.isPredefined = true;
          advancedSelected.predefinedList = predefinedValues;
        },
        error => {
          console.log(error);
        });
  }

  loadBooleanOperators() {
    this.booleanOperatorsList.push(
      {
        id: 1,
        value: 'Equal'
      },
      {
        id: 2,
        value: 'Not Equal'
      }
    );
  }

  loadIntegerOperators() {
    this.integerOperatorsList.push(
      {
        id: 1,
        value: 'Equal'
      },
      {
        id: 2,
        value: 'Not Equal'
      },
      {
        id: 3,
        value: 'Less Than'
      },
      {
        id: 4,
        value: 'Less Than or Equal'
      },
      {
        id: 5,
        value: 'Greater Than'
      },
      {
        id: 6,
        value: 'Greater Than or Equal'
      },
    );
  }

  loadStringOperators() {
    this.stringOperatorsList.push(
      {
        id: 1,
        value: 'Equal'
      },
      {
        id: 2,
        value: 'Not Equal'
      },
      {
        id: 7,
        value: 'Contain'
      },
      {
        id: 8,
        value: 'Start With'
      },
      {
        id: 9,
        value: 'End With'
      },
      {
        id: 10,
        value: 'Not Contains'
      }
    );
  }

  validateValue(dataType, event) {
    // eslint-disable-next-line eqeqeq
    if (dataType == 'INT' || dataType == 'BIGINT' || dataType == 'TINYINT' || dataType == 'BIT') {
      // eslint-disable-next-line eqeqeq
      if (event.key != 1 && event.key != 2 && event.key != 3 && event.key != 4 && event.key != 5 && event.key != 6 && event.key != 7 && event.key != 8 && event.key != 9 && event.key != 0 && event.key != 'y' && event.key != 'e' && event.key != 's' && event.key != 'n' && event.key != 'o' && event.key != 'Y' && event.key != 'E' && event.key != 'S' && event.key != 'N' && event.key != 'O') {
        return false;
      }
      // let parsetxt = parseInt(event.target.value + event.key);
      // return (!isNaN(parsetxt));
    // eslint-disable-next-line eqeqeq
    } else if (dataType == 'DECIMAL') {
      // eslint-disable-next-line eqeqeq
      if (event.key.toLowerCase() != 1 && event.key != 2 && event.key.toLowerCase() != 3 && event.key != 4 && event.key.toLowerCase() != 5 && event.key != 6 && event.key.toLowerCase() != 7 && event.key != 8 && event.key.toLowerCase() != 9 && event.key != 0 && event.key != '.') {
        return false;
      }
      const parsetxt = parseInt(event.target.value + event.key, 10);
      return (!isNaN(parsetxt));
    }
    // else if (dataType == 'TINYINT' || dataType == 'BIT') {
    //     if (event.key != 1 && event.key != 0 && event.key != 'y' && event.key != 'e' && event.key != 's' && event.key != 'n' && event.key != 'o' && event.key != 'Y' && event.key != 'E' && event.key != 'S' && event.key != 'N' && event.key != 'O') {
    //         return false;
    //     }
    //     // let parsetxt = parseInt(event.target.value + event.key);
    //     // return (!isNaN(parsetxt));
    // }
    // else if (dataType == 'VARCHAR') {
    //     if (event.key.toLowerCase() == 1 || event.key == 2 || event.key.toLowerCase() == 3 || event.key == 4 || event.key.toLowerCase() == 5 || event.key == 6 || event.key.toLowerCase() == 7 || event.key == 8 || event.key.toLowerCase() == 9 || event.key == 0) {
    //         return false;
    //     }
    // }
  }

}
