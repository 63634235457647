import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorNotificationService } from '../customSubService';
import { HTTP_CONSTANTS } from '../../libs/app.constants';

/**
 * HTTP Interceptor for global error alerting.  If the header contains the `x-skip-error` key,
 * then no error handling will be applied.
 *
 * The `x-skip-error` header is removed before leaving this interceptor since its only purpose is
 * to indicate if error handling is desired.
 */
@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
  private readonly _errorNotificationService = inject(ErrorNotificationService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(HTTP_CONSTANTS.SKIP_ERROR_HEADER_KEY)) {
      const modifiedReq = request.clone({
        headers: request.headers.delete(HTTP_CONSTANTS.SKIP_ERROR_HEADER_KEY),
      });
      return next.handle(modifiedReq);
    }
    return this.addErrorHandlers(request, next);
  }

  private addErrorHandlers(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const uuid = request.headers.get(HTTP_CONSTANTS.REQUEST_ID_HEADER_KEY);

    return next
      .handle(request)
      .pipe(
        catchError((err: any) => {
          err.requestId = uuid ?? '';

          if (request.responseType === 'blob') {
            const reader: FileReader = new FileReader();
            const obs = new Observable<any>((observer: any) => {
              reader.onloadend = () => {
                err.message = JSON.parse(reader.result as string);
                this._errorNotificationService.errorResponse.emit(err);
                observer.error(err);
                observer.complete();
              };
            });
            try {
              reader.readAsText(err.error);
            } catch (readerErr) {
              console.error('File Reader error', readerErr);
              console.error('Original error', err);
              return throwError(() => err);
            }
            return obs;
          } else {
            this._errorNotificationService.errorResponse.emit(err);
            return throwError(() => err);
          }
        }),
      );
  }
}
