import {Component, Input} from '@angular/core';
import {IMasterColumns, IMasterColumnsValues, IRules, IQuery, IMacros} from '../../../models/querybuilder';
import {QueryBuilderService} from '../../../services/querybuilder.service';
import {Utils} from '../../../core/functions';

@Component({
  selector: 'eclipse-data-query-standard',
  templateUrl: './dataquerystandard.component.html'
})
export class DataQueryStandardComponent {
  @Input() standardQuery: IQuery;
  availableColumnList: IMasterColumns[] = [];
  public readonly andOrOptions: { label: string }[] = [
    {label: 'AND'}, {label: 'OR'}
  ];
  public readonly yesNoValues: { label: string; value: number }[] = [
    {label: 'Yes', value: 1}, {label: 'No', value: 0}
  ];
  public readonly sleeveTypeValues: { label: string }[] = [
    {label: 'Normal'}, {label: 'None'},
    {label: 'Custodial'}, {label: 'Distribution'},
  ];
  macroList: IMacros[] = [];
  predefinedList: IMasterColumnsValues[];
  // showPredefinedValues: boolean = false;
  operatorsList: any = [];
  integerOperatorsList: any = [];
  stringOperatorsList: any = [];
  booleanOperatorsList: any = [];
  copyOfRules: any = [];

  constructor(private queryBuilderService: QueryBuilderService) {
    this.standardQuery = <IQuery>{};
  }

  ngOnInit() {
    this.getAvailableColumns();
    this.getMacroList();
    this.loadIntegerOperators();
    this.loadStringOperators();
    this.loadBooleanOperators();
    this.standardQuery.showSameRuleError = false;
  }

  // Used to assign the query which got from Parent page
  assignStandardQuery(standardquery: any) {
    this.standardQuery = standardquery;
  }

  // Get availableColumns from DataBase
  getAvailableColumns() {
    this.queryBuilderService.getMasterColumns()
      .subscribe((columns: IMasterColumns[]) => {
        this.availableColumnList = columns;
      });
  }

  // Validating if same rule is already existing or not
  sameRuleValidation(arr) {
    const objArray = [];
    arr.forEach(q => {
      // eslint-disable-next-line eqeqeq
      if (q.selectedColumn == '' && q.operator == '' && q.value == '') {
        this.standardQuery.emptyRuleValidation = true;
        return;
      } else {
        this.standardQuery.emptyRuleValidation = false;
        objArray.push(JSON.stringify(q, Object.keys(q).sort()));
      }

    });

    const result = Utils.checkForDuplicatesInArray(objArray);
    // eslint-disable-next-line eqeqeq
    this.standardQuery.showSameRuleError = result == true;
  }

  // To add a query rule
  addQueryRule(rules) {
    this.sameRuleValidation(rules);
    if (!this.standardQuery.showSameRuleError && !this.standardQuery.emptyRuleValidation) { // If same rule is already exists we are not adding rule again
      rules.push({
        operator: '',
        selectedColumn: '',
        value: '',
        columnDateType: ''
      });
      this.standardQuery.isChanged = true;
    }

  }

  // Delete the rule
  deleteRow(row) {
    this.standardQuery.isChanged = true;
    // eslint-disable-next-line eqeqeq
    const index = this.standardQuery.where.rules.findIndex(x => x == row);
    this.standardQuery.where.rules.splice(index, 1);
    this.sameRuleValidation(this.standardQuery.where.rules);
    // This condition is used to delete the error message(Empty rule cannot be added)
    // eslint-disable-next-line eqeqeq
    if (this.standardQuery.where.rules.length == 0) {
      if (this.standardQuery.emptyRuleValidation) {
        this.standardQuery.emptyRuleValidation = false;
      }
    }
  }

  // If user remove the Selected Columns, then that corresponding rules will be deleted
  deleteRowInSelectedColumn(row) {
    // eslint-disable-next-line eqeqeq
    this.standardQuery.where.rules = this.standardQuery.where.rules.filter(x => x.selectedColumnId != row.selectedColumnId);
  }

  // Used to call when availableColumns removed from availableColumnList
  removeSelectedRow(selectedColumnObjs) {
    selectedColumnObjs.forEach(selectedColumnObj => {
      this.removeRule(selectedColumnObj);
    });
  }

  removeRule(selectedColumnObj) {
    this.standardQuery.where.rules.forEach(rule => {
      // eslint-disable-next-line eqeqeq
      if (rule.selectedColumnId == selectedColumnObj.id) {
        this.deleteRowInSelectedColumn(rule);
      }
    });
  }

  // Setting predefined values
  setPredefinedvalue(standardSelected, event) {
    this.standardQuery.isChanged = true;
    standardSelected.predefinedName = standardSelected.predefinedList.values.find(x => x.id === event.value).name;
    standardSelected.predefinedValue = event.value;
    this.sameRuleValidation(this.standardQuery.where.rules);
  }

  // Getting the selectedColumn datatype
  setColumnDataType(standardSelected: IRules, event) {
    const dataType = this.standardQuery.selectedColumns.find(d => d.id === event.value)?.dataType;
    this.standardQuery.isChanged = true;
    standardSelected.value = '';
    standardSelected.macro = '';
    standardSelected.date = '';
    standardSelected.operator = '';
    // standardSelected.predefinedValue = null;
    // eslint-disable-next-line eqeqeq
    const data = this.availableColumnList.filter(x => x.id == event.value);
    // eslint-disable-next-line eqeqeq
    if (data.length != 0) {
      if (data[0].isPredefined) {
        this.callPredefined(event.value, standardSelected);
      } else {
        standardSelected.predefinedList = [];
        standardSelected.predefinedName = '';
        standardSelected.predefinedValue = '';
      }
    }
    standardSelected.isPredefined = false;
    standardSelected.columnDateType = dataType;

    // eslint-disable-next-line eqeqeq
    if (standardSelected.columnDateType == '' || standardSelected.columnDateType == undefined) {
      standardSelected.columnDateType = data[0].dataType;
    }
    this.sameRuleValidation(this.standardQuery.where.rules);
  }

  // Will call any changes made in existing query
  setChange() {
    this.standardQuery.isChanged = true;
    if (this.standardQuery.where.rules.length >= 1) {
      this.sameRuleValidation(this.standardQuery.where.rules);
    }
  }

  // Will clear the date in change of macroList
  clearDate(standardSelected) {
    standardSelected.date = '';
    this.setChange();
  }

  // Getting macros list from DataBase
  getMacroList() {
    this.queryBuilderService.getMacros()
      .subscribe((macros: IMacros[]) => {
        macros.unshift({id: 0, name: 'Specific date', typeId: null});
        this.macroList = macros;
      });
  }

  // Will call when selectedColumn contains predefied values
  callPredefined(selectedId, standardSelected) {
    this.queryBuilderService.getMasterColumnValues(selectedId)
      .subscribe((predefinedValues: IMasterColumnsValues) => {
          standardSelected.predefinedList = predefinedValues;
          standardSelected.isPredefined = true;
        },
        error => {
          console.log(error);
        });
  }

  loadBooleanOperators() {
    this.booleanOperatorsList.push(
      {
        id: 1,
        value: 'Equal'
      },
      {
        id: 2,
        value: 'Not Equal'
      }
    );
  }

  loadIntegerOperators() {
    this.integerOperatorsList.push(
      {
        id: 1,
        value: 'Equal'
      },
      {
        id: 2,
        value: 'Not Equal'
      },
      {
        id: 3,
        value: 'Less Than'
      },
      {
        id: 4,
        value: 'Less Than or Equal'
      },
      {
        id: 5,
        value: 'Greater Than'
      },
      {
        id: 6,
        value: 'Greater Than or Equal'
      },
    );
  }

  loadStringOperators() {
    this.stringOperatorsList.push(
      {
        id: 1,
        value: 'Equal'
      },
      {
        id: 2,
        value: 'Not Equal'
      },
      {
        id: 7,
        value: 'Contain'
      },
      {
        id: 8,
        value: 'Start With'
      },
      {
        id: 9,
        value: 'End With'
      },
      {
        id: 10,
        value: 'Not Contains'
      }
    );
  }

  /** Returns a range of years used as a default for calendar picker year dropdowns. */
  getYearRange(yearsInFuture: number = 0): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() + yearsInFuture);
    return `2000:${date.getFullYear()}`; // 2000 -> current year
  }

  // Used to Validate the Value field
  validateValue(dataType, event) {
    // eslint-disable-next-line eqeqeq
    if (dataType == 'INT' || dataType == 'BIGINT' || dataType == 'TINYINT' || dataType == 'BIT') {
      // eslint-disable-next-line eqeqeq
      if (event.key != 1 && event.key != 2 && event.key != 3 && event.key != 4 && event.key != 5 && event.key != 6 && event.key != 7 && event.key != 8 && event.key != 9 && event.key != 0 && event.key != 'y' && event.key != 'e' && event.key != 's' && event.key != 'n' && event.key != 'o' && event.key != 'Y' && event.key != 'E' && event.key != 'S' && event.key != 'N' && event.key != 'O') {
        return false;
      }
      // let parsetxt = parseInt(event.target.value + event.key);
      // return (!isNaN(parsetxt));
    // eslint-disable-next-line eqeqeq
    } else if (dataType == 'DECIMAL') {
      // eslint-disable-next-line eqeqeq
      if (event.key.toLowerCase() != 1 && event.key != 2 && event.key.toLowerCase() != 3 && event.key != 4 && event.key.toLowerCase() != 5 && event.key != 6 && event.key.toLowerCase() != 7 && event.key != 8 && event.key.toLowerCase() != 9 && event.key != 0 && event.key != '.' && event.key != '-') {
        return false;
      }

      //allow first character to be -
      if(event.target.value === "" && event.key === '-') {
        return  true;
      }
      const parseText = parseInt(event.target.value + event.key, 10);
      return (!isNaN(parseText));
    }
  }
}
