import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { PortfolioEditorService } from './portfolio-editor.service';
import { Observable, Subject, switchMap, takeUntil, filter } from 'rxjs';
import { PortfolioService } from "../../../services/portfolio.service";
import { PortfolioGainLossSummary } from "../../../models/portfolio";

@Component({
  selector: 'eclipse-portfolio-editor-gain-loss',
  templateUrl: './portfolio-gain-loss.component.html'
})
export class PortfolioGainLossComponent extends BaseComponent {
  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  private readonly _portfolioService: PortfolioService = inject(PortfolioService);
  private destroyed$: Subject<void> = new Subject<void>();

  public gainLossSummary$: Observable<PortfolioGainLossSummary>;

  constructor() {
    super();

    this.gainLossSummary$ =  this.portfolioEditorService.portfolio$
      .pipe(
        takeUntil(this.destroyed$),
        filter(p => !!p.id),
        switchMap((p) => { return this._portfolioService.getGainLossSummary(p.id) })
      )
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
