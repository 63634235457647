<div class="flex flex-column">
  <eclipse-page-header pageName="Overview">
    <section slot="action" style="display: flex; gap: 5px;">
      <button pButton class="p-button-secondary" type="button" [label]="importHistoryLabel"
              (click)="openImportHistory()"></button>
      <p-button *ngIf="!dataStatusEnabled" badge="{{summaryData?.errors || 0}}" styleClass="p-button-secondary" label="Import Errors"
        [routerLink]="['/eclipse', 'errorlogs']"></p-button>
      <p-button *ngIf="dataStatusEnabled" badge="{{summaryData?.errors || 0}}" styleClass="p-button-secondary" label="Import Errors"
        [routerLink]="['/eclipse', 'datastatus','errors']" [queryParams]="{tab:0}"></p-button>
      <p-splitButton label="New Import" (onClick)="importOptions.length === 1 ? importOptions[0].command() : null"
                     [model]="importOptions" styleClass="p-button-secondary"
                     (onDropdownClick)="setImportOptions()" [disabled]="disableStartImport || !importOptions.length"
                     expandAriaLabel="Import options"></p-splitButton>

      <div class="btn-group actionBtn">
        <button type="button" pButton class="p-button-secondary" icon="fas fa-ellipsis-h" data-target="#"
                data-toggle="dropdown" title="Analytics actions"></button>
        <ul class="dropdown-menu dropdown-menu-right">
          <li><a href="javascript:void(0)" *ngIf="showRefreshAnalytics" [class.disabled]="disableAnalyticsRelated"
                 (click)="onMenuClick('RefreshAnalytics')">Refresh Database Analytics</a></li>
          <li><a href="javascript:void(0)" *ngIf="displayResetAnalytics" (click)="onMenuClick('ResetAnalytics')">Reset
            Analytics</a></li>
          <li><a href="javascript:void(0)" [routerLink]="['/eclipse', 'analyticserrorlogs']">View Analytics Error
            Log</a></li>
          <li><a href="javascript:void(0)" (click)="openReverseSyncHistory()" *ngIf="!showCombinedHistory">View Reverse Sync History</a></li>
        </ul>
      </div>
    </section>
    <section slot="subtitle" class="page-header-subtitle" *ngIf="(importDate$ | async) as importDate">
      <span class="last-import-value" *ngIf="!importDate">N/A</span>
      <span class="last-import-value" *ngIf="importDate && !summaryData?.lastImportWarning">
        <i class="fas fa-calendar-alt"></i> Last Import on {{ importDate | date: "MMMM dd, yyyy 'at' h:mm a" }} {{summaryData?.lastImportTimezone}}
      </span>
      <p-chip *ngIf="summaryData?.lastImportedDate && summaryData.lastImportWarning" styleClass="warningAlt" icon="fas fa-triangle-exclamation fa-sm"
              label="Last Import on {{ summaryData?.lastImportedDate | date: 'MMMM dd, yyyy \'at\' h:mm a z' }}"></p-chip>
    </section>
  </eclipse-page-header>

  <ng-container [ngTemplateOutlet]="newDashboard"></ng-container>

  <ng-template #newDashboard>
    <div class="dashboard-content" [ngClass]="{'loading-value': (dashboardData$ | async) === null}">
      <section>
        <div id="analyticsstatus">
          <span *ngIf="showAnalyticsDuration" class="analytic-status-message analytic-status-running">
            <i class="fas fa-sync fa-spin"
               aria-hidden="true"></i> Refresh analytics in progress. Duration: {{ analyticsDuration }}
          </span>
          <span *ngIf="isAnalyticsCompleted" class="analytic-status-message analytic-status-complete">
            <i class="fas fa-check" aria-hidden="true"></i> Refresh analytics completed
          </span>
          <span *ngIf="isResetAnalytics" class="analytic-status-message analytic-status-aborted">
            <i class="fas fa-times" aria-hidden="true"></i> Refresh analytics aborted
          </span>
        </div>
        <section id="overviewmetrics" class="overview-metrics">
          <div class="overview-metric">
            <span>Total AUM</span>
            <span title="{{summaryData?.totalAUM |  currency:'USD'}}">{{ summaryData?.totalAUM | currencyMillionFilter : 2 }} {{ summaryData?.totalAUM | currencyAmountfilter }}</span>
          </div>
          <div class="overview-metric">
            <span>Total Portfolios</span>
            <span>{{ portfolioData?.total || 0 | number }}</span>
          </div>
          <div class="overview-metric">
            <span>Total Accounts</span>
            <span>{{ accountData?.total || 0 | number }}</span>
          </div>
          <div class="overview-metric">
            <span>Total Models</span>
            <span>{{ modelData?.total || 0 | number }}</span>
          </div>
        </section>
      </section>
      <section class="m-x-15 m-t-40">
        <eclipse-main-dashboard-container
          [dashboardSummary]="dashboardData$ | async"></eclipse-main-dashboard-container>
      </section>
    </div>
  </ng-template>
</div>

<p-dialog header="Reverse Sync History" *ngIf="!showCombinedHistory" [(visible)]="showReverseSyncHistory" modal="true"
          [draggable]=true [resizable]="false" class="ui-dialog-lg">
  <div class="sma-weightings-table">
    <div class="margin-top-15">
      <ag-grid-angular #agGrid style="width: 100%; height: 400px;" class="ag-theme-alpine"
                       (gridReady)="onReverseSyncGridReady($event)" rowSelection="multiple"
                       [gridOptions]="reverseSyncGridOptions" [columnDefs]="reverseSyncColumnDefs"
                       [rowData]="reverseSyncHistoryData"
                       (rowSelected)="onReverseSyncRowSelected()"
                       [suppressContextMenu]="true">
      </ag-grid-angular>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton (click)="exportReverseSyncToExcel()" label="Export to Excel"
            [disabled]="!reverseSyncHistoryData?.length" icon="far fa-file-excel">
    </button>
    <button type="button" pButton (click)="downloadReverseSyncLogs()"
            *ngIf="canExportLogs" [disabled]="!selectedReverseSyncRows?.length" label="Download"></button>
    <button type="button" pButton data-dismiss="modal" class="p-button-secondary" (click)="showReverseSyncHistory=false"
            label="Close"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="showNoMergedFirmMessage" class="ui-dialog-md" modal="true" [draggable]="false"
          [resizable]="false">
  <div><p class="text-center">Please enter identified Orion Connect Firms you are partnered with for data import.</p>
    <p class="text-center">Go to Administration Page.</p>
  </div>
  <p-footer>
    <button pButton type="button" (click)="showNoMergedFirmMessage = false" label="OK" title="OK"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Import History" [(visible)]="showImportHistory" class="ui-dialog-xl" modal="true"
          [draggable]=true [resizable]="false" *ngIf="!showCombinedHistory">
  <div>
    <div class="sma-weightings-table">
      <div class="form-inline">
        <div class="form-group" style="display: flex;">
          <div class="col-sm-5">
            <label id="startDateLabel">Start Date: </label>
            <p-calendar name="startDate" [showIcon]="true" [monthNavigator]="true" placeholder="Select Start Date"
                        appendTo="body"
                        [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="startDate" [maxDate]="maxDate"
                        id="startDate"
                        (onSelect)="dateSelect($event,'start')" [readonlyInput]="true" iconAriaLabel="Select start date"
                        ariaLabelledBy="startDateLabel"></p-calendar>
          </div>
          <div class="col-sm-5">
            <label id="endDateLabel">End Date: </label>
            <p-calendar name="endDate" [showIcon]="true" [monthNavigator]="true" placeholder="Select End Date"
                        appendTo="body"
                        [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="endDate" [maxDate]="maxDate"
                        id="endDate"
                        (onSelect)="dateSelect($event,'end')" [readonlyInput]="true" iconAriaLabel="Select end date"
                        ariaLabelledBy="endDateLabel"></p-calendar>
          </div>
          <div class="col-md-2 col-sm-2 text-right">
            <button pButton type="button" (click)="viewImportHistoryData()" [disabled]="validateStartAndEndDates()"
                    label="View"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="sma-weightings-table">
      <div class="margin-top-15">
        <ag-grid-angular #agGrid style="width: 100%; height: 300px;" class="ag-theme-alpine"
                         [gridOptions]="importHistoryGridOptions" [columnDefs]="importHistoryColumnDefs"
                         [rowData]="importHistoryData" rowSelection="single"
                         (selectionChanged)="onImportHistoryRowSelected()"
                         [suppressContextMenu]="true" (gridReady)="onImportGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>

  <p-footer>
    <button pButton (click)="exportImportLogsToExcel()" [disabled]="!importHistoryData?.length"
            label="Export to Excel" icon="far fa-file-excel">
    </button>
    <button pButton (click)="downloadImportLogs()" label="Download" icon="fas fa-download"
            *ngIf="canExportLogs"
            [disabled]="selectedImportRows?.length !== 1 || (exportImportLogsSubscription && !exportImportLogsSubscription?.closed)">
    </button>
    <button pButton class="p-button-secondary" data-dismiss="modal" (click)="showImportHistory=false"
            label="Close"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Sync History" [(visible)]="showComImportHistory" class="ui-dialog-fullwidth" modal="true"
          [draggable]=true [resizable]="false" *ngIf="showCombinedHistory">
  <div>
    <div class="margin-top-5">
      <div class="flex" style="gap: .5rem;">
        <div class="flex" style="align-items: center;gap: .5rem;">
          <label id="startDateComLabel">Start Date: </label>
          <p-calendar name="startDateCom" [showIcon]="true" [monthNavigator]="true" placeholder="Select Start Date"
                      appendTo="body"
                      [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="startDateCombine" [maxDate]="maxDate"
                      id="startDateCom"
                      (onSelect)="dateSelect($event,'start')" [readonlyInput]="true" iconAriaLabel="Select start date"
                      ariaLabelledBy="startDateComLabel"></p-calendar>
        </div>
        <div class="flex" style="align-items: center;gap: .5rem;">
          <label id="endDateComLabel">End Date: </label>
          <p-calendar name="endDateCom" [showIcon]="true" [monthNavigator]="true" placeholder="Select End Date"
                      appendTo="body"
                      [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="endDateCombine" [maxDate]="maxDate"
                      id="endDateCom"
                      (onSelect)="dateSelect($event,'end')" [readonlyInput]="true" iconAriaLabel="Select end date"
                      ariaLabelledBy="endDateComLabel"></p-calendar>
        </div>
        <div class="text-left">
          <button pButton type="button" (click)="viewImportHistoryData_9746()" [disabled]="validateStartAndEndDates_9746()"
                  label="View"></button>
        </div>
      </div>
      <div class="margin-top-05">
        <p-tabView (onChange)="onChange($event)">
          <p-tabPanel header="Import History" tabName="ImportHistory">
              <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 400px;"
                               [gridOptions]="importHistoryGridOptions" [columnDefs]="importHistoryColumnDefs"
                               [rowData]="importHistoryData" rowSelection="single"
                               (selectionChanged)="onImportHistoryRowSelected()"
                               [suppressContextMenu]="true" (gridReady)="onImportGridReady($event)">
              </ag-grid-angular>
          </p-tabPanel>
          <p-tabPanel header="Reverse Sync History" tabName="ReverseSyncHistory">
            <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 400px;"
                             (gridReady)="onReverseSyncGridReady($event)" rowSelection="multiple"
                             [gridOptions]="reverseSyncGridOptions" [columnDefs]="reverseSyncColumnDefs"
                             [rowData]="reverseSyncHistoryData"
                             (rowSelected)="onReverseSyncRowSelected()"
                             [suppressContextMenu]="true">
            </ag-grid-angular>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>

  <p-footer>
    <button pButton (click)="exportSyncHistoryToExcel()" [disabled]="(currentTab === 'ImportHistory' && !importHistoryData?.length) || (currentTab === 'ReverseSyncHistory' && !reverseSyncHistoryData?.length)"
            label="Export to Excel" icon="far fa-file-excel">
    </button>
    <button pButton (click)="downloadSyncHistory()" label="Download" icon="fas fa-download"
            *ngIf="canExportLogs"
            [disabled]="((currentTab === 'ImportHistory' && (selectedImportRows?.length !== 1 ||(exportImportLogsSubscription && !exportImportLogsSubscription?.closed)))
            || (currentTab === 'ReverseSyncHistory' && (!selectedReverseSyncRows?.length || (exportReverseSyncLogsSubscription && !exportReverseSyncLogsSubscription?.closed))))">
    </button>
    <button pButton class="p-button-secondary" data-dismiss="modal" (click)="showComImportHistory=false"
            label="Close"></button>
  </p-footer>
</p-dialog>
