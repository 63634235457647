import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { TagType } from '../../../shared/tags';
import { PortfolioEditorService } from './portfolio-editor.service';
import { Utils as Util } from '../../../core/functions';
import * as Consts from '../../../libs/app.constants';
import { AnalyticsStatus } from '../../../libs/app.constants';
import { IAnalyticsNotification } from '../../../models/notification';
import { EntityType as NoteEntityType, EntityType } from '../../../libs/preference.enums';
import { forkJoin, Subject, switchMap, takeUntil, filter } from 'rxjs';
import { PreferenceService } from '../../../services/preference.service';
import { IModel } from '../../../models/modeling/model';
import { ModelService } from '../../../services/model.service';
import { IIdName } from '../../../models/tom';
import { IPreferences } from '../../../models/preferences/preference';
import { INote } from '../../../models/notes';

@Component({
  selector: 'eclipse-portfolio-editor-summary',
  templateUrl: './portfolio-summary.component.html',
  styleUrls: ['./portfolio-summary.component.scss']
})
export class PortfolioSummaryComponent extends BaseComponent {
  public readonly tacticalToolPrivilege: boolean = Util.hasTradeToolRight(Consts.PRIV_TACTICAL);
  public readonly portfolioEditPermission: boolean = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;
  public readonly portfolioPreferencePermission: boolean = !!Util.getPermission(Consts.PRIV_PORTFOLIOPREF)?.canRead;

  public noteEntityType = NoteEntityType;
  public tagTypes = TagType;
  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public readonly MAX_NOTES_TO_DISPLAY: number = 8;
  public maxCapGainAmountPreference: IPreferences;
  public modelSuggestions: IModel[] = [];
  private destroyed$: Subject<void> = new Subject<void>();
  private readonly _preferenceService: PreferenceService = inject(PreferenceService);
  private readonly _modelService: ModelService = inject(ModelService);
  public canReadNotes: boolean = !!Util.getPermission(Consts.PRIV_NOTES)?.canRead;
  public displayNotes: INote[] = [];
  public displayTeamNotes: INote[] = [];

  constructor() {
    super();
    this.portfolioEditorService.portfolio$
      .pipe(
        takeUntil(this.destroyed$),
        filter(p => !!p.id),
        switchMap((p) => forkJoin({
          preferences: this._preferenceService.getpreferencesForLevel('Portfolio', p.id)
        }))
      )
      .subscribe(({preferences}) => {
        this.maxCapGainAmountPreference = preferences.preferences.find(pref => pref.name === 'maxGainAmount');
      });

    this.portfolioEditorService.portfolioNotes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notes => {
        this.displayNotes = this.sortNotes(notes.filter(n => n.displayNote));
      });

    this.portfolioEditorService.teamNotes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notes => {
        this.displayTeamNotes = this.sortNotes(notes.filter(n => n.displayNote));
      });
  }

  private sortNotes(notesToSort: INote[]): INote[] {
    return notesToSort.sort((a, b) => {
      if (a.startDate > b.startDate) {
        return -1;
      } else if (a.startDate < b.startDate) {
        return 1;
      }
      return 0;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refreshData(analyticsNotification: IAnalyticsNotification): void {
    // this.isAnalyticsRan = true; // TODO JN needed for refreshing mac weights ?
    if (!analyticsNotification?.portfolioFlags?.length) {
      return;
    }
    const portfolio = analyticsNotification.portfolioFlags.find(flag => flag.portfolioId === +this.portfolioEditorService.portfolio?.id);
    if (portfolio && (portfolio.needAnalytics !== AnalyticsStatus.Running || analyticsNotification.isAnalyticsCompleted)) {
      if (!!this.portfolioEditorService.portfolio?.id) {
        this.portfolioEditorService.portfolioId$.next(this.portfolioEditorService.portfolio?.id);
        //this.getPortfolioDetailsById(this.portfolioId);
        //this.getOriginalAndSubstitutedModelDetailsByPortfolioId();
      } else {
        //this.getTeams(true);
      }
    } else if (portfolio && portfolio.needAnalytics === AnalyticsStatus.Running) {
      // this.portfolio.needAnalyticsStatus = Util.getNeedAnalyticsStatus(AnalyticsStatus.Running);
    }
  }

  onSelectedTeamsChanged() {
    this.portfolioEditorService.portfolio.teams = this.portfolioEditorService.teams.filter(team => this.portfolioEditorService.portfolio.teamIds.includes(team.id));
    if (this.portfolioEditorService.portfolio.primaryTeamId
      && !this.portfolioEditorService.portfolio.teamIds.includes(this.portfolioEditorService.portfolio.primaryTeamId)) {
      this.portfolioEditorService.portfolio.primaryTeamId = null;
    }
  }

  searchModels(event: any): void {
    this._modelService.getModelSearch(event.query.toLowerCase())
      .subscribe((models: IModel[]) => {
        this.modelSuggestions = models.filter(model => !model.isDeleted && model.statusId === Consts.modelStatusEnum.APPROVED);
        this.formatModelSuggestion(this.modelSuggestions, event);
      });
  }

  onModelSelect(model: IIdName) {
    console.log('new model', model);
    this.portfolioEditorService.portfolio.modelId = model.id;
    this.portfolioEditorService.portfolio.modelName = model.name;
    this.portfolioEditorService.portfolio.model = model;
    /*this.selectedModelObject = model;
    this.isAssignModel = true; // assign model flag
    // eslint-disable-next-line eqeqeq
    if ((this.initialModelId != model.id) && (this.securityWeightingComponent && this.securityWeightingComponent.macStatus === Consts.MACWeightingStatus.Portfolio.value)) {
      this.displayMacPopup = true; // Show MAC Weightings Confirm popup
    } else {
      this.onModelSelectConfirm(this.selectedModelObject, assignModelToPortfolio);
      this.showMACWarningForNewlyAssignedModel(model.isMAC);
    }*/
  }

  showModelClearConfirm: boolean = false;
  modelCleared() {
    this.showModelClearConfirm = true;
  }

  confirmModelClear() {
    this.portfolioEditorService.portfolio.model = null;
    this.portfolioEditorService.portfolio.modelId = null;
    this.portfolioEditorService.portfolio.modelName = null;
  }
}
