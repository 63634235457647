import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { EMPTY, Observable } from 'rxjs';
import { IAssignPortfolioToModel } from '../models/modeling/assignportfoliotomodel';
import { IAsideCash } from '../models/account';
import {
  IPortfolio,
  PortfolioCashDetails,
  IPortfolioTree,
  ISleeveContributionMethod,
  ISleeveDistributionMethod,
  PortfolioGainLossSummary
} from '../models/portfolio';
import { IAccount, IPortfolioDetails, IToken } from '../models/portfolio';
import { IDynamicGridViewState } from '../shared/gridextensions/dynamic-column-filter.component';
import * as Constants from '../libs/app.constants';
import { RELATED_TYPE } from "../libs/app.constants";
import { ConfigService } from '../config/config.service';
import { LoginAsService } from '../services/loginas.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  private _portfolioEndPoint = 'v1/portfolio/portfolios';
  private _portfolioEndPointV2 = 'v2/portfolio/portfolios';
  private portfolioDashboardEndPoint = 'v1/dashboard/portfolio/summary';
  private _accountsByFilterEndPoint = 'v1/account/accounts';
  private _portfolioSimpleEndPoint = 'v1/portfolio/portfolios/simple';
  private _portfolioSimpleEndPointV2 = 'v2/portfolio/portfolios/GetPortfolioSearch';
  private _portfoilioSleeveEndPoint = 'v1/portfolio/sleeves';
  private _portfoilioSleeveEndPointV2 = 'v2/portfolio/sleeves';
  private _portfolioAuditEndPoint = 'v1/auditlog/portfolio/';
  private _portfolioAccountsSimpleListEndPoint = 'v1/portfolio/portfolios/accounts/simple/list';
  private _runPortfolioAnalysis = 'v1/postimport/run_portfolio_analysis';
  private _notesHistoryEndPoint = 'v2/notes/history';
  private _dataImportEndoint = 'v2/DataImport';
  private _portfolioCashDetailsEndPointV2 = 'v2/portfolio/portfolios/cashdetails';

  constructor(
    private _httpClient: OEHttpClient,
    private _loginAsService: LoginAsService) { }

  /** Get team portfolios data for auto complete */
  getPortfolioSearch(searchString: string, includeDisbaled: boolean = false, includeSleeves: boolean = false) {
    searchString = searchString.trim();
    if (searchString === '') {
      return EMPTY;
    }

    let searchendpoint = `${this._portfolioSimpleEndPointV2}?includevalue=true&searchAccounts=true&search=${searchString}`;
    if (includeDisbaled) {
      searchendpoint = `${searchendpoint}&includeDisabled=true`;
    }
    if (includeSleeves) {
      searchendpoint = `${searchendpoint}&includeSleeves=true`;
    }
    return this._httpClient.getData(searchendpoint);
  }

  getSimplePortfolioList() {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/simple`);
  }

  /** Get all portfolios list */
  getPortfolios(portfolioFilterId: number = 0) {
    return (portfolioFilterId === 0)
      ? this._httpClient.getData(`${this._portfolioEndPointV2}/getPortfolioList`)
      : this._httpClient.getData(`${this._portfolioEndPointV2}/getPortfolioList?filter=${portfolioFilterId}`);
  }

  getPortfoliosDynamic(state: IDynamicGridViewState, filterTypeId?: number): Observable<IPortfolio[]> {
    let url = `${this._portfolioEndPointV2}/list`;
    const params = [];
    if (!!filterTypeId) {
      params.push(`filterId=${filterTypeId}`);
    }
    if (params.length) {
      url = `${url}?${params.join('&')}`;
    }
    return this._httpClient.postData(url, state);
  }

  getPortfolioListFormat(portfolioId: number = 0) {
    return this._httpClient.getData(`${this._portfolioEndPoint}?id=${portfolioId}`);
  }

  getUserPortfolioIds() {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/getUserPortfolioIds`);
  }

  /** Get portfolio accounts based on portfolio Id */
  getPortfolioAccounts(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${portfolioId}/accounts`);
  }

  /** Get portfolio accounts based on portfolio Id */
  getAccountsSimpleListByPortfolioIds(portfolioIds: number[]) {
    return this._httpClient.postData(`${this._portfolioAccountsSimpleListEndPoint}?includevalue=true&excludeNoAccountPortfolio=true&excludeNeedAnalytics=true&excludeDoNotTrade=true`, { ids: portfolioIds });
  }

  /** Get portfolio accounts count summary */
  getPortfolioAccountsCountSummary(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/accounts/summary`);
  }

  /** To delete portfolio */
  deletePortfolio(portfolioId: number) {
    return this._httpClient.deleteData(`${this._portfolioEndPoint}/${portfolioId}`);
  }

  deletePortfolios(portfolioIds: { ids: number[] }) {
    return this._httpClient.deleteData(`${this._portfolioEndPoint}`, portfolioIds);
  }

  /** Get dashboard data */
  getPortfolioDashboardData() {
    return this._httpClient.getData(this.portfolioDashboardEndPoint);
  }

  /** load portfolio details by portfolio id */
  getPortfolioById(portfolioId: number): Observable<IPortfolioDetails> {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${portfolioId}`);
  }

  getPortfolioSummary(portfolioId: number): Observable<any> {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/summary/${portfolioId}`);
  }

  /** load portfolioregularaccount details by portfolio id */
  getSleevedAccountsById(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/accounts/sleeved`);
  }

  /** To create Portfolio */
  createPortfolio(portfolio: any): Observable<any> {
    return this._httpClient.postData(this._portfolioEndPoint, portfolio);
  }

  /** To update Portfolio */
  updatePortfolio(portfolioId: number, portfolio: any) {
    return this._httpClient.updateData(`${this._portfolioEndPoint}/${portfolioId}`, portfolio);
  }

  /** To update portfolio accounts */
  updateAccounts(portfolioId: number, accountIds: any) {
    return this._httpClient.updateData(`${this._portfolioEndPoint}/${portfolioId}/accounts`, accountIds);
  }

  /*** Get All Accounts data */
  getAllAccounts(id: number) {
    return this._httpClient.getData(`${this._accountsByFilterEndPoint}?filterId=${id}`);
  }

  /** Search by name for All Portfolios in Portfolio Assignment */
  searchPortfolio(name: string) {
    name = name.trim();
    if (name === '') {
      return EMPTY;
    }

    return this._httpClient.getData(`${this._portfolioEndPoint}/simple?includevalue=true&searchAccounts=true&search=${name}`);
  }

  /** Search by name or id to get id, name, value*/
  searchPortfolioSimple(name: any, excludeDoNotTrade: boolean = false, isFromViewPortfolio: boolean = false) {
    name = name.trim();
    if (name === '') {
      return EMPTY;
    } else if (isFromViewPortfolio) {
      return this._httpClient.getData(`${this._portfolioSimpleEndPointV2}?includeSleeves=true&includevalue=true&searchAccounts=true&search=${name}&includeDisabled=true&excludeDoNotTrade=${excludeDoNotTrade}`);
    } else {
      return this._httpClient.getData(`${this._portfolioSimpleEndPointV2}?includeSleeves=true&includeValue=true&searchAccounts=true&search=${name}&excludeNeedAnalytics=true&excludeNoAccountPortfolio=true&excludeDoNotTrade=${excludeDoNotTrade}`);
    }
  }

  /**Search by HouseholdIds for Find By Orion HouseHold ID in Portfolio Assignment */
  searchHousehold(id: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}?householdIds=${id}`);
  }

  /** To get portfolio status */
  getPortfolioFilters() {
    return this._httpClient.getData(`${this._portfolioEndPoint}/portfolioFilters`);
  }

  /** Assgin Portfolio to Acconts */
  assignAccounts(id: number, accountIds: any) {
    return this._httpClient.postData(`${this._portfolioEndPoint}/${id}/accounts`, accountIds);
  }

  /** Get New Portfolio */
  getNewPortfolios(searchKey: string = '') {
    if (searchKey !== '' && searchKey !== undefined) {
      searchKey = `?search=${searchKey}`;
    }

    return this._httpClient.getData(`${this._portfolioEndPoint}/new${searchKey || ''}`);
  }

  /** get Sleeved accounts for autocomplete with accountNumber/portfolioName/accountNumber */
  searchportfolioSleeveAccounts(searchString: string) {
    return this._httpClient.getData(`${this._portfoilioSleeveEndPoint}?search=${searchString}`);
  }

  /** Gets Allocations for selected Sleeved account associated with a model */
  getSleevedAccountAllocation(sleevedAccountId: number) {
    return this._httpClient.getData(`${this._portfoilioSleeveEndPointV2}/${sleevedAccountId}/allocations`);
  }

  /** To get the nodes from model for portfolio*/
  getModelNodesOfPortfolio(portfolioId: number, isSpendCash: boolean) {
    return (isSpendCash) ?
      this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/Model/nodes?includeBuyEmphasisNodeId=true`) :
      this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/Model/nodes?includeSellEmphasisNodeId=true`);
  }

  /** To get portfolio contribution amount */
  getPortfolioContributionAmount(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/contributionamount`);
  }

  /** To get portfolio contribution pending amount */
  getSpendCashMaxAmountByPortfolioId(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/spendCashMaxAmount`);
  }


  /** To get portfolio contribution pending amount */
  getSpendCashMaxAmountForPortfolios(portfolioIds: number[]) {
    return this._httpClient.postData(`${this._portfolioEndPoint}/spendCashMaxAmount`, { ids: portfolioIds });
  }
  /** To get portfolio managedValue amount */
  getPortfolioManagedValue(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/managedValue`);
  }

  /** Search by name or id to get id, name, value*/
  searchSleevePortfolioSimple(name: any, excludeDoNotTrade: boolean = false) {
    name = name.trim();
    if (name === '') {
      return EMPTY;
    }

    return this._httpClient.getData(`${this._portfolioSimpleEndPoint}?excludeNeedAnalytics=true&searchAccounts=true&inSleeve=true&includevalue=true&search=${name}&excludeDoNotTrade=${excludeDoNotTrade}`);
  }

  /** To get portfolio audit history */
  getPortfolioAuditHistory(portfolioId: number, startDateParam, endDateParam) {
    return this._httpClient.getData(`${this._portfolioAuditEndPoint + portfolioId}?toDate=${endDateParam}&fromDate=${startDateParam}`);
  }

  /** To get portfolio substitutions history */
  getPortfolioSubstitutionsHistory(portfolioId: number, startDateParam, endDateParam) {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${portfolioId}/PortfolioSubstitutionsHistory?toDate=${endDateParam}&fromDate=${startDateParam}`);
  }

  /** Get Portfolio Notes History*/
  getNotesHistory(portfolioId: number, startDateParam, endDateParam) {
    return this._httpClient.getData(`${this._notesHistoryEndPoint}?relatedType=${RELATED_TYPE.PORTFOLIO}&relatedId=${portfolioId}&toDate=${endDateParam}&fromDate=${startDateParam}`);
  }

  /** To get portfolio MAC history */
  getPortfolioMacHistory(portfolioId: number, startDateParam, endDateParam) {
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${portfolioId}/MacHistory?toDate=${endDateParam}&fromDate=${startDateParam}`);
  }

  /*Simple portfolio search for sleeved and non-sleeved
 @param: name-string-search word
 @param: inSleeve-boolean-true for sleeved portfolios and false for non-sleeved portfolios.
  */
  searchPortfolioForSleevedAndNonSleeved(name: string, inSleeve: boolean) {
    name = name.trim();
    if (name === '') {
      return EMPTY;
    }
    return this._httpClient.getData(`${this._portfolioEndPoint}/simple?includevalue=true&searchAccounts=true&inSleeve=${inSleeve}&search=${name}`);
  }

  /** Search by name or id to get id, name, value*/
  GetPortfolioListApprovedAndBalancedModel(name: any) {
    name = name.trim();
    if (name === '') {
      return EMPTY;
    } else {
      return this._httpClient.getData(`${this._portfolioSimpleEndPoint}?approvedModel=true&searchAccounts=true&search=${name}`);
    }
  }

  /**
   * Get Simple portfolio list based on list of portfolios ids
   * @param selectedIds
   */
  getPortfolioSimpleListByIds(selectedIds: number[]) {
    return this._httpClient.postData(`${this._portfolioSimpleEndPoint}/list?includevalue=true&excludeNoAccountPortfolio=true&excludeNeedAnalytics=true&excludeDoNotTrade=true`, { ids: selectedIds });
  }

  /** To run Portfolio analysis*/
  runPortfolioAnalysis(ids: any[], preferenceLevel: string = Constants.PREFERENCE_LEVEL.PORTFOLIOS) {
    return this._httpClient.postData(this._runPortfolioAnalysis, { ids: ids, preferenceLevel: preferenceLevel });
  }

  /** To run Portfolio analysis with trigger*/
  runPortfolioAnalysisByTrigger(ids: number[], trigger: string, preferenceLevel: string = Constants.PREFERENCE_LEVEL.PORTFOLIOS) {
    return this._httpClient.postData(`${this._runPortfolioAnalysis}?trigger=${trigger}`, { ids: ids, preferenceLevel: preferenceLevel });
  }

  /** To get Portfolio flag*/
  getPortfolioFlag(portfolioIds: number[]) {
    return this._httpClient.postData(`${this._portfolioEndPoint}/portfolioFlag`, { portfolioIds: portfolioIds });
  }

  /** assign model to portfolio */
  assignModelToPortfolio(assignPortfolio: IAssignPortfolioToModel, modelId: number) {
    return this._httpClient.postData(`${this._portfolioEndPoint}/${modelId}/portfolios`, assignPortfolio);
  }

  patchPortfolio(portfolioId: number, patchData: { op: string; path: string; value: any }[]): Observable<any> {
    return this._httpClient.patchData(`${this._portfolioEndPointV2}/${portfolioId}`, patchData);
  }

  /**Add Aside Cash */
  addAsideCash(id: number, asideCash: IAsideCash) {
    return this._httpClient.postData(`${this._portfolioEndPoint}/${id}/asideCash`, asideCash);
  }

  /** Get portfolio Aside cash for Grid */
  getPortfolioAsideCash(id: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${id}/asideCash`);
  }

  /** Get Aside Details by id */
  getAsideDetailsById(id: number, asideCashId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${id}/asideCash/${asideCashId}`);
  }

  /** Delete Aside Details by id */
  deleteAsideDetailsById(id: number, asideCashId: number) {
    return this._httpClient.deleteData(`${this._portfolioEndPoint}/${id}/asideCash/${asideCashId}`);
  }

  /** Gets Sleeve Distribution Methods */
  getSleeveDistributionMethods(): Observable<ISleeveDistributionMethod[]> {
    return this._httpClient.getData(`${this._portfoilioSleeveEndPointV2}/SleeveDistributionMethods`);
  }

  /** Gets Sleeve Contribution Methods */
  getSleeveContributionMethods(): Observable<ISleeveContributionMethod[]> {
    return this._httpClient.getData(`${this._portfoilioSleeveEndPointV2}/SleeveContributionMethods`);
  }

  /**
   * get available levels in a model which is assigned to given portfolio
   * @portfolioIds: ids of the given portfolios
   */
  getPortfolioLevels(portfolioIds: number[]) {
    return this._httpClient.postData(`${this._portfolioEndPoint}/levels`, { portfolioIds });
  }

  /**
   * run the reverse sync for a list of portfolios
   * @portfolioIds: ids of the given portfolios
   */
  runReverseSyncPortfolios(portfolioIds: number[]) {
    return this._httpClient.postData(`${this._portfolioEndPointV2}/Action/ReverseSyncPortfolioAssignments`, portfolioIds);
  }

  runSyncAccountsFromOrionByPortfolioId(portfolioIds: number[]){
    return this._httpClient.postData( `${this._dataImportEndoint}/Action/SyncAccountsByPortfolio`, portfolioIds);
  }

  /**
   * Get the summary hierarchy of the portfolio and its accounts.
   * @param portfolioId
   */
  getPortfolioTree(portfolioId?: number, accountId?: number): Observable<IPortfolioTree> {
    let args = [];
    if (portfolioId) {
      args.push(`portfolioId=${portfolioId}`);
    } else if (accountId) {
      args.push(`accountId=${accountId}`);
    }
    return this._httpClient.getData(`${this._portfolioEndPointV2}/PortfolioTree?${args.join('&')}`);
  }

  /** Given one or more registration IDs, queries Orion Connect for a Session ID
   * @param {number} registrationIds The registration IDs for which the Session ID is desired.
   * @returns {Observable<number>} The Session ID from Orion Connect.
   */
  getSessionIdFromRegistrationIds(registrationIds: number[], token: string): Observable<number> {
    const registrationEndpoint = ConfigService.settings.orionAdvisorEndPoint +
      'api/v1/Portfolio/SleeveTransfer/EntitySession/Registration';

    return this._httpClient.postDataToOrionConnectUri(registrationEndpoint, token, registrationIds);
  }

  /**
   * Returns the URI of the Orion Connect Sleeve Transfer Tool for given portfolio ID.
   * @param portfolioId The ID of the portfolio to open in the Orion Connect Sleeve Transfer Tool.
   * @returns The URI of the Orion Connect Sleeve Transfer Tool.
   */
  getOCSleeveTransferUri(portfolioId: number): Observable<string> {
    if (portfolioId <= 0) {
      return EMPTY;
    }

    return new Observable(subscriber => {
      this.getPortfolioAccounts(portfolioId)
        .subscribe((accounts: IAccount[]) => {
          if (!accounts || !accounts.length) { return EMPTY; }

          const accountOrionFirmId = accounts[0].ocFirmId;

          // Request a new token because we can't determine what firm the token is for.
          this._loginAsService.getFirmToken(accountOrionFirmId)
            .subscribe((token: IToken) => {
              this.getPortfolioById(portfolioId)
                .subscribe((portfolio: IPortfolioDetails) => {
                  this.getSessionIdFromRegistrationIds([portfolio.general.registrationId], token.orion_access_token)
                    .subscribe((sessionId: number) => {
                      const connectTransferUri = ConfigService.settings.orionAdvisorEndPoint +
                        'orionconnectapp/integration.html?p=/portfolio/sleeve/transfer/' + sessionId +
                        '/?m=crm&t=' + token.orion_access_token;

                      subscriber.next(connectTransferUri);
                      subscriber.complete();
                    });
                });
            });
        });
    });
  }

  /**
   * Returns the URI of the Orion Connect Sleeve Transfer Tool for given list of registration ids and OC firm id.
   * @param registrationIds the registration ids to get the uri for
   * @param orionFirmId The OC firm id to create the uri for
   * @returns The URI of the Orion Connect Sleeve Transfer Tool.
   */
  getOCSleeveTransferUriForRegistrations(registrationIds: number[], orionFirmId: number){

    if(registrationIds.length <= 0){
      return EMPTY;
    }

    return new Observable(transferUri => {
      this._loginAsService.getFirmToken(orionFirmId)
        .subscribe((token: IToken) => {
          this.getSessionIdFromRegistrationIds(registrationIds, token.orion_access_token)
            .subscribe((sessionId: number) => {
              const connectTransferBaseUri = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/integration.html`;
              const connectTransferUri = `${connectTransferBaseUri}?p=/portfolio/sleeve/transfer/${sessionId}/?m=crm&t=${token.orion_access_token}`;

              transferUri.next(connectTransferUri);
              transferUri.complete();
            });
        });
    });
  }

  /** Get portfolio accounts based on portfolio Id */
  getGainLossSummary(portfolioId: number) : Observable<PortfolioGainLossSummary>{
    return this._httpClient.getData(`${this._portfolioEndPointV2}/${portfolioId}/gainLossSummary`);
  }

  getPortfolioCashDetails(portfolioId: number = 0): Observable<PortfolioCashDetails> {
    return this._httpClient.getData(`${this._portfolioCashDetailsEndPointV2}/${portfolioId}`);
  }
}
