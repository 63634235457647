<div class="page-header-row" #summaryroot>
  <div>
    <h3>Summary</h3>
    <div class="flex m-t-5" style="gap: 10px; align-items: center;"
         *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingMeta">
      <eclipse-analytics-duration
        [portfolioAnalytics]="portfolio.analytics"
        (updateAnalyticsData)="refreshData($event)">
      </eclipse-analytics-duration>
      <!--<p-chip styleClass="errorAlt" label="Has Data Errors" icon="fas fa-octagon-xmark"></p-chip>-->
      <p-chip *ngIf="portfolio.isDisabled" styleClass="errorAlt" label="Disabled"
              icon="fas fa-octagon-xmark"></p-chip>
      <!--<p-chip styleClass="errorAlt" label="Model Assignment Pending Approval"
              icon="fas fa-octagon-xmark"></p-chip>-->
    </div>
    <ng-template #loadingMeta>
      <div class="flex m-t-5" style="gap: 10px; align-items: center;">
        <p-skeleton width="10rem" height="1.55rem"></p-skeleton>
        <p-skeleton width="10rem" height="1.55rem"></p-skeleton>
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="portfolioEditorService.portfolio$ | async as portfolio">
    <button pButton class="p-button-secondary" label="Open In Tactical Tool" icon="fas fa-arrow-up-right"
            (click)="portfolioEditorService.navigateToTradeTool('Tactical')"
            iconPos="right" *ngIf="tacticalToolPrivilege"></button>
  </ng-container>
</div>

<div class="section-row" *ngIf="portfolioEditorService.portfolioId$ | async">
  <div class="section-column">
    <p-card header="">
      <div class="summary-content-grid"
           *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingTolerance">
        <div class="summary-box" id="tolerance">
          <div class="summary-box-item">
            <span class="summary-label">Portfolio Tolerance</span>
            <ng-container *ngIf="portfolio.outOfTolerance">
              <strong class="text-danger" pTooltip="Out of Tolerance">OOT</strong><i pTooltip="Out of Tolerance" class="fas fa-exclamation-triangle text-warning ml-2"></i>
            </ng-container>
            <ng-container *ngIf="!portfolio.outOfTolerance">
              <strong class="text-success" pTooltip="In Tolerance">In Tolerance<i pTooltip="In Tolerance" class="fas fa-check ml-2"></i></strong>
            </ng-container>
          </div>
          <div class="summary-box-item" style="gap:2rem;" [ngSwitch]="portfolio.isSleevePortfolio" *ngIf="portfolio.showDeviationSection">
            <ng-container *ngSwitchCase="true">
              <div *ngIf="!!portfolio.sleeveDeviationPercent" class="flex flex-column">
                <span class="summary-label">Sleeve Strategy</span>
                <span class="summary-label">Deviation</span>
                <span>??</span>
              </div>
              <div *ngIf="!!portfolio.sleeveDeviationPercent" class="flex flex-column">
                <span class="summary-label">Sleeves</span>
                <span class="summary-label">Deviation</span>
                <span>{{ portfolio.sleeveDeviationPercent | percent:'1.0-2' }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <div class="flex-column">
                <div class="flex" style="gap: 1.5rem;">
                  <div *ngIf="!!portfolio.categoryDeviationPercent" class="flex flex-column">
                    <span class="summary-label">Category</span>
                    <span class="summary-label">Deviation</span>
                    <span>{{ portfolio.categoryDeviationPercent | percent:'1.0-2' }}</span>
                  </div>
                  <div *ngIf="!!portfolio.classDeviationPercent" class="flex flex-column">
                    <span class="summary-label">Class</span>
                    <span class="summary-label">Deviation</span>
                    <span>{{ portfolio.classDeviationPercent | percent:'1.0-2' }}</span>
                  </div>
                  <div *ngIf="!!portfolio.subclassDeviationPercent" class="flex flex-column">
                    <span class="summary-label">Subclass</span>
                    <span class="summary-label">Deviation</span>
                    <span>{{ portfolio.subclassDeviationPercent | percent:'1.0-2' }}</span>
                  </div>
                  <div *ngIf="!!portfolio.securitySetDeviationPercent" class="flex flex-column">
                    <span class="summary-label">Security Set</span>
                    <span class="summary-label">Deviation</span>
                    <span>{{ portfolio.securitySetDeviationPercent | percent:'1.0-2' }}</span>
                  </div>
                  <div *ngIf="!!portfolio.securityDeviationPercent" class="flex flex-column">
                    <span class="summary-label">Security</span>
                    <span class="summary-label">Deviation</span>
                    <span>{{ portfolio.securityDeviationPercent | percent:'1.0-2' }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="summary-box-footer">
            <a (click)="portfolioEditorService.navigateToTradeTool('Rebalancer')" *ngIf="portfolioEditorService.rebalancerToolVisible$ | async">
              Rebalance <i class="fas fa-arrow-up-right"></i>
            </a>
          </div>
        </div>
        <div class="summary-box" id="cash-need">
          <div class="summary-box-item">
            <span class="summary-label">Portfolio Cash Tolerance</span>
            <ng-container *ngIf="portfolio.cashOutOfTolerance">
              <strong class="text-danger" pTooltip="Out of Tolerance">OOT</strong><i pTooltip="Out of Tolerance" class="fas fa-exclamation-triangle text-warning ml-2"></i>
            </ng-container>
            <ng-container *ngIf="!portfolio.cashOutOfTolerance">
              <strong class="text-success" pTooltip="In Tolerance">In Tolerance<i pTooltip="In Tolerance" class="fas fa-check ml-2"></i></strong>
            </ng-container>
          </div>
          <div class="summary-box-item">
            <span class="summary-label">Cash Need Amount</span>
            <span>{{ portfolio.cashNeed | currency:'USD' }}
              / {{ portfolio.cashNeedPercentage | percent:'1.0-2' }}</span>
          </div>
          <div class="summary-box-footer">
            <a (click)="portfolioEditorService.navigateToTradeTool('CashNeeds')" *ngIf="portfolioEditorService.cashNeedToolVisible$ | async">
              Cash Need <i class="fas fa-arrow-up-right"></i>
            </a>
          </div>
        </div>
        <div class="summary-box" id="tlh">
          <div class="summary-box-item">
            <span class="summary-label">TLH Opportunity</span>
            <strong>{{ portfolio.hasTLHOpportunity ? 'Yes' : 'No' }}</strong>
          </div>
          <div class="summary-box-item">
            <span class="summary-label">Under Max Cap Gain
            <span
              *ngIf="maxCapGainAmountPreference?.value && maxCapGainAmountPreference.indicatorValue === '$'">({{ maxCapGainAmountPreference.value | currency:'USD' }})</span>
            <span
              *ngIf="maxCapGainAmountPreference?.value && maxCapGainAmountPreference.indicatorValue === '%'">({{ maxCapGainAmountPreference.value / 100 | percent:'1.0-2' }})</span>
            </span>
            <span *ngIf="portfolio.underMaxGainAmount !== null">{{ portfolio.underMaxGainAmount ? 'Yes' : 'No' }}</span>
            <span *ngIf="portfolio.underMaxGainAmount === null">N/A
              <i class="fas fa-question-circle m-l-5" pTooltip="Set the max cap gain in the portfolio preferences."
                 tooltipPosition="bottom"></i>
            </span>
          </div>
          <div class="summary-box-footer">
            <a (click)="portfolioEditorService.navigateToTradeTool('TLH')" *ngIf="portfolioEditorService.tlhToolVisible$ | async">
              TLH <i class="fas fa-arrow-up-right"></i>
            </a>
          </div>
        </div>
      </div>

      <ng-template #loadingTolerance>
        <div class="summary-content-grid">
          <div class="summary-box">
            <div class="summary-box-item">
              <p-skeleton height="6rem" width="20rem"></p-skeleton>
            </div>
          </div>
          <div class="summary-box">
            <div class="summary-box-item">
              <p-skeleton height="6rem" width="20rem"></p-skeleton>
            </div>
          </div>
          <div class="summary-box">
            <div class="summary-box-item">
              <p-skeleton height="6rem" width="20rem"></p-skeleton>
            </div>
          </div>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>

<h5 class="section-title">Details</h5>
<div class="section-row">
  <div class="section-column">
    <p-card>
      <div class="section-content-row" style="display: grid; grid-template-columns: minmax(0, 2fr) 1fr;"
           *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingDetails">
        <section class="section-content-column">
          <div class="content-inputs-column">
            <div>
              <label for="portfolio-name">Name</label>
              <input id="portfolio-name" pInputText required [disabled]="!portfolioEditPermission"
                     [(ngModel)]="portfolio.name"/>
            </div>
            <div>
              <label for="tags">Tags</label>
              <eclipse-tags id="tags" [(ngModel)]="portfolio.tags" ngDefaultControl [disabled]="!portfolioEditPermission"
                            [tagType]="tagTypes.Portfolio" ariaLabelledBy="tags"></eclipse-tags>
            </div>
            <div>
              <label id="model">Model</label>
              <div class="flex gap-1">
                <!-- disabled until model page integration is complete -->
                <p-autoComplete ariaLabelledBy="model" class="flex-1" [(ngModel)]="portfolio.model"
                                ngDefaultControl [suggestions]="modelSuggestions" placeholder="Search Model"
                                panelStyleClass="overlay-width-lg"
                                (completeMethod)="searchModels($event)" [minLength]="1" field="name"
                                [disabled]="true"
                                (onSelect)="onModelSelect($event)">
                  <ng-template let-selectedItem pTemplate="item">
                    <div class="flex align-items-center" style="gap: .5rem">
                      <i class="fas fa-flip-vertical fa-code-branch" aria-hidden="true"></i>
                      <span class="wrap">{{ selectedItem.displayFormat }}</span>
                    </div>
                  </ng-template>
                </p-autoComplete>
                  <button pButton label="Unassign" class="p-button-secondary" (click)="modelCleared()" [disabled]="true"></button>
              </div>
            </div>
            <div>
              <label id="teamsLabel" for="teams">Teams</label>
              <p-multiSelect class="form-multiSelect portfolio-team"
                             [disabled]="!portfolioEditPermission"
                             [options]="portfolioEditorService.teams$ | async"
                             name="team" id="teams" ariaLabelledBy="teamsLabel"
                             [(ngModel)]="portfolio.teamIds" ngDefaultControl defaultLabel="Choose Team"
                             optionLabel="name" optionValue="id"
                             (onChange)="onSelectedTeamsChanged()"
                             [resetFilterOnHide]="true" [appendTo]="summaryroot">
              </p-multiSelect>
            </div>
            <div>
              <label id="primary-team-label" for="primary-team">Primary Team</label>
              <p-dropdown id="primary-team" [options]="portfolio.teams" [disabled]="!portfolioEditPermission"
                          [(ngModel)]="portfolio.primaryTeamId" [required]="true"
                          placeholder="Choose Primary Team" ariaLabelledBy="primary-team-label"
                          optionLabel="name" optionValue="id"></p-dropdown>
            </div>
            <div>
              <label id="do-not-trade-label" for="do-not-trade">Do Not Trade</label>
              <p-inputSwitch styleClass="p-inputswitch-lg" id="do-not-trade" ariaLabel="Do Not Trade"
                             [(ngModel)]="portfolio.doNotTrade" [disabled]="!portfolioEditPermission"></p-inputSwitch>
            </div>
          </div>
          <a class="m-t-10 text-right" *ngIf="portfolioPreferencePermission && (portfolioEditorService.portfolio$ | async)?.id"
             [routerLink]="['/eclipse/admin/preferences/portfolio', (portfolioEditorService.portfolio$ | async)?.id]">View
            All Preferences <i class="fas fa-arrow-up-right"></i>
          </a>
        </section>
        <section class="section-content-column" *ngIf="portfolio?.id">
          <div class="content-data-column">
            <div style="display: flex;align-items: flex-start;align-self: stretch;">
              <div class="content-data-list">
                <div class="detail-field-box">
                  <label>Portfolio Type</label>
                  <span>{{ portfolio.isSleevePortfolio ? 'Sleeved' : 'Normal' }}</span>
                </div>
                <div class="detail-field-box-grid">
                  <div class="detail-field-box">
                    <label>Portfolio ID</label>
                    <span copyToClipboard>{{ portfolio.id }}</span>
                  </div>
                  <div class="detail-field-box" *ngIf="portfolio.isSleevePortfolio">
                    <label>Registration ID</label>
                    <span [copyToClipboard]="!!portfolio.registrationId">{{ portfolio.registrationId || 'N/A' }}</span>
                  </div>
                </div>
                <div class="detail-field-box" *ngIf="portfolio.isSleevePortfolio">
                  <label>Custodial Account Number</label>
                  <span
                    [copyToClipboard]="!!portfolio.custodialAccountNumber">{{ portfolio.custodialAccountNumber || 'N/A' }}</span>
                </div>
                <div class="detail-field-box" *ngIf="portfolio.isSleevePortfolio">
                  <label>Sleeve Strategy Aggregate Name</label>
                  <span
                    [copyToClipboard]="!!portfolio.sleeveStrategyAggregateName">{{ portfolio.sleeveStrategyAggregateName || 'N/A' }}</span>
                </div>
                <div class="detail-field-box" *ngIf="portfolio.isSleevePortfolio">
                  <label>Sleeve Strategy Deviation</label>
                  <span>{{ (portfolio.sleeveDeviationPercent | percent:'1.0-2') || 'N/A' }}</span>
                </div>
              </div>
            </div>
          </div>
          <!--          <a class="m-t-10 text-right" href="javascript:void(0)">View Sleeve Maintenance <i
                      class="fas fa-chevron-right"></i></a>-->
        </section>
      </div>
      <ng-template #loadingDetails>
        <div class="section-content-row">
          <section class="section-content-column" style="flex:2;">
            <div class="content-inputs-column">
              <div class="form-row">
                <label>
                  <p-skeleton height="1rem" width="4rem"></p-skeleton>
                </label>
                <p-skeleton styleClass="mb-2" height="2rem"></p-skeleton>
              </div>
              <div class="form-row">
                <label>
                  <p-skeleton height="1rem" width="4rem"></p-skeleton>
                </label>
                <p-skeleton styleClass="mb-2" height="2rem"></p-skeleton>
              </div>
              <div class="form-row">
                <label>
                  <p-skeleton height="1rem" width="4rem"></p-skeleton>
                </label>
                <p-skeleton styleClass="mb-2" height="2rem"></p-skeleton>
              </div>
              <div class="form-row">
                <label>
                  <p-skeleton height="1rem" width="4rem"></p-skeleton>
                </label>
                <p-skeleton styleClass="mb-2" height="2rem"></p-skeleton>
              </div>
              <div class="form-row">
                <label>
                  <p-skeleton height="1rem" width="4rem"></p-skeleton>
                </label>
                <p-skeleton styleClass="mb-2" height="2rem"></p-skeleton>
              </div>
            </div>
          </section>
          <section class="section-content-column" style="flex:1;">
            <div class="content-data-column">
              <div style="display: flex;align-items: flex-start;align-self: stretch;">
                <div class="content-data-list">
                  <div class="detail-field-box">
                    <label>
                      <p-skeleton height="1rem" width="8rem"></p-skeleton>
                    </label>
                    <p-skeleton styleClass="mb-2" height="1rem" width="3rem"></p-skeleton>
                  </div>
                  <div class="detail-field-box">
                    <label>
                      <p-skeleton height="1rem" width="8rem"></p-skeleton>
                    </label>
                    <p-skeleton styleClass="mb-2" height="1rem" width="3rem"></p-skeleton>
                  </div>
                  <div class="detail-field-box">
                    <label>
                      <p-skeleton height="1rem" width="8rem"></p-skeleton>
                    </label>
                    <p-skeleton styleClass="mb-2" height="1rem" width="3rem"></p-skeleton>
                  </div>
                  <div class="detail-field-box">
                    <label>
                      <p-skeleton height="1rem" width="8rem"></p-skeleton>
                    </label>
                    <p-skeleton styleClass="mb-2" height="1rem" width="3rem"></p-skeleton>
                  </div>
                  <div class="detail-field-box">
                    <label>
                      <p-skeleton height="1rem" width="8rem"></p-skeleton>
                    </label>
                    <p-skeleton styleClass="mb-2" height="1rem" width="3rem"></p-skeleton>
                  </div>
                </div>
              </div>
            </div>
            <a class="m-t-10 text-right" href="javascript:void(0)">View Sleeve Maintenance <i
              class="fas fa-chevron-right"></i></a>
          </section>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>

<ng-container *ngIf="canReadNotes && portfolioEditorService.portfolioId$ | async">
  <h5 class="section-title">Display Notes</h5>
  <div class="section-row">
    <div class="section-column">
      <p-card>
        <div style="display: flex; flex-direction: column; gap: 1rem;">
          <ng-container *ngIf="displayTeamNotes">
            <ng-container *ngIf="displayTeamNotes?.length">
              <h6>Team</h6>
              <div class="content-data-list display-notes-list">
                <div *ngFor="let note of displayTeamNotes | slice:0: MAX_NOTES_TO_DISPLAY" class="detail-field-box">
                    <div class="flex" style="flex: 1; justify-content: space-between;">
                      <label>{{ note.notes }}</label>
                    </div>
                    <div class="secondary-information">
                      <span>{{ note.startDate | date }}</span>
                      <span *ngIf="note.endDate">- {{ note.endDate | date }}</span>
                      <span>{{ note.createdByUser }}</span>
                    </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="displayNotes; else loadingNotes">
            <ng-container *ngIf="displayNotes?.length; else emptyNotes">
              <h6>Portfolio and Account</h6>
              <div class="content-data-list display-notes-list">
                <div *ngFor="let note of displayNotes | slice:0: MAX_NOTES_TO_DISPLAY" class="detail-field-box">
                    <div class="flex" style="flex: 1; justify-content: space-between;">
                      <label>{{ note.notes }}</label>
                    </div>
                    <div class="display-note-list-footer">
                      <div class="secondary-information">
                        <span>{{ note.startDate | date }}</span>
                        <span *ngIf="note.endDate">- {{ note.endDate | date }}</span>
                        <span>{{ note.createdByUser }}</span>
                      </div>
                      <ng-container>
                        <div *ngIf="note.relatedType === noteEntityType.Portfolio">
                          <i class="fal fa-cubes" aria-hidden="true"></i>
                          <span>Portfolio</span>
                        </div>
                        <div *ngIf="note.relatedType === noteEntityType.Account">
                          <i class="fas fa-briefcase" aria-hidden="true"></i>
                          <span>Account - {{ note.entityName }}</span>
                        </div>
                      </ng-container>
                    </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #emptyNotes>
            <h6>No Portfolio or Account Display Notes Found.</h6>
          </ng-template>
          <ng-template #loadingNotes>
            <div *ngFor="let _ of [].constructor(MAX_NOTES_TO_DISPLAY)" class="detail-field-box">
              <p-skeleton height="1rem" width="10rem"></p-skeleton>
              <p-skeleton styleClass="mb-2" height="1rem" width="4rem"></p-skeleton>
            </div>
          </ng-template>
        </div>
        <!--  <ng-template pTemplate="footer">
              <div class="card-footer" *ngIf="portfolioEditorService.notes$ | async as noteList">
                  <a class="m-t-10 text-right" href="javascript:void(0)"
                     *ngIf="noteList?.length > MAX_NOTES_TO_DISPLAY">View
                      All {{ noteList.length }} Notes <i
                              class="fas fa-chevron-right"></i></a>
              </div>
          </ng-template>-->
      </p-card>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="portfolioEditorService.portfolioId$ | async">
  <div class="section-row">
    <div class="section-column">
      <h5 class="section-title">Trading</h5>
      <p-card header="">
        <div class="section-content-column"
             *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingTrading">
          <div class="data-box">
            <span class="data-label">Pending Trades</span>
            <ng-container [ngSwitch]="portfolio.hasPendingTrades">
              <span *ngSwitchCase="false">No</span>
              <a *ngSwitchCase="true" [routerLink]="['/eclipse/tradeorder/pending/portfolio', portfolio.id]">Yes
                <i class="fas fa-arrow-up-right"></i>
              </a>
            </ng-container>
          </div>
          <div class="data-box">
            <span class="data-label">Last Trade Date</span>
            <span>{{ portfolio.lastTradedDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Last Rebalance Date</span>
            <span>{{ portfolio.lastRebalanceDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Last TLH Date</span>
            <span>{{ portfolio.lastTLHDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label-label">Auto Rebalance</span>
            <span>{{ portfolio.autoRebalance }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Has Unassigned Holdings</span>
            <span>{{ portfolio.hasUnassignedHoldings ? 'Yes' : 'No' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Has Substitutes</span>
            <span>{{ portfolio.hasSubstitutes ? 'Yes' : 'No' }}</span>
          </div>
        </div>
        <ng-template #loadingTrading>
          <div class="section-content-column">
            <div class="data-box">
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
            </div>
            <div class="data-box">
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
            </div>
            <div class="data-box">
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
            </div>
          </div>
        </ng-template>
        <!-- <ng-template pTemplate="footer">
             <div class="card-footer">
                 <a class="m-t-10 text-right" href="javascript:void(0)">
                     View Trade History <i class="fas fa-chevron-right"></i></a>
             </div>
         </ng-template>-->
      </p-card>

    </div>
    <div class="section-column">
      <h5 class="section-title">Cash</h5>
      <p-card header="">
        <div class="section-content-column"
             *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingCash">
          <div class="data-box">
            <span class="data-label">Contributions</span>
            <span>{{ portfolio.contribution | currency:'USD' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Distributions</span>
            <span>{{ portfolio.distribution | currency:'USD' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Current %</span>
            <span>{{ portfolio.cashPercent | percent:'1.0-2' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Target %</span>
            <span>{{ portfolio.cashTargetPercent | percent:'1.0-2' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Cash Need %</span>
            <span>{{ portfolio.cashTargetPercent - portfolio.cashPercent | percent:'1.0-2' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Set Aside Amount</span>
            <span>{{ portfolio.actualSetAsideCash | currency:'USD' }}</span>
          </div>
          <div class="data-box">
            <span class="data-label">Set Aside Target</span>
            <span>{{ portfolio.targetSetAsideCash | currency:'USD' }}</span>
          </div>
        </div>
        <ng-template #loadingCash>
          <div class="section-content-column">
            <div class="data-box">
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
            </div>
            <div class="data-box">
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
            </div>
            <div class="data-box">
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
              <p-skeleton height="1rem" width="4rem"></p-skeleton>
            </div>
          </div>
        </ng-template>
        <!--   <ng-template pTemplate="footer">
               <div class="card-footer">
                   <a class="m-t-10 text-right" href="javascript:void(0)">
                       View Cash Details <i class="fas fa-chevron-right"></i></a>
               </div>
           </ng-template>-->
      </p-card>
    </div>
  </div>

  <h5 class="section-title">Audit</h5>
  <div class="section-row">
    <div class="section-column" style="align-items: flex-start; align-content: baseline;">
      <p-card header="">
        <div class="summary-content-grid" style="grid-template-columns: 1fr 1fr;"
             *ngIf="portfolioEditorService.portfolio$ | async as portfolio">
          <div class="summary-box" style="grid-template-columns: 1fr 1fr;">
            <span class="summary-label">Created</span>
            <span>{{ portfolio.createdBy }} on {{ portfolio.createdDate | dateFormat: 'MM/DD/YYYY hh:mm:ss A' }}</span>
          </div>
          <div class="summary-box">
            <span class="summary-label">Last Edited</span>
            <span>{{ portfolio.editedBy }} on {{ portfolio.editedDate | dateFormat: 'MM/DD/YYYY hh:mm:ss A' }}</span>
          </div>
        </div>
      </p-card>
    </div>
  </div>

  <p-dialog header="Confirm Action" [(visible)]="showModelClearConfirm" modal="true" class="ui-dialog-md">
    <div>Are you sure you want to unassign model "{{(portfolioEditorService.portfolio$ | async)?.modelName}}" from this portfolio?</div>
    <div>Clicking NO means that no portfolio settings will be saved.</div>
    <p-footer>
      <button type="button" pButton icon="fas fa-times" (click)="showModelClearConfirm = false" class="p-button-secondary" label="No"></button>
      <button type="button" pButton icon="fas fa-check" (click)="confirmModelClear()" label="Yes"></button>
    </p-footer>
  </p-dialog>
</ng-container>
