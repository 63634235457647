import { AlertService } from '../../../core';
import { AstroService } from '../../../services/astro.service';
import {
  IAstroOptimize,
  IAstroOptimizeRequest,
  IAstroOptimizeOverride,
  IOptimizeOverride,
  IAstroBatchOptimization,
  IAstroMessageResponse,
  IWithdrawCashOption,
  IWithdrawCashOptimizationRequest,
  IHarvestLoss,
  IOptimizeOverrideFields
} from '../../../models/astro';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import {
  AstroBatchStatus,
  AstroInvestorPreferences,
  HarvestLossValidationCode,
  HarvestLossValidationMessage,
  OptimizationType,
  OptimizationValidationCode,
  OptimizationValidationMessage,
  OverrideParameter,
  OverrideParameterInputType,
  OverrideParameterPlaceholder,
  OverrideParameterType,
  OverrideParameterValue,
  TransactionCostValidationCode,
  TransactionCostValidationMessage
} from '../../../libs/astro.constants';
import { PreferenceService } from '../../../services/preference.service';
import { IPreferences } from '../../../models/preferences/preference';

@Injectable({
  providedIn: 'root'
})

export class AstroAccountHubService {
  eclipseAccountIds: string[] = [];

  private readonly _pollBatchStatus: Subject<IAstroBatchOptimization[]> = new Subject();
  private readonly _displaySpinner: Subject<string> = new Subject();
  private readonly _batchCount: Subject<number> = new Subject();
  private readonly _accountCount: Subject<number> = new Subject();
  private readonly _isPollingMultiBatch: Subject<boolean> = new Subject();

  public get pollBatchStatus(): Observable<IAstroBatchOptimization[]> {
    return this._pollBatchStatus.asObservable();
  }

  public get getBatchCount(): Observable<number> {
    return this._batchCount.asObservable();
  }

  public get displaySpinner(): Observable<string> {
    return this._displaySpinner.asObservable();
  }

  public get getAccountCount(): Observable<number> {
    return this._accountCount.asObservable();
  }

  public get getPollingType(): Observable<boolean> {
    return this._isPollingMultiBatch.asObservable();
  }

  constructor(private readonly _preferenceService: PreferenceService,
    private readonly _astroService: AstroService,
    private readonly _alertService: AlertService) {
  }

  filterOutSyncEclipseCashPreference(levelPreferences: IPreferences[]): IPreferences[] {
    return levelPreferences.filter(category => category.name !== AstroInvestorPreferences.SyncEclipseCashToAstro);
  }

  optimizeAccount<T extends IAstroOptimize>(accounts: T[]): void {
    this.populateEclipseAccountIds(accounts);
    const optimizationRequest = this.getAstroOptimizeRequest(OptimizationType.Standard);
    this._accountCount.next(accounts.length);
    this._isPollingMultiBatch.next(false);
    this.optimize(optimizationRequest);
  }

  optimizeAccountInvestCash<T extends IAstroOptimize>(accounts: T[], optimizeOverride: IOptimizeOverride): void {
    this.populateEclipseAccountIds(accounts);
    const optimizationRequest = this.getAstroOptimizeRequest(OptimizationType.Single, true, optimizeOverride);
    if (optimizationRequest.optimizationType === OptimizationType.Standard) {
      this._accountCount.next(accounts.length);
      this._isPollingMultiBatch.next(false);
    }
    this.optimize(optimizationRequest);
  }

  optimizeAccountTLH<T extends IAstroOptimize>(accounts: T[], optimizeOverride?: IOptimizeOverride, harvestLoss?: IHarvestLoss): void {
    this._accountCount.next(accounts.length);
    this._isPollingMultiBatch.next(false);
    this.populateEclipseAccountIds(accounts);
    const optimizationRequest = this.getHarvestLossRequest(optimizeOverride, harvestLoss);
    this.optimize(optimizationRequest);
  }

  getHarvestLossRequest(optimizeOverride?: IOptimizeOverride, harvestLoss?: IHarvestLoss): IAstroOptimizeRequest {
    const optimizationRequest = {
      accountIds: this.eclipseAccountIds,
      optimizationType: OptimizationType.HarvestLoss,
      optimizeOverride: optimizeOverride
    } as IAstroOptimizeRequest;
    if (optimizationRequest.optimizeOverride) {
      optimizationRequest.optimizeOverride.shortTermLoss = optimizationRequest.optimizeOverride.isShortTermLossPct
        ? harvestLoss?.shortTermLossPercent
        : harvestLoss?.shortTermLossAmount;
      optimizationRequest.optimizeOverride.longTermLoss = optimizationRequest.optimizeOverride.isLongTermLossPct
        ? harvestLoss?.longTermLossPercent
        : harvestLoss?.longTermLossAmount;
      optimizationRequest.optimizeOverride.sellLotIfLossGreaterThan = optimizationRequest.optimizeOverride.isSellLotIfLossGreaterThanPct
        ? harvestLoss?.sellIfLossGTPercent
        : harvestLoss?.sellIfLossGTAmount;
    }
    return optimizationRequest;
  }

  optimizeAccountWithdrawCash<T extends IAstroOptimize>(accounts: T[], optimizeOverride: IOptimizeOverride, withdrawCashOption: IWithdrawCashOption): void {
    this.populateEclipseAccountIds(accounts);
    if (accounts.length > 1) {
      this._batchCount.next(accounts.length);
      this._isPollingMultiBatch.next(true);
    } else {
      this._isPollingMultiBatch.next(false);
    }
    this._accountCount.next(accounts.length);
    const optimizationRequest = this.getAstroOptimizeRequest(OptimizationType.WithdrawCash);
    optimizationRequest.withdrawCash = this.getAstroWithdrawOptimizationRequest(withdrawCashOption, optimizeOverride);
    this.optimize(optimizationRequest);
  }

  private getAstroWithdrawOptimizationRequest(withdrawCashOption: IWithdrawCashOption, optimizeOverride: IOptimizeOverride): IWithdrawCashOptimizationRequest {
    return {
      withdrawAmount: withdrawCashOption.withdrawAmount ?? 0,
      includeCash: withdrawCashOption.includeCash ?? false,
      onlySellSecurities: withdrawCashOption.onlySellSecurities ?? false,
      maxCapitalGains: optimizeOverride.maximumCapitalGains,
      maxTrades: optimizeOverride.maximumNoOfTrades,
      minTradeSizePCT: optimizeOverride.minimumTradeSizePCT
    } as IWithdrawCashOptimizationRequest;
  }

  optimizeSingleAccount<T extends IAstroOptimize>(accounts: T[], optimizeOverride: IOptimizeOverride): void {
    if (accounts.length > 1) {
      this._batchCount.next(accounts.length);
      this._isPollingMultiBatch.next(true);
    }
    this._accountCount.next(accounts.length);
    this.populateEclipseAccountIds(accounts);
    const optimizationRequest = this.getAstroOptimizeRequest(OptimizationType.Single, false, optimizeOverride);
    this.optimize(optimizationRequest);
  }

  async optimize(astroOptimizeRequest: IAstroOptimizeRequest): Promise<void> {
    try {
      this._displaySpinner.next(AstroBatchStatus.Initiated);
      const optimizationResponse = await lastValueFrom(this._astroService.optimize(astroOptimizeRequest));
      this.setPollingType(optimizationResponse);
      this._displaySpinner.next(optimizationResponse[0].batchIds[0]);
      this._pollBatchStatus.next(optimizationResponse);
    } catch (error) {
      this._displaySpinner.next(AstroBatchStatus.Failed);
    }
  }

  private setPollingType(optimizationResponse: IAstroBatchOptimization[]): void {
    if (optimizationResponse.length === 1) {
      this._isPollingMultiBatch.next(optimizationResponse[0].batchIds.length > 1);
    } else {
      this._isPollingMultiBatch.next(optimizationResponse.length > 1);
    }
  }

  async getAstroLog(accountId: string, batchName: string, connectFirmId: string): Promise<IAstroMessageResponse> {
    try {
      const logsObservable = this._astroService.getAstroLogByAccountIdAndBatchName(accountId, batchName, connectFirmId);
      return await lastValueFrom(logsObservable);
    } catch (error) {
      throw Error(error);
    }
  }

  async getAstroMessage(accountId: string, batchName: string, connectFirmId: string): Promise<IAstroMessageResponse> {
    const messageObservable = this._astroService.getAstroMessageByAccountIdAndBatchName(accountId, batchName, Number(connectFirmId));
    return await lastValueFrom(messageObservable);
  }

  private getAstroOptimizeRequest(optimizationType: OptimizationType, investCash?: boolean, optimizeOverride?: IOptimizeOverride): IAstroOptimizeRequest {
    return {
      accountIds: this.eclipseAccountIds,
      isInvestCash: investCash,
      optimizationType: optimizationType,
      optimizeOverride: optimizeOverride
    } as IAstroOptimizeRequest;
  }

  private populateEclipseAccountIds<T extends IAstroOptimize>(accounts: T[]): void {
    this.eclipseAccountIds = accounts.map(a => a.accountId);
  }

  getFieldsToValidate(optimizeOverride: IOptimizeOverride): IOptimizeOverrideFields[] {
    return [{
      validationCode: OptimizationValidationCode.MinimumTradeSize,
      validationMessage: OptimizationValidationMessage.MinimumTradeSize,
      value: optimizeOverride.minimumTradeSize,
      allowedValue: OverrideParameterValue.MinimumTradeSize
    }, {
      validationCode: OptimizationValidationCode.MaximumNoOfTrades,
      validationMessage: OptimizationValidationMessage.MaximumNoOfTrades,
      value: optimizeOverride.maximumNoOfTrades,
      allowedValue: OverrideParameterValue.MaximumIntegerValue
    }, {
      validationCode: OptimizationValidationCode.MaximumNoOfAssets,
      validationMessage: OptimizationValidationMessage.MaximumNoOfAssets,
      value: optimizeOverride.maximumNoOfAssets,
      allowedValue: OverrideParameterValue.MaximumIntegerValue
    }, {
      validationCode: OptimizationValidationCode.NetCapitalGainsYTD,
      validationMessage: OptimizationValidationMessage.NetCapitalGainsYTD,
      value: optimizeOverride.netCapitalGainsYTD,
      allowedValue: OverrideParameterValue.MaximumOptimizationAmount
    }, {
      validationCode: TransactionCostValidationCode.BuyTradeTransactionCost,
      validationMessage: TransactionCostValidationMessage.BuyTradeTransactionCost,
      value: optimizeOverride.buyTradeTransactionCost,
      allowedValue: OverrideParameterValue.MaximumOptimizationAmount
    }, {
      validationCode: TransactionCostValidationCode.SellTradeTransactionCost,
      validationMessage: TransactionCostValidationMessage.SellTradeTransactionCost,
      value: optimizeOverride.sellTradeTransactionCost,
      allowedValue: OverrideParameterValue.MaximumOptimizationAmount
    }, {
      validationCode: OptimizationValidationCode.MaxCapitalGain,
      validationMessage: OptimizationValidationMessage.MaximumCapitalGain,
      value: optimizeOverride.maximumCapitalGains,
      allowedValue: OverrideParameterValue.MaximumOptimizationAmount
    }, {
      validationCode: OptimizationValidationCode.TicketCharge,
      validationMessage: OptimizationValidationMessage.TicketCharge,
      value: optimizeOverride.ticketCharge,
      allowedValue: OverrideParameterValue.MaximumTicketCharge
    }, {
      validationCode: OptimizationValidationCode.InvalidTurnoverPercentage,
      validationMessage: OptimizationValidationMessage.InvalidTurnoverPercentage,
      value: optimizeOverride.maximumTurnoverPercent,
      allowedValue: OverrideParameterValue.MaximumTurnoverPercentage
    }] as IOptimizeOverrideFields[];
  }

  getFieldsToValidatePercentage(optimizeOverride: IOptimizeOverride, harvestLoss: IHarvestLoss): IOptimizeOverrideFields[] {
    return [{
      validationCode: OverrideParameter.MaximumTrackingError,
      value: optimizeOverride.maximumTrackingErrorPercent
    }, {
      validationCode: OverrideParameter.MinimumTrackingError,
      value: optimizeOverride.minimumTrackingErrorPercent
    }, {
      validationCode: OverrideParameter.ShortTermGainTaxRate,
      value: optimizeOverride.shortTermGainTaxRatePercent
    }, {
      validationCode: OverrideParameter.LongTermGainTaxRate,
      value: optimizeOverride.longTermGainTaxRatePercent
    }, {
      validationCode: OverrideParameter.SecurityMinimumWeight,
      value: optimizeOverride.securityMinimumWeightPercent
    }, {
      validationCode: OverrideParameter.SecurityMaximumWeight,
      value: optimizeOverride.securityMaximumWeightPercent
    }, {
      validationCode: OverrideParameter.MaximumTradeSize,
      value: optimizeOverride.minimumTradeSizePCT
    }, {
      validationCode: HarvestLossValidationCode.SellIfLossGTPercent,
      value: harvestLoss.sellIfLossGTPercent
    }, {
      validationCode: HarvestLossValidationCode.ShortTermLoss,
      value: harvestLoss.shortTermLossPercent
    }, {
      validationCode: HarvestLossValidationCode.LongTermLoss,
      value: harvestLoss.longTermLossPercent
    }] as IOptimizeOverrideFields[];
  }

  getHarvestLossFields(harvestLoss: IHarvestLoss): IOptimizeOverrideFields[] {
    return [{
      validationCode: HarvestLossValidationCode.SellIfLossGT,
      validationMessage: HarvestLossValidationMessage.SellIfLossGT,
      value: harvestLoss.sellIfLossGTAmount,
      allowedValue: OverrideParameterValue.MaximumIntegerValue
    }, {
      validationCode: HarvestLossValidationCode.ShortTermLoss,
      validationMessage: HarvestLossValidationMessage.ShortTermLoss,
      value: harvestLoss.shortTermLossAmount,
      allowedValue: OverrideParameterValue.MaximumIntegerValue
    }, {
      validationCode: HarvestLossValidationCode.LongTermLoss,
      validationMessage: HarvestLossValidationMessage.LongTermLoss,
      value: harvestLoss.longTermLossAmount,
      allowedValue: OverrideParameterValue.MaximumIntegerValue
    }] as IOptimizeOverrideFields[];
  }

  getWithdrawCashOverrideOptions(): IAstroOptimizeOverride[] {
    return [{
      displayName: 'Maximum # Trades',
      fieldName: 'maximumNoOfTrades',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Count,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Maximum Trade Size(%)',
      fieldName: 'minimumTradeSizePCT',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Count,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Maximum Capital Gains($)',
      fieldName: 'maximumCapitalGains',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Simple Rounding',
      fieldName: 'simpleRounding',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    }];
  };

  getTLHOverrideFieldOptions(): IAstroOptimizeOverride[] {
    return [{
      displayName: 'Minimum Tracking Error(%)',
      fieldName: 'minimumTrackingErrorPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    }, {
      displayName: 'Maximum Tracking Error(%)',
      fieldName: 'maximumTrackingErrorPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    }, {
      displayName: 'Maximum # Trades',
      fieldName: 'maximumNoOfTrades',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Count,
      valueType: OverrideParameterType.Amount
    }, {
      displayName: 'Minimum Trade Size',
      fieldName: 'minimumTradeSize',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Count,
      valueType: OverrideParameterType.Amount
    }, {
      displayName: 'Sync Accounts Data With Real Time Prices',
      fieldName: 'syncAccountsDataWithRealTimePrices',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    },
    {
      displayName: 'Simple Rounding',
      fieldName: 'simpleRounding',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    }];
  };

  getOverrideOptions(): IAstroOptimizeOverride[] {
    return [{
      displayName: 'Short Term Gain Tax Rate(%)',
      fieldName: 'shortTermGainTaxRatePercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Security Minimum Weight(%)',
      fieldName: 'securityMinimumWeightPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Maximum # Assets',
      fieldName: 'maximumNoOfAssets',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Count,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Long Term Gain Tax Rate(%)',
      fieldName: 'longTermGainTaxRatePercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Security Maximum Weight(%)',
      fieldName: 'securityMaximumWeightPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Trade Transaction Cost(Sell)($)',
      fieldName: 'sellTradeTransactionCost',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Maximum Capital Gains($)',
      fieldName: 'maximumCapitalGains',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Maximum Turnover(%)',
      fieldName: 'maximumTurnoverPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Trade Transaction Cost(Buy)($)',
      fieldName: 'buyTradeTransactionCost',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Net Capital Gains YTD($)',
      fieldName: 'netCapitalGainsYTD',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Maximum Tracking Error(%)',
      fieldName: 'maximumTrackingErrorPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Ticket Charge($)',
      fieldName: 'ticketCharge',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Withhold Cash For Transaction Cost',
      fieldName: 'withholdCashForTransactionCost',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    },
    {
      displayName: 'Minimum Tracking Error(%)',
      fieldName: 'minimumTrackingErrorPercent',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Decimal,
      valueType: OverrideParameterType.Percent
    },
    {
      displayName: 'Maximum # Trades',
      fieldName: 'maximumNoOfTrades',
      inputType: OverrideParameterInputType.Textbox,
      placeholder: OverrideParameterPlaceholder.Count,
      valueType: OverrideParameterType.Amount
    },
    {
      displayName: 'Withhold Cash For Taxes',
      fieldName: 'withholdCashForTaxes',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    },
    {
      displayName: 'Apply Invest Cash Restriction',
      fieldName: 'applyInvestCashRestriction',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    },
    {
      displayName: 'Simple Rounding',
      fieldName: 'simpleRounding',
      inputType: OverrideParameterInputType.Checkbox,
      placeholder: null,
      valueType: OverrideParameterType.Boolean
    }];
  }
}
