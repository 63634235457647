<div class="main">
  <eclipse-page-header pageName="Queries" [allowExcelExport]="dynamicResultActiveTabIndex > 0"
                       (exportToExcel)="exportQueryResult(selectedQuery)">
    <ng-container slot="mainActions" *ngIf="dynamicResultActiveTabIndex === 0">
      <button pButton (click)="runQuery(selectedQuery)" icon="fas fa-play" label="Run Query" [disabled]="!selectedQuery"></button>
      <eclipse-actions-button [menuItems]="actionItems$" (menuOpened)="generateActionItems()"></eclipse-actions-button>
    </ng-container>
  </eclipse-page-header>
  <div class="dashboard-content" id="grid-height-top">
    <p-tabView styleClass="tabs-only" [(activeIndex)]="dynamicResultActiveTabIndex" [controlClose]="true"
               (onClose)="handleTabClose($event)" (onChange)="onTabChange($event)">
      <p-tabPanel header="Query Builder">
        <div id="querybuilder" class="flex gap-2">
          <div class="querybuilder-list">
            <p-listbox [options]="finalQueryObject" filter="true" [(ngModel)]="selectedQuery"
                       [filterValue]="filterQueryText" optionLabel="name"
                       (onChange)="getQueryDetails($event.value.id)">
              <ng-template let-query pTemplate="item">
                <div
                  style="display: grid; grid-template-columns: auto 1fr auto; align-items: center; gap: .5rem; width: 100%;">
                  <i [ngClass]="{'favorite-star active': query.isFavourite}" class="fas fa-star color-transparent"
                     aria-hidden="true"></i>
                  <span class="truncate-text" [title]="query.name">{{ query.name }}</span>
                  <button *ngIf="query.id === queryDisplay.id" href="javascript:void(0)"
                          (click)="$event.stopPropagation();closeQuery()" pButton
                          class="p-button-text p-button-sm">Clear
                  </button>
                </div>

              </ng-template>
            </p-listbox>
          </div>
          <div class="flex flex-column flex-1 gap-2">
            <div class="flex pt-2 gap-2">
              <div class="flex flex-1 gap-2 align-items-center">
                <div class="flex-1 p-inputgroup">
                  <input pInputText placeholder="Data Query Name" (ngModelChange)="setChange()"
                         type="text" [(ngModel)]="queryDisplay.name" class="flex-1"
                         (blur)="(queryDisplay.isTradeGroup)?checkTradeGroupQueryName(queryDisplay.name + '_TG'):checkQueryName(queryDisplay.name)"
                         aria-label="Data query name" [maxlength]="MAX_QUERY_NAME_LENGTH">
                  <small class="p-inputgroup-addon">{{queryDisplay.name?.length || 0}} / {{ MAX_QUERY_NAME_LENGTH }}</small>
                </div>
                <p-message *ngIf="showQueryNameErr" severity="error" text="Query name already exists."></p-message>
              </div>
              <div class="flex flex-1 align-items-center gap-2">
                <i class="fa-star favorite-star cursor" style="font-size: 1.25rem;"
                   [ngClass]="{'fas active': queryDisplay.isFavourite, 'far': !queryDisplay.isFavourite}"
                   aria-hidden="true"
                   (click)="setFavourite()"></i>
                <p-checkbox name="optcheck" binary="true" [(ngModel)]="queryDisplay.isTradeGroup"
                            (onChange)="setTradeGroup()" label="Trade Group"></p-checkbox>
              </div>
            </div>
            <div class="flex flex-column gap-2">
              <p-card>
                <p-pickList [source]="availableColumnList" [target]="queryDisplay.selectedColumns"
                            [showSourceControls]="false"
                            sourceHeader="Available Columns" targetHeader="Selected Columns" [dragdrop]="true"
                            [responsive]="true"
                            [sourceStyle]="{ height: '12rem' }" [targetStyle]="{ height: '12rem' }"
                            styleClass="p-picklist-compact"
                            (onMoveToTarget)="addSelectedColumns()"
                            (onMoveToSource)="removeSelectedColumns($event)"
                            filterBy="displayName" sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name">
                  <ng-template let-column pTemplate="item">
                    {{ column.displayName }}
                  </ng-template>
                </p-pickList>
              </p-card>
              <p-card header="Query Output">
                <textarea pInputTextarea readonly *ngIf="activeTabName === 'Standard'" aria-label="Query output">
          {{finalQueryStandard}}
            </textarea>
                <textarea pInputTextarea readonly *ngIf="activeTabName === 'Advanced'"
                          aria-label="Advanced query output">
          {{finalQueryAdvanced}}
            </textarea>
              </p-card>
              <div class="mt-2">
                <p-tabView [activeIndex]="activeTabIndex" (onChange)="getDivId($event.index)">
                  <p-tabPanel header="Standard">
                    <eclipse-data-query-standard [standardQuery]="queryDisplay"></eclipse-data-query-standard>
                    {{ autoGenerateQuery() }}
                  </p-tabPanel>
                  <p-tabPanel header="Advanced">
                    <div class="tree">
                      <eclipse-data-query-advance [currentAdvanceQueryGroups]="queryDisplay.where.groups"
                                                  [selectedColumnList]="queryDisplay.selectedColumns"
                                                  [availableColumnList]="MasteravailableColumns"
                                                  [advanceQueryChanges]="queryDisplay" [removeHide]="hideRemoveButton">
                      </eclipse-data-query-advance>
                      {{ autoGenerateQuery() }}
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </div>
              <div class="clearfix"></div>
              <div *ngIf="showError">
                <p class="text-danger">To create a Trade Group, please select either Portfolio.Portfolio ID or
                  Account.Account ID under selected column</p>
              </div>
              <div *ngIf="queryDisplay.showSameRuleError">
                <p class="text-danger">Same rule cannot be added</p>
              </div>
              <div *ngIf="queryDisplay.emptyRuleValidation">
                <p class="text-danger">Fields cannot be blank</p>
              </div>
              <div class="p-t-50"></div>
            </div>
          </div>
          <div class="button-footer">
            <span *ngIf="tabCountMaxm" class="text-danger pull-left">Maximum number of Query Output at a time is 5. Please close the executed query tab to Run a new Query.</span>

            <button type="button" pButton class="p-button-secondary" (click)="createNewQuery('Standard')" label="Cancel"
                    [disabled]="!queryDisplay.name==null || queryDisplay.name==undefined || queryDisplay.name=='' || queryDisplay.selectedColumns.length==0"></button>
            <button type="button" pButton (click)="saveQuery(queryDisplay.id, null)" label="Save"
                    [disabled]="queryDisplay.name==null || queryDisplay.name==undefined || queryDisplay.name=='' || queryDisplay.selectedColumns.length==0 || showError || hideSaveButton ||showQueryNameErr  || queryDisplay.showSameRuleError || saveDisable"></button>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel *ngFor="let tabData of runQueryTabsData" [header]="tabData.name" [closable]="true" [cache]="true">
        <div id="executeQuery" class="agcontainer">
          <ag-grid-angular class="ag-theme-alpine grid-height-autosizeThr" enableKeyboardSelectAllRows
                           [context]="gridContext"
                           rowSelection="single" [columnDefs]="activeTabData.ColumnDef"
                           [rowData]="activeTabData.result"
                           suppressContextMenu="true" [gridOptions]="activeTabData.GridOptions"
                           (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog [(visible)]="savedView.exitWarning.show" modal="true" [draggable]="false" [resizable]="false"
          class="ui-dialog-md"
          [closable]="true">
  <div>You will lose any unsaved changes. Do you want to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-check" (click)="confirmClick(false)" label="Yes"></button>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(true)"
            label="No"></button>
  </p-footer>
</p-dialog>
<p-dialog [(visible)]="isModified" modal="true" [draggable]="false" [resizable]="false" class="ui-dialog-md"
          [closable]="false">
  <div>You will lose any unsaved changes. Do you want to save & continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(false)"
            label="No"></button>
    <button type="button"
            [disabled]="!queryDisplay?.name==null || queryDisplay.name==undefined || queryDisplay.name=='' || queryDisplay.selectedColumns.length==0 || showError || hideSaveButton ||showQueryNameErr || saveHideInAdvanceTab || queryDisplay.showSameRuleError || saveDisable"
            pButton icon="fas fa-check" (click)="confirmClick(true, queryDisplay.id)" label="Yes"></button>
  </p-footer>
</p-dialog>
<p-dialog [(visible)]="isSaved" modal="true" [draggable]="false" [resizable]="false" class="ui-dialog-md"
          [closable]="false">
  <div>Saving will update the existing Query. Do you want to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(false)"
            label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="confirmUpdateClick(queryDisplay.id)"
            label="Yes"></button>
  </p-footer>
</p-dialog>
<p-dialog header="Delete Query" [(visible)]="displayDeleteConfirm" modal="true" [draggable]=false [resizable]=false
          [closable]="false" class="ui-dialog-md">
  <div>This will permanently delete the selected query. Do you want to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmDeleteClick(false)"
            label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="confirmDeleteClick(true)" label="Yes"></button>
  </p-footer>
</p-dialog>
