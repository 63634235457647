<section style="display: flex; flex-direction: column; height: 100%;">
  <div class="page-header-row">
    <h3>Gain/Loss</h3>
  </div>
  <ng-container *ngIf="gainLossSummary$ | async as gainLossSummary">
    <div class="section-row">
      <div class="section-column">
        <p-card header="">
          <div class="section-content-column">
            <div class="data-box">
              <span><h4>Realized YTD Gain/Loss</h4></span>
            </div>
            <div class="data-box">
              <span>Total</span>
              <span>{{ gainLossSummary?.ytdRealizedTotalGain | currency:'USD' }}</span>
            </div>
            <div class="data-box">
              <span>Short-Term</span>
              <span>{{ gainLossSummary?.ytdRealizedShortTermGain | currency:'USD' }}</span>
            </div>
            <div class="data-box">
              <span class="data-label">Long-Term</span>
              <span>{{ gainLossSummary?.ytdRealizedLongTermGain | currency:'USD' }}</span>
            </div>
            <div class="data-box margin-top-10">
              <span><h4>Unrealized Gain/Loss</h4></span>
            </div>
            <div class="data-box">
              <span class="data-label">Total</span>
              <span>{{ gainLossSummary?.unRealizedTotalGain | currency:'USD' }}</span>
            </div>
            <div class="data-box">
              <span class="data-label-label">Short-Term</span>
              <span>{{ gainLossSummary?.unRealizedShortTermGain | currency:'USD' }}</span>
            </div>
            <div class="data-box">
              <span class="data-label">Long-Term</span>
              <span>{{ gainLossSummary?.unRealizedLongTermGain | currency:'USD' }}</span>
            </div>
          </div>
        </p-card>
      </div>
      <div class="section-column">
        <p-card header="">
          <div class="section-content-column">
            <div class="data-box">
              <span><h4>Max Gain Amount</h4></span>
            </div>
            <div class="data-box">
              <span class="data-label-label">Short-Term</span>
              <span *ngIf="gainLossSummary?.maxShortTermGainDollar">{{ gainLossSummary.maxShortTermGainDollar | currency:'USD' }}</span>
              <span *ngIf="gainLossSummary?.maxShortTermGainPercent">{{ gainLossSummary.maxShortTermGainPercent / 100 | percent:'1.0-2' }}</span>
            </div>
            <div class="data-box">
              <span class="data-label">Long-Term</span>
              <span *ngIf="gainLossSummary?.maxGainAmountDollar">{{ gainLossSummary.maxGainAmountDollar | currency:'USD' }}</span>
              <span *ngIf="gainLossSummary?.maxGainAmountPercent">{{ gainLossSummary.maxGainAmountPercent / 100 | percent:'1.0-2' }}</span>
            </div>
            <div class="data-box">
              <span class="data-label-label">Under Max Gain</span>
              <span *ngIf="gainLossSummary?.underMaxGainAmount !== null">{{ gainLossSummary?.underMaxGainAmount ? 'Yes' : 'No' }}</span>
              <span *ngIf="gainLossSummary?.underMaxGainAmount === null">N/A
              <i class="fas fa-question-circle m-l-5" pTooltip="Set the max cap gain in the portfolio preferences."
                 tooltipPosition="bottom"></i>
              </span>
            </div>
            <div class="data-box">
              <span class="data-label">Remaining Capital Gain</span>
              <span>{{ gainLossSummary?.netCapitalGain | currency:'USD' }}</span>
            </div>
            <div class="data-box">
              <span class="data-label">Carryforward Loss Amount</span>
              <span *ngIf="gainLossSummary?.carryForwardLossAmount !== null">{{ gainLossSummary.carryForwardLossAmount | currency:'USD'}}</span>
              <span *ngIf="gainLossSummary?.carryForwardLossAmount === null">N/A
              <i class="fas fa-question-circle m-l-5" pTooltip="Set the carry forward loss amount in the portfolio preferences."
                 tooltipPosition="bottom"></i>
              </span>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </ng-container>
</section>
