<p-card>
  <a href="javascript:void(0)" class="notification-item-list">
    <div class="notification-item" (click)="viewNotificationStory(notice.id)">
      <h4 class="item-title" [ngClass]="{'unread': !notice.readStatus}"
          [ngSwitch]="notice.notificationCategory.name.toLowerCase()">
          <i *ngSwitchCase="'trading'" class="fas fa-heart-rate green" aria-hidden="true"></i>
          <i *ngSwitchCase="'approval'" class="fas fa-file-check red" aria-hidden="true"></i>
          <i *ngSwitchCase="'system status'" class="fas fa-server blue" aria-hidden="true"></i>
          <i *ngSwitchCase="'analytics status'" class="fal fa-analytics blue" aria-hidden="true"></i>
        {{notice.subject}} </h4>
      <div class="flex justify-content-between">
        <span class="item-time-stamp">{{notice.createdOn | dateFormat: 'hh:mm A'}}</span>
        <span class="item-time-stamp">{{notice.createdOn | dateFormat: 'MM/DD/YYYY'}}</span>
      </div>
      <span [ngClass]="{'Error':'fas fa-exclamation-circle text-danger','Information':'fas fa-info-circle text-info', 'Warning':'fas fa-exclamation text-warning', 'TodoInformation':'far fa-calendar-check text-primary'}"></span>
    </div>
    <div class="item-slide-menu">
      <span *ngIf="!notice.readStatus">
        <button class="btn-read" title="Read" (click)="takeAction(notice.id,'UnRead')">
          <i class="fas fa-envelope-open" alt="Read" aria-hidden="true"></i>
        </button>
      </span>
      <span *ngIf="notice.readStatus">
        <button class="btn-read" title="Unread" (click)="takeAction(notice.id,'Read')">
          <i class="fas fa-envelope-dot" alt="Unread"></i>
        </button>
      </span>
      <button class="btn-trash" title="Delete" (click)="takeAction(notice.id,'Delete')"><i class="far fa-trash" aria-hidden="true"></i></button>
    </div>
  </a>
</p-card>
