import { TradeSeverity } from '../models/tradetools';

export interface IAstroBatchStatus {
  batchName: string;
  isReady: boolean;
  status: string;
  comments: string;
  totalCount: number;
  completedCount: number;
  pendingCount: number;
  failedCount: number;
  isError: boolean;
  message: string;
  successCount: number;
  totalReceived: number;
  isMultiBatchRequest: boolean;
  isSingleBatchRequest: boolean;
  uniqueBatchIdentifier?: string;
  retryCount: number;
  unHandledBatchesProcessed: boolean;
}

export interface IAstroBatchesResults {
  accountId: string;
  batchName: string;
  externalBatchName: string;
  tradeInstanceId: number;
  accountName: string;
  targetStrategy: string;
  marketValue: number;
  numberOfTrades: number;
  turnOver: number;
  optimalBeta: number;
  optimalRSquared: number;
  transactionCost: number;
  proposedShortTermGainLoss: number;
  proposedLongTermGainLoss: number;
  taxCost: number;
  ytdRealizedSTGain: number;
  ytdRealizedLTGain: number;
  currentTrackingError: number;
  optimalTrackingError: number;
  optimizationRun: string;
  warning: string;
  connectFirm: number;
  createdBy: number;
  optimizationType: string;
  optimizationStartTime: Date;
}

export interface IAstroTradeImportSummary {
  tradeInstanceId: number;
  createdDate: Date;
  createdBy: string;
  tradeCount: number;
  stageTotalCount: number;
  errorCount: number;
}

export interface IAstroTradeImportErrors {
  id: number;
  tradeInstanceId: number;
  stagingTradeAstroId: number;
  errorCode: string;
  severity: TradeSeverity;
  message: string;
}

export interface IAstroTradeImportSummaryResponse {
  astroTradeImportSummary: IAstroTradeImportSummary;
  astroTradeImportErrors: IAstroTradeImportErrors[];
}

export interface IAstroTradeImportResponse {
  tradeInstanceId: number;
  errorMessage: string;
}

export interface IAstroOptimizationReport {
  topTenDecrease: ITopTen[];
  topTenIncrease: ITopTen[];
  optGainLoss: IOptimalGainLoss;
  initGainLoss: IInitialGainLoss;
  portfolioSummary: IPortfolioSummary;
  error: string;
}

export interface ITopTen {
  userId: string;
  accountId: string;
  securityName: string;
  initialPercentWeight: number;
  optimalPercentWeight: number;
  changePercentWeight: number;
}

export interface IOptimalGainLoss {
  accountId: string;
  batchName: string;
  userId: string;
  proposedNetShortTermGainLoss: number;
  proposedNetLongTermGainLoss: number;
  proposedTaxCost: number;
  remainingShortTermGainLoss: number;
  remainingLongTermGainLoss: number;
}

export interface IInitialGainLoss {
  accountName: string;
  manager: string;
  holdingsDate: Date;
  numberOfHoldings: number;
  marketValue: number;
  shortLots: number;
  longLots: number;
  shareLots: number;
  shortGain: number;
  shortLoss: number;
  shortNet: number;
  longGain: number;
  longLoss: number;
  longNet: number;
  longShortGainLoss: number;
}

export interface IPortfolioSummary {
  accountId: number;
  initialMarketValue: number;
  optimalMarketValue: number;
  initialPercentCash: number;
  optimalPercentCash: number;
  targetPercentCash: number;
  initialHoldings: number;
  optimalHoldings: number;
  targetHoldings: number;
  initialTrackingError: number;
  optimalTrackingError: number;
  initialRSquared: number;
  optimalRSquared: number;
  initialBeta: number;
  optimalBeta: number;
  initialRisk: number;
  optimalRisk: number;
  targetRisk: number;
  turnover: number;
  initialTaxCost: number;
  optimalTaxCost: number;
  sold: number;
  purchased: number;
}

export interface ISector {
  accountId: number;
  userId: number;
  initialIT: number;
  optimizedIT: number;
  targetIT: number;
  initialFinancial: number;
  optimizedFinancial: number;
  targetFinancial: number;
  initialHealthCare: number;
  optimizedHealthCare: number;
  targetHealthCare: number;
  initialConsumerCyclical: number;
  optimizedConsumerCyclical: number;
  targetConsumerCyclical: number;
  initialConsumerIndustrial: number;
  optimizedIndustrial: number;
  targetIndustrial: number;
  initialRealEstate: number;
  optimizedRealEstate: number;
  targetRealEstate: number;
  initialConsumerDefensive: number;
  optimizedConsumerDefensive: number;
  targetConsumerDefensive: number;
  initialCommunication: number;
  optimizedCommunication: number;
  targetCommunication: number;
  initialEnergy: number;
  optimizedEnergy: number;
  targetEnergy: number;
  initialMaterials: number;
  optimizedMaterials: number;
  targetMaterials: number;
  initialUtilities: number;
  optimizedUtilities: number;
  targetUtilities: number;
}

export interface IAstroModels {
  id: number;
  name: string;
  astroTemplateId: string;
}

export interface IAstroTrades {
  ticker: string;
  securityName: string;
  marketValue: number;
  costBasis: number;
  acquiredDate: Date;
  initialShares: number;
  finalShares: number;
  changeshare: number;
  initialPercentOfPortfolio: number;
  finalPercentOfPortfolio: number;
  transactionValue: number;
  tax: number;
  realizedGainLoss: number;
}

/** Defines the customview entity for all Astro Alerts*/
export interface IAstroAlertFilters {
  id: number;
  name: string;
}

export interface IAstroAccount {
  id: number;
  accountId: string;
  name: string;
  accountNumber: string;
  model: string;
  value: number;
  astroEnabled: boolean;
  currentCash: number;
  orionConnectExternalId: number;
  orionConnectFirmId: number;
  cashMaxPer?: number;
  cashMinPer?: number;
  cashPer: number;
  currentTrackingError: number;
  hasCashAlert?: boolean;
  hasNewTemplateAssignmentAlert?: boolean;
  hasRiskAlert?: boolean;
  hasTaxLossHarvestingOpportunities?: boolean;
  hasTrackingErrorAlert?: boolean;
  maxTrackingError: number;
  minTrackingError: number;
  riskTolerance: number;
  riskToleranceMax: number;
  riskToleranceMin: number;
  riskToleranceTarget: number;
  taxLossHarvestingAmount: number;
  lastOptimizedDate: Date;
}

export interface IAstroTemplate {
  name: string;
}

export interface IAstroBatchOptimization {
  batchIds: string[];
  connectFirmId?: number;
}

export interface IAstroHoldingRestriction {
  securityId: number;
  securityName: string;
  ticker: string;
  maximumPercent: number | null;
  minimumPercent: number | null;
  accountId: string;
  userId: string;
  updateSource: string;
  restrictionType: AstroHoldingRestrictionType;
  securityType: string;
}

export enum AstroHoldingRestrictionType {
  None = 0,
  DoNotTrade = 1,
  SellAllShares = 2,
  BringWeightTo = 3,
  Percent = 4,
  DoNotSell = 5,
  DoNotBuy = 6
}

export interface IAstroMessageResponse {
  logs: string;
  errorMessage: string;
}

export interface IAstroOptimize {
  accountId: string;
  orionConnectExternalId: number;
  orionConnectFirmId: number;
}

export interface IAstroOptimizeRequest {
  accountIds: string[];
  optimizationType: number;
  isInvestCash: boolean;
  optimizeOverride?: IOptimizeOverride;
  withdrawCash?: IWithdrawCashOptimizationRequest;
}

export interface IAstroOptimizeOverride {
  displayName: string;
  fieldName: string;
  inputType: string;
  placeholder: string;
  valueType: string;
}

export interface IOptimizeOverride {
  shortTermGainTaxRatePercent: string;
  longTermGainTaxRatePercent: string;
  maximumCapitalGains: string;
  netCapitalGainsYTD: string;
  withholdCashForTransactionCost: boolean;
  withholdCashForTaxes: boolean;
  securityMinimumWeightPercent: string;
  securityMaximumWeightPercent: string;
  maximumTurnoverPercent: string;
  maximumTrackingErrorPercent: string;
  minimumTrackingErrorPercent: string;
  maximumNoOfTrades: string;
  maximumNoOfAssets: string;
  sellTradeTransactionCost: string;
  buyTradeTransactionCost: string;
  ticketCharge: string;
  applyInvestCashRestriction: boolean;
  isAmountToHarvest: boolean;
  shortTermLoss: string;
  longTermLoss: string;
  sellLotIfLossGreaterThan: string;
  minimumTradeSizePCT: string;
  minimumTradeSize: string;
  maxTrades: string;
  onlySellSecurities: boolean;
  isRestrictTlh: boolean;
  isShortTermLossPct: boolean;
  isLongTermLossPct: boolean;
  isSellLotIfLossGreaterThanPct: boolean;
  syncAccountsDataWithRealTimePrices: boolean;
}

export interface IHarvestLoss {
  sellIfLossGTAmount: string;
  sellIfLossGTPercent: string;
  shortTermLossAmount: string;
  shortTermLossPercent: string;
  longTermLossAmount: string;
  longTermLossPercent: string;
}

export interface IWithdrawCashOption {
  withdrawAmount: string;
  includeCash: boolean;
  onlySellSecurities: boolean;
}

export interface IWithdrawCashOptimizationRequest {
  withdrawAmount: string;
  maxCapitalGains: string;
  minTradeSizePCT: string;
  maxTrades: string;
  includeCash: boolean;
  onlySellSecurities: boolean;
}

export interface IOptimizationValidation {
  code: string;
  message: string;
}

export interface IOptimizeOverrideFields {
  validationCode: string;
  validationMessage: string;
  value: string;
  allowedValue: number;
}

export interface IAstroInvestorPreferences {
  astroTemplate: string;
  riskModel: string;
  enableNLTC: string;
  excludeHoldFromBuyList: string;
  accountMaxGainAmount: number;
  maximumNoSecurities: number;
  maximumAccountCash: number;
  maximumSecurityExposure: number;
  maximumTrackingError: number;
  maximumTurnover: number;
  minimumAccountCash: number;
  minimumSecurityExposure: number;
  minimumThreshold: number;
  minimumTrackingError: number;
  targetStrategy: string;
  ticketCharge: number;
  unsetCompositeAssets: string;
  withholdCashForTransactionCosts: string;
  astroWithholdCashForTaxes: string;
  astroError: string;
  maximumTrades: number;
  minimumTradeType: string;
  minimumTradeSize: number;
  allowShortTermGains: string;
  isEdited?: boolean;
  syncEclipseCashValue: boolean;
  updateCashFields: boolean;
}

export interface IPreferenceOptions {
  id: number;
  name: string;
  order: number;
};

export interface ISaveInvestorPreferencesResponse {
  isError: boolean;
  errorMessage: string;
}

export interface ISaveInvestorPreferencesRequest {
  isEdited: boolean;
  editedInvestorPreferences: IAstroInvestorPreferences;
}
