<div class="dashboard-container-header">
  <!-- Select current dashboard -->
  <ng-container [ngTemplateOutlet]="newActions"></ng-container>
  <ng-template #newActions>
    <p-tabView styleClass="tabs-only p-tabview-no-bottom-border" [(activeIndex)]="activeDashboardTabIndex"
               (onChange)="onDashboardTabChange($event)">
      <p-tabPanel header="Firm Action Items"></p-tabPanel>
      <p-tabPanel header="My Action Items"></p-tabPanel>
    </p-tabView>
  </ng-template>

  <!-- Enable/disable catgories -->
  <div id="actionsFilter" data-test-id="actionsFilter" class="flex" style="gap: 5px;">
    <div class="actionBtn btn-group" *ngIf="!isEditing">
      <button data-target="#" pButton class="p-button-sm p-button-secondary" icon="fas fa-filter" data-toggle="dropdown"
              aria-expanded="false" title="Filter">
      </button>
      <div #filterDropDown class="dropdown-menu dropdown-menu-right p-a-10">
        <div *ngFor="let category of dashboardCategories">
          <label>
            <input type="checkbox" [name]="category.name" [value]="category.name"
                   [(ngModel)]="category.selected" (change)="onSelectedCategoryChange(category)"> {{ category.name }}
          </label>
        </div>
      </div>
    </div>

    <button *ngIf="canAddSavedView" type="button" pButton class="p-button-sm p-button-secondary"
            (click)="addSavedView()">
      Add Saved View
    </button>

    <!-- Add/remove fields -->
    <div class="actionBtn btn-group" id="itemsFilter" data-test-id="itemsFilter" *ngIf="isEditing">
      <button data-target="#" pButton class="p-button-sm p-button-secondary" data-toggle="dropdown"
              aria-expanded="false">
        {{ actionsSelected }} selected<span class="m-l-5 caret"></span>
        <div class="ripple-container"></div>
      </button>
      <div #editActionsDropDown class="dropdown-menu dropdown-menu-right p-x-15"
           style="overflow: auto; max-height: 400px;">
        <div>
          <label class="fas fa-search search-addon-icon" for="actionFilter" title="Filter action items"
                 aria-label="Filter action items"></label>
          <input type="text" placeholder="Filter action items" id="actionFilter" class="form-control"
                 [(ngModel)]="actionSearchText"
                 (ngModelChange)="filterActionItems()"/>
        </div>
        <div *ngIf="!filteredSelectableFields?.length" id="action-empty" data-test-id="action-empty">
          <div style="display:flex; justify-content: space-between; min-width: 400px;">
            <label class="field-group-header" style="margin-top:10px;">No items found</label>
          </div>
        </div>
        <div *ngFor="let field of filteredSelectableFields;let index = index" id="action-{{field.name}}">
          <label class="field-group-header"
                 *ngIf="!filteredSelectableFields[index-1] || field.categoryName !== filteredSelectableFields[index-1].categoryName">
            {{ field.categoryName }} </label>
          <div style="display:flex; justify-content: space-between; min-width: 400px;">
            <span class="field-name">{{ field.name }}</span>
            <p-inputSwitch id="action-{{field.id}}-switch" class="input-switch-small" [(ngModel)]="field.selected"
                           (onChange)="fieldToggled(field)" ariaLabel="{{field.name}} switch"></p-inputSwitch>
          </div>
        </div>

      </div>
    </div>

    <button *ngIf="!isEditing && canEditDashboard" type="button" pButton class="p-button-sm p-button-secondary"
            (click)="startEditing()">
      Edit Items
    </button>
    <button *ngIf="isEditing" type="button" pButton class="p-button-sm p-button-secondary"
            (click)="resetLayout()">
      Reset
    </button>
    <button *ngIf="isEditing" type="button" pButton class="p-button-sm p-button-secondary"
            (click)="cancelEditing()">
      Cancel
    </button>
    <button *ngIf="isEditing" [disabled]="isSaving" type="button" pButton class="p-button-sm"
            (click)="saveChanges()">
      Save
    </button>
  </div>
</div>

<div class="dashboard-item-container" cdkDropListGroup #dlg>
  <ng-container *ngIf="dashboard$ | async; else loadingdashboard">
    <div cdkDropList
         *ngFor="let item of currentDashboard?.dashboardDetails | dashboardItemFilter:dashboardCategories:isEditing;let i=index;"
         (cdkDropListDropped)="drop($event)"
         cdkDropListOrientation="horizontal"
         [cdkDropListData]="item">
      <div id="item-{{item.id}}" class="categories-item" [cdkDragDisabled]="(!isEditing || item.isCustomizing)" cdkDrag>
        <div class="dashboard-item" [ngClass]="{'customizing': item.isCustomizing}" [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="dashboardItemType.Metric">
            <eclipse-metric-dashboard-item [detail]="item" [dashboardSummary]="dashboardSummary"
                                           class="flex h-full"
                                           (onDelete)="onDeleteField(item.dashboardFieldId)"
                                           [isCustomizing]="item.isCustomizing"
                                           (onCustomizing)="onItemCustomizing(item, $event)"
                                           [isEditing]="isEditing"></eclipse-metric-dashboard-item>
          </ng-container>
          <ng-container *ngSwitchCase="dashboardItemType.SavedView">
            <eclipse-saved-view-dashboard-item [detail]="item"
                                               [dashboardSummary]="dashboardSummary"
                                               (onDelete)="onDeleteSavedView(item)"
                                               [isEditing]="isEditing"></eclipse-saved-view-dashboard-item>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingdashboard>
    <div class="flex flex-column flex-1 align-items-center" style="margin-top: 10%">
      <p-progressSpinner></p-progressSpinner>
      <span class="opl-progress-spinner-text-lg">Loading dashboard</span>
    </div>
  </ng-template>

</div>

<p-dialog header="Reset Action Items" [(visible)]="showResetWarning" modal="true" [draggable]="false"
          [resizable]="false" [closeOnEscape]="false" [closable]="false" [closeIcon]="null">
  Action items will reset back to the firm default action items.
  <p-footer>
    <button type="button" pButton class="p-button-secondary" (click)="showResetWarning = false" label="Cancel"></button>
    <button type="button" pButton (click)="confirmReset()" label="Reset"></button>
  </p-footer>
</p-dialog>

<p-sidebar [(visible)]="isFilterExpanded" (onHide)="onHideFilterCustomizing()" [position]="'right'" [blockScroll]="true"
           [showCloseIcon]="false" [autoZIndex]="true" styleClass="p-sidebar-md p-sidebar-no-header">
  <div class="m-t-15">
    <eclipse-main-dashboard-filter #dashboardFilter (resetDashboardSetting)="resetDashboardSetting()">
    </eclipse-main-dashboard-filter>
  </div>
</p-sidebar>
